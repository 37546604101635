import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetupComponent } from './setup/setup.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'business-ambition',
    pathMatch: 'full'
  },
 
  {
    path: 'business-ambition',
    component: SetupComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
