import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
} from "ngx-ui-loader";
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { EnsureModuleLoadedOnceGuard } from './guard/ensure-module-loaded-once.guard';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { environment } from '../../environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    // NgxUiLoaderHttpModule.forRoot({
    //   showForeground: true,
    //   exclude: [`api/team/GetNudgeSprintWeek`, `api/feedback/getTodaysNudges`, "api/feedback/updateStatusOnCheck", "api/dashboard/GetTrackerDays", "api/dashboard/getTrackerNudges", "api/setup/GetTaskAndSprintDetails"],
    //   delay: 200
    // })
  ],
  exports: [RouterModule, HttpClientModule, NgxPermissionsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
