import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FeedbackResponse } from '../../models/responses/feedback.response';
import { SessionModel } from '../../models/session.model';
import { AuthenticationService } from '../../services/authentication.service';
import { FeedbackService } from '../../services/feedback.service';
import * as moment from "moment";
import { Sprint } from '../../models/responses/sprint.response';
import { AnswerList, FeedbackRequest } from '../../models/requests/feedback.request';
import { ProcessService } from '../../services/process.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../../shared/services/utilities.service';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  @Output() feedbackComplete = new EventEmitter<void>();

  feedbackQuestions: FeedbackResponse[];
  feedBackForm: FormGroup
  questions = [];
  currentSession: SessionModel;
  curremtSprint: Sprint;
  npS_score: number = 0;
  isFormSubmitted: boolean = false;
  activeIndex: number = 0;
  isInvalidRating: boolean = false;


  constructor(private feedbackService: FeedbackService, public fb: FormBuilder,
    public processService: ProcessService,
    private authenticationService: AuthenticationService,
    public router: Router, 
    private toastService: ToastrService,
    public utils: UtilitiesService) {
    this.authenticationService.currentUser.subscribe(x => this.currentSession = x);
    this.curremtSprint = this.authenticationService.currentSprintValue;
    this.feedBackForm = this.fb.group({});
    this.GetFeedbackQuestions();
  }

  ngOnInit(): void {
  }

  get formControls() {
    return this.feedBackForm.controls;
  }

  saveFeedbackClick(ev: any) {
    ev.preventDefault();
    this.isFormSubmitted = true;
    if (this.feedBackForm.valid) {
      let questionsAns: AnswerList[] = []
      for (let single of this.questions) {
        var itIsIn = Object.keys(this.feedBackForm.value).some(function (k) { return ~k.indexOf(single.controlName) })
        // if (this.feedBackForm.value.hasOwnProperty(single.controlName)) {
       //if (itIsIn) {
          if (single.inputCount) {
            for (let control of single.inputCount) {
              const regex = /(\d+)(?!.*\d)/;
              let order_no = control.controlName.match(regex);
              order_no = Number(order_no[0])
              let obj = {
                question_id: single.questionId,
                program_id: this.currentSession.Program_ID,
                text_answer: this.feedBackForm.value[control.controlName],
                order_no: order_no,
                program_question_id: single.program_question_id
              }
              questionsAns.push(obj);
            }
          } else {
            let obj = {
              question_id: single.questionId,
              program_id: this.currentSession.Program_ID,
              text_answer: this.feedBackForm.value[single.controlName],
              order_no: single.orderNo,
              program_question_id: single.program_question_id
            }
            questionsAns.push(obj);
          }
        //}
      }

      let durationDays = moment.duration(moment(this.curremtSprint.next_survey_due).diff(this.curremtSprint.user_sprint_start_date)).asDays();
      let periodNo = Math.abs(Math.trunc(durationDays / 7)) + 1;
      let req: FeedbackRequest = {
        user_assessment_id: 0,
        program_id: this.currentSession.Program_ID,
        user_id: this.currentSession.User_ID,
        period: periodNo,
        completion_date: new Date(),
        npS_score: this.npS_score,
        date_to_update: this.curremtSprint.next_survey_due,
        user_program_id: this.currentSession.User_Program_ID,
        answerList: questionsAns
      }
      this.feedbackService.saveFeedback(req).subscribe(result => {
        this.toastService.success('Thanks! Feedback saved successfully.');
        this.isFormSubmitted = false;
        this.feedBackForm.reset();
        this.activeIndex = 0
        this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
        this.feedbackComplete.emit();
      });
    }
  }

  GetFeedbackQuestions() {
    let isAdded = false;
    this.feedbackService.getFeedbackQuestions().subscribe(results => {
      this.feedbackQuestions = results;
      var previousQuestionId: number = null;
      for (let single of results) {
        var existItem = results.filter(x => x.question_text == single.question_text);
        if (previousQuestionId != single.question_id) {
          isAdded = false;
        }
        previousQuestionId = single.question_id;
        let controlArray = single.category_name.split(' ');

        if (existItem.length > 1) {
          if (!isAdded) {
            let obj = {
              controlName: controlArray.join('_'),
              controlIndex: single.order_no,
              questionText: single.question_text,
              question_summary: single.category_name,
              category_name: single.category_name,
              questionType: single.question_type,
              inputCount: existItem,
              questionId: single.question_id,
              illustration: single.illustration,
              orderNo: single.order_no,
              program_question_id: single.program_question_id
            }
            this.questions.push(obj);
            isAdded = true;
          }
        } else {
          let obj = {
            controlName: controlArray.join('_'),
            controlIndex: single.order_no,
            question_summary: single.category_name,
            category_name: single.category_name,
            questionText: single.question_text,
            questionType: single.question_type,
            questionId: single.question_id,
            illustration: single.illustration,
            orderNo: single.order_no,
            program_question_id: single.program_question_id
          }
          this.questions.push(obj);
        }
      }
      
      for (let question of this.questions) {
        if (question.inputCount) {
          for (let control of question.inputCount) {
            let name = question.question_summary + '-' + control.order_no;
            control['controlName'] = name;
            this.feedBackForm.addControl(control.controlName, new FormControl(''));
          }
        } else {
          this.feedBackForm.addControl(question.controlName, new FormControl(''))
        }
      }
    });
  }
  navigateClick(action: string, control?: any) {
    if (action == 'next') {
      if (this.npS_score == 0) {
        this.isInvalidRating = true;
      } else {
        this.isInvalidRating = false;

        if (control == null && !this.isInvalidRating) {
          this.activeIndex += 1;
        } else if (control?.valid) {
          this.isFormSubmitted = false;
          this.activeIndex += 1;
        } else {
          // control.set.required = true;
          this.isFormSubmitted = true;
        }
      }

    } else {
      this.activeIndex += 1;

    }
  }
  onRatingChange(ev) {
    if (ev > 0) {
      this.npS_score = ev;
      this.isInvalidRating = false;
    }
  }
}
