import { WeeklyPlanRequest } from "../requests/nudge.request";

export class SprintNudgeResponse {
    user_action_id: number;
    program_goal_id: number;
    user_objective_guid: string;
    program_id: number;
    user_id: number;
    category_id: number;
    category_name: string;
    action_name: string;
    action_description: string;
    is_personal: number;
    diary_dates: WeeklyPlanRequest[];
    scheduled_time_name: string;
    scheduled_time_id: number;
    sprint_template_suggested_nudge_id: number;
}
