import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { FeedbackRequest } from "../models/requests/feedback.request";
import { ComboResponse } from "../models/responses/combos.response";
import { FeedbackResponse } from "../models/responses/feedback.response";
import { LoginResponse } from "../models/responses/login.response";
import { QuestionAnswer } from "../models/responses/question-answer.response";
import { ReflectSummaryResponse } from "../models/responses/reflect-summary.response";
import { ReflectWeekResponse } from "../models/responses/reflect-week.response";
import { Sprint } from "../models/responses/sprint.response";
import { TodaysNudgeResponse } from "../models/responses/todays-nudge.response";
import { SessionModel } from "../models/session.model";
import { AuthenticationService } from "./authentication.service";
import { ProcessService } from "./process.service";
import { SettingsService } from "./settings.service";

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  private currentComboSubject: BehaviorSubject<ComboResponse[]>;
  private currentUser: SessionModel;
  public currentSprint: Sprint;

  constructor(
    private http: HttpClient, 
    private authService: AuthenticationService, 
    private processService: ProcessService,
    private settingsService: SettingsService) {

    this.currentComboSubject = new BehaviorSubject<ComboResponse[]>([]);
    this.currentUser = this.authService.currentUserValue;
    this.currentSprint = this.authService.currentSprintValue;
  }

  getFeedbackQuestions() {
    return this.http.get<FeedbackResponse[]>(`${this.settingsService.settings.apiUrl}/Feedback/GetFeedbackQuestion?programid=${this.currentUser.Program_ID}`)
  }

  saveFeedback(data: FeedbackRequest) {
    return this.http.post<LoginResponse>(`${this.settingsService.settings.apiUrl}/Feedback/SaveFeedback`, data);
  }

  getTodaysNudges(diaryDate) {
    return this.http.get<TodaysNudgeResponse[]>(`${this.settingsService.settings.apiUrl}/feedback/getTodaysNudges?User_Id=${this.currentUser.User_ID}&User_Program_Id=${this.currentUser.User_Program_ID}&Diary_Date=${diaryDate}`);
  }

  updateNudgeStatus(data: any) {
    return this.http.post<LoginResponse>(`${this.settingsService.settings.apiUrl}/feedback/updateStatusOnCheck`, data);
  }

  updateIamOnHoliday(dt) {
    // var todayDate = moment(this.currentSprint.next_update_due).format('YYYY-MM-DD');
    var todayDate = moment(dt).format('YYYY-MM-DD');
    return this.http.post<LoginResponse>(`${this.settingsService.settings.apiUrl}/feedback/IamOnHoliday?user_program_id=${this.currentUser.User_Program_ID}&date=${todayDate}`, {});
  }

  Unsnooze(dt): Observable<any> {
    var dateToUnsnooze = moment(dt).format('YYYY-MM-DD');
    return this.http.post<LoginResponse>(`${this.settingsService.settings.apiUrl}/feedback/Unsnooze?date=${dateToUnsnooze}`, {});
  }

  finishMyDay(datalist: any[]) {
    var todayDate = moment(this.currentSprint.next_update_due).format('YYYY-MM-DD');
    return this.http.post<LoginResponse>(`${this.settingsService.settings.apiUrl}/feedback/finishMyDay?user_id=${this.currentUser.User_ID}&date=${todayDate}&user_program_id=${this.currentUser.User_Program_ID}`, datalist);
  }

  rescheduleWeeklyNudge() {
    var nextUpdateDue = moment(this.authService.currentSprintValue.next_update_due).format('YYYY-MM-DD');
    return this.http.post<LoginResponse>(`${this.settingsService.settings.apiUrl}/feedback/RescheduleWeeklyNudge?user_id=${this.currentUser.User_ID}&nextUpdateDue=${nextUpdateDue}`, {});
  }

  getReflectSummary() {
    return this.http.get<ReflectSummaryResponse>(`${this.settingsService.settings.apiUrl}/feedback/GetReflectSummary?user_program_id=${this.currentUser.User_Program_ID}`);
  }

  getReflectSprintWeek() {
    return this.http.get<ReflectWeekResponse[]>(`${this.settingsService.settings.apiUrl}/feedback/GetReflectSprintWeek?user_program_id=${this.currentUser.User_Program_ID}`);
  }
  
  getQuestionAnswerList() {
    return this.http.get<QuestionAnswer[]>(`${this.settingsService.settings.apiUrl}/Process/GetQuestionAnswerList?programid=${this.currentUser.Program_ID}&userid=${this.currentUser.User_ID}`);
  }

  getQuestionCategories(): Observable<QuestionCategory[]> {
    return this.http.get<QuestionCategory[]>(`${this.settingsService.settings.apiUrl}/feedback/GetQuestionCategories`);
  }

  getCategoryAndQuestionList(client_id: number, program_id: number): Observable<QuestionsByQuestionCategory[]> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());
    params = params.append("program_id", program_id.toString());

    return this.http.get<QuestionsByQuestionCategory[]>(`${this.settingsService.settings.apiUrl}/feedback/GetCategoryAndQuestionList`,{
      params: params
    });
  }

  saveQuestion(question: SaveQuestion) {
    return this.http.post<any>(`${this.settingsService.settings.apiUrl}/feedback/SaveQuestion`, question);
  }

  saveQuestionCategory(category: SaveQuestionCategory) {
    return this.http.post<any>(`${this.settingsService.settings.apiUrl}/feedback/SaveQuestionCategory`, category);
  }

  saveSprintQuestions(sprintQuestions: Array<SaveSprintQuestions>) {
    return this.http.post<any>(`${this.settingsService.settings.apiUrl}/feedback/SaveSprintQuestions`, sprintQuestions);
  }
}

export class QuestionCategory {
  public question_category_id: number;
  public category_name: string;
  public description: string;
  public icon_id: number;
  public icon: string;
  public is_mandatory: boolean;
  public is_selected: boolean;
  public order_no: number;
}

export class QuestionsByQuestionCategory {
  public questionCategory: QuestionCategory;
  public selectedQuestion: Question;
  public questions: Array<Question>;
}


export class Question {
  public question_id: number;
  public client_id: number;
  public question_text: string;
  public question_type: number;
  public question_category_id: number;
  public icon_id: number;
  public Illustration: string;
  public is_selected: boolean;
  public order_no: number;
}

export class SaveQuestion {
  public question_id: number;
  public client_id: number;
  public question_text: string;
  public question_type: number;
  public question_category_id: number;
  public icon_id: number;
  public order_no: number;
  public is_active: boolean;
}

export class SaveQuestionCategory {
  public question_category_id: number;
  public category_name: string;
  public description: string;
  public icon_id: number;
  public is_mandatory: boolean;
  public order_no: number;
  public is_active: boolean;
}

export class SaveSprintQuestions {
  public program_question_id: number;
  public program_id: number;
  public question_id: number;
  public order_no: number;
  public is_active: boolean;
}