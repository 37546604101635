import { Component,EventEmitter,OnInit, Output} from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
	@Output() onSave: EventEmitter<any> = new EventEmitter();
	public subject: string;
	public body: string;

	constructor(private userService: UserService,
		private toastService: ToastrService) {

	}

	public ngOnInit(): void {

	}

	public submit(): void {
		this.userService.addSupportTicket(this.subject, this.body).subscribe(response => {
			if (response == 1) {
				this.toastService.success("Support ticket successfully added")
				this.onSave.emit();
			} else
				this.toastService.error("An error has occured please contact Black Isle Group directly")

		});
	}
}