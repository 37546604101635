
export class MeasuresResponse  {
    kpI_id: number;
    kpI_library_id: number;
    program_id: number;
    user_program_id: number;
    program_goal_id: number;
    measure_target:string;
    measure_target_description: string;
    target_value: number;
    initial_value: number;
    kpI_area_id: number;
}
