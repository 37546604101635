import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { SetupService } from '../../../services/setup.service';
import { ComboResponse } from '../../../models/responses/combos.response';
import { MeasuresResponse } from '../../../models/responses/measures.response';
import { MeasureRequest } from '../../../models/requests/measure.request';
import { AuthenticationService } from '../../../services/authentication.service';
import { SessionModel } from '../../../models/session.model';

@Component({
  selector: 'app-add-measure',
  templateUrl: './add-measure.component.html',
  styleUrls: ['./add-measure.component.css']
})
export class AddMeasureComponent implements OnInit, OnChanges {
  measureForm: FormGroup;
  @Input() showTitle: boolean = false;
  @Input() data: MeasuresResponse;
  @Input() mode: "ADD" | "EDIT" | "VIEW" = "ADD";
  @Input() sprintGoalTitle: string;
  @Output() saveAndClose: EventEmitter<MeasureRequest> = new EventEmitter();

  isFormDisabled: boolean = false;
  submitted: boolean = false;
  public kpiList: ComboResponse[] = [];
  public kpiLibraryList: ComboResponse[] = [];
  currentUser: SessionModel;
  isInvalidTargetValue: boolean= false;

  constructor(private fb: FormBuilder, private setupService: SetupService, private authService: AuthenticationService) {
    this.currentUser = this.authService.currentUserValue;
    this.getKpiMeasures();
    this.measureForm = this.fb.group({
      measure_target: ['', Validators.required],
      measure_target_description: [''],
      kpI_area_id: [null, Validators.required],
      kpI_library_id: [null],
      initial_value: ['', [Validators.required]],
      target_value: ['', [Validators.required]],
      kpI_id: 0
    });
  }

  ngOnInit(): void {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue as MeasuresResponse;
      this.setFormControls();
      this.getKpiMeasuresFromLibrary(this.data.kpI_area_id);
    }
    if (changes.mode && changes.mode.currentValue) {
      this.mode = changes.mode.currentValue;
      this.isFormDisabled = this.mode === "VIEW";
    }
  }

  get formControls() {
    return this.measureForm.controls;
  }

  setFormControls(): void {
    if (this.data) {
      this.mode === 'VIEW' ? this.measureForm.disable() : this.measureForm.enable();
      this.data &&
        this.measureForm.patchValue({
          measure_target: this.data.measure_target,
          measure_target_description: this.data.measure_target_description,
          kpI_id: this.data.kpI_id,
          kpI_area_id: this.data.kpI_area_id,
          kpI_library_id: this.data.kpI_library_id,
          initial_value: this.data.initial_value,
          target_value: this.data.target_value
        });
    }
  }

  onSaveClick() {
    this.submitted = true;
    if (this.measureForm.valid && this.formControls.target_value.value > this.formControls.initial_value.value) {
      this.isInvalidTargetValue = false;
      let req: MeasureRequest = {
        kpI_id: this.data?.kpI_id || 0,
        measure_target: this.measureForm.value.measure_target,
        measure_target_description: this.measureForm.value.measure_target_description,
        program_id: this.currentUser.Program_ID,
        kpI_library_id: this.measureForm.value.kpI_library_id,
        user_program_id: null,//this.data?.user_program_id || this.currentUser.User_Program_ID,
        initial_value: this.measureForm.value.initial_value,
        target_value: this.measureForm.value.target_value,
        program_goal_id: 0,
        is_active: true,
        user_id: this.currentUser.User_ID
      }
      this.saveAndClose.emit(req);
    } else if (this.formControls.target_value.value < this.formControls.initial_value.value) {
      this.isInvalidTargetValue = true;

    }
  }
  onCancelClick() {
    this.submitted = false;
    this.saveAndClose.emit();
  }

  changeKpi(ev: any) {
    this.getKpiMeasuresFromLibrary(ev.value);
  }

  getKpiMeasures() {
    this.setupService.getAreaComboList().subscribe(result => {
      this.kpiList = result;
    })
  }

  getKpiMeasuresFromLibrary(kpi_area_id) {
    this.setupService.getKpiLibrarryList(kpi_area_id).subscribe(result => {
      this.kpiLibraryList = result;
    });
  }
}
