import { Component, Input, OnInit } from '@angular/core';
import { MotivationalNugget } from '../../../services/process.service';
import { ResourcesService } from '../../../services/resources.service';

@Component({
  selector: 'nugget-pdf',
  templateUrl: './nugget-pdf.component.html',
  styleUrls: ['./nugget-pdf.component.scss']
})
export class NuggetPDFComponent implements OnInit {

    @Input() nugget: MotivationalNugget;
    public pdfData: Blob;

    constructor(private resourceService: ResourcesService) { }

    public ngOnInit(): void {
      this.resourceService.getPDF(this.nugget.nugget_id).subscribe((data: Blob) => {
        this.pdfData = new Blob([data], { type: 'application/pdf' });
      });
    }
}