import { HttpHeaders } from "@angular/common/http";

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export enum UserSession {
  _User_ID = 0,
  _User_Name = 1,
  _User_Email = 2,
  _User_Token = 3,
  _Session_Expiry = 4
}
export const app_session_name = '_UdSM';
export const app_session_sprint_name = '_UCSm';
export const app_session_task_name = '_uNtM';
export const app_session_registrationProcess_name = '_uRpsD';
export const app_session_registrationProcess_IU_name = '_uRpIUsD';
