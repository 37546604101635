import {
    Component,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventEmitter } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SprintNudgeResponse } from "../../../models/responses/sprint-nudge.response";
import { SetupService } from "../../../services/setup.service";
import { ComboResponse } from "../../../models/responses/combos.response";
import { GoalComboResponse } from "../../../models/responses/goal-combo.response";
import { NudgeRequest } from "../../../models/requests/nudge.request";
import { SessionModel } from "../../../models/session.model";
import { AuthenticationService } from "../../../services/authentication.service";
import { ScheduleTimeResponse } from "../../../models/responses/schedule-time.response";

@Component({
    selector: "app-add-nudge",
    templateUrl: "./add-nudge.component.html",
    styleUrls: ["./add-nudge.component.css"],
})
export class AddNudgeComponent implements OnInit, OnChanges {
    @Input() data: SprintNudgeResponse;
    @Input() mode: "ADD" | "EDIT" | "VIEW";
    @Input() showTitle: boolean = false;
    @Input() isFromRegister: boolean;
    @Output() onClose: EventEmitter<number> = new EventEmitter();

    addNudgeForm: FormGroup;
    requestParams: NudgeRequest = null;
    categoryList: ComboResponse[] = [];
    bsModalRef: BsModalRef;
    isFormDisabled: boolean = false;
    submitted: boolean = false;
    goalList: GoalComboResponse[] = [];
    currentUser: SessionModel;
    scheduleTimes: Array<ScheduleTimeResponse>;

    constructor(
        private fb: FormBuilder,
        private modalService: BsModalService,
        private setupService: SetupService,
        private authService: AuthenticationService,
    ) {
        this.currentUser = this.authService.currentUserValue;
        this.addNudgeForm = this.fb.group({
            action_name: ["", Validators.required],
            action_description: [""],
            category_id: [null],
            program_goal_id: [0, Validators.required],
            // scheduled_time_id: [5, Validators.required]
        });
    }

    ngOnInit(): void {
        this.getCategories();
        this.getGoals();
        this.getScheduleTime();
    }

    getGoals() {
        this.setupService.getGoalComboList().subscribe((results) => {
            this.goalList = results;
        });
    }

    getScheduleTime() {
        this.setupService.getScheduleTime().subscribe(result => {
            this.scheduleTimes = result;
        });

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            this.data = changes.data.currentValue as SprintNudgeResponse;
        }
        if (changes.mode && changes.mode.currentValue) {
            this.mode = changes.mode.currentValue;
            this.isFormDisabled = this.mode === "VIEW";
        }
        this.setFormControls();
    }

    setFormControls() {
        this.isFormDisabled
            ? this.addNudgeForm.disable()
            : this.addNudgeForm.enable();
        this.data &&
            this.addNudgeForm.patchValue({
                action_name: this.data.action_name,
                action_description: this.data.action_description,
                category_id: this.data.category_id,
                program_goal_id: this.data.program_goal_id,
                scheduled_time_id: this.data.scheduled_time_id
            });
    }

    getCategories() {
        this.setupService.getCategoryComboList().subscribe((results) => {
            this.categoryList = results;
        });
    }

    get formControls() {
        return this.addNudgeForm.controls;
    }

    saveNudgeClick(e: Event) {
        e.preventDefault();
        this.submitted = true;
        if (this.addNudgeForm.valid) {
            let params = this.getRequestParams();
            this.setupService.nudgeListSaveUpdate(params).subscribe(results => {
                if (results !== -1) {
                    this.onClose.emit(results);
                    this.submitted = false;
                }
            });
        }
    }

    getRequestParams() {
        let obj = new NudgeRequest();
        obj.action_name = this.addNudgeForm.value.action_name;
        obj.action_description = this.addNudgeForm.value.action_description;
        obj.category_id = this.addNudgeForm.value.category_id || null;
        obj.program_goal_id = this.addNudgeForm.value.program_goal_id || 0;
        obj.is_active = true;
        obj.program_id = this.currentUser.Program_ID;
        obj.user_id = this.currentUser.User_ID;
        // obj.scheduled_time_id = this.addNudgeForm.value.scheduled_time_id
        if (this.mode === "ADD") {
            obj.user_action_id = 0;
        } else {
            obj.user_action_id = this.data.user_action_id;
        }
        return obj;
    }

    cancelClick() {
        this.submitted = false;
        this.onClose.emit(-1);
    }

    openDeleteModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template, { ignoreBackdropClick: true });
    }

    handleDeleteConfirm(isConfirm: boolean) {
        if (isConfirm) {
            this.setupService.nudgeDelete(this.data.user_action_id).subscribe(result => {
                if (result !== 0) {
                    this.bsModalRef.hide();
                    this.submitted = false;
                    this.onClose.emit(0);
                }
            });
        } else {
            this.bsModalRef.hide();
        }
    }
}
