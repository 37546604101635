import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "./settings.service";

@Injectable({ providedIn: 'root' })
export class ImplementationService {
    constructor(
        private http: HttpClient, 
        private settingsService: SettingsService) {

        }

    private apiController: string = `${this.settingsService.settings.apiUrl}/implementation/`;

    public getImplementationSteps(): Observable<Array<ClientImplementation>> {
        return this.http.get<Array<ClientImplementation>>(this.apiController + 'GetImplementationSteps');
    }

    public updateImplementationStep(id: number): Observable<any> {
        return this.http.post(this.apiController + 'UpdateImplementationStep', {
            client_implementation_step_id: id
        });
    }
}

export class ClientImplementation {
    public client: string; 
    public steps: Array<ImplementationStep>;
}

export class ImplementationStep {
    public id: number;
    public step_no: number;
    public step_name: string;
    public sub_comment: string;
    public is_overdue: boolean;
    public is_blocked: boolean;
    public is_blocking: boolean;
}