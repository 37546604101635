import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ReportingService } from '../../../shared/services/reporting.service';
import { SignalrService } from '../../../shared/services/signalr.service';
import { ToastrService } from 'ngx-toastr';
import 'rxjs' ;
import * as moment from 'moment';
import { TextAnchor } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent implements OnInit {

  constructor(
    private reportingService: ReportingService, 
    private signalrService: SignalrService,
    private toastr: ToastrService) {

    this.exportHistory = new Array();
    
    signalrService.initiateSignalrConnection().then(() => {
      signalrService.monitorExports();
    });

    this.signalrService.exportHasOccured.subscribe((type: number) => {
      if (type) {
        this.getExportHistory();
      }
    });
  }

  public exportHistory: Export[];
  

  ngOnInit(): void {
    this.getExportHistory();
    this.signalrService.exportsChecked();
  }

  private getExportHistory() {
    this.reportingService.exportHistory().subscribe(result => {
      this.exportHistory = result.map(value => new Export(value));
    });
  }

  public requestExport(type: number) {
    this.reportingService.exportFile(type, 0, 0, 0, "Stakeholder Report").subscribe(result => {

      this.toastr.success('Your export has been queued for processing', '', {
        positionClass: 'toast-bottom-right', 
        timeOut: 10000
      });

      this.exportHistory.unshift(new Export({
        date_requested: new Date(),
        type: "Stakeholder Report",
        status: "Exporting"
      }));
      this.exportHistory.pop();
    });
  }

  public downloadFile(dataRequestId: number, fileName: string) {
    this.reportingService.downloadFile(dataRequestId).subscribe(data => this.serveFile(data, fileName));
  }

  serveFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    var anchor = document.createElement("a");
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
    URL.revokeObjectURL(url);
  }
}

export class Export {
  constructor(data: any) {
    Object.assign(this, data);
  }
  public data_request_id: number;
  public date_requested: Date;
  public target_file_name: string;
  public status: string;
  public type: string;
  public get date() {
    return moment(this.date_requested).format("DD MMMM yyyy - hh:mm:ss");
  }
}