import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-measure',
  templateUrl: './company-measure.component.html',
  styleUrls: ['./company-measure.component.css']
})
export class CompanyMeasureComponent implements OnInit {
  
  companyMeasures = [
    { title: 'Cyclomatic Complexity', targetValue: '2.0', currentValue: '1.0' },
 
  ]
  

  constructor() { }

  ngOnInit(): void {
  }

}
