import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CoachChat, CoachService } from '../../shared/services/coach.service';
import { ICoachingCard, ResourcesService } from '../../shared/services/resources.service';
import { ToastrService } from 'ngx-toastr';
import { Resource } from '../../shared/services/process.service';

@Component({
  selector: 'app-nuggets',
  templateUrl: './nuggets.component.html',
  styleUrls: ['./nuggets.component.scss']
})
export class NuggetsComponent implements OnInit {

  constructor(
    private resourcesService: ResourcesService,
    private domSanitizer: DomSanitizer,
    public authService: AuthenticationService,
    public resouresService: ResourcesService,
    private toastService: ToastrService){

  }

  public coachingCards: Array<Resource>;
  public nuggetGroups: Array<string> = new Array();
  public showVideoPopup: boolean = false;
  public showTranscriptPopup: boolean = false;
  public showPDFPopup: boolean = false;
  public selectedVideoUrl: string;
  public selectedPDFUrl: SafeResourceUrl;
  public selectedVideoTranscript;
  public selectedCardImage: SafeUrl;
  public selectedCardTitle: string = " ";
  private selectedNuggetId: number = 0;
  private videoTimeStamp: number = 0;
  public pendingChanges: boolean = false;
  public inEditingMode: boolean = false;
  public nuggetToEdit: any = null;
  public nuggetToDelete: any = null;
  public showNewNuggetDialog: boolean = false;
  public showDeleteNuggetDialog: boolean = false;
  public newNuggetDialogTitle: string;
  public showNuggetActivationDialog: boolean = false;
  public nuggetToToggleActivationFor: any = null;

  @ViewChild('video') videoPlayer: ElementRef;

  public ngOnInit(): void {
    this.load();
  }

  private load(): void {
    this.resourcesService.getCoachingCards(this.authService.currentUserValue.Client_ID).subscribe(result => {
      result.forEach(nugget => {
        nugget["safeIcon"] = this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + nugget.icon);
        nugget["safeBlob"] = this.domSanitizer.bypassSecurityTrustResourceUrl(nugget.blob_URL);

        if (!this.nuggetGroups.includes(nugget.rc_group)) {
          this.nuggetGroups.push(nugget.rc_group);
        }
      });

      this.coachingCards = result;
    });
  }

  ngAfterViewInit() {
    if (this.videoPlayer)
      this.videoPlayer.nativeElement.addEventListener('loadedmetadata', function() {
        this.currentTime = 50;
      }, false);
  }

  public openVideo(card: CoachingCard): void {
    this.showVideoPopup = true;
    this.selectedVideoUrl = card.blob_URL;
    this.selectedCardTitle = (card.nugget_name == undefined || card.nugget_name == "") ? " " : card.nugget_name;
    this.selectedNuggetId = card.nugget_id;
  }

  public openTranscript(card: CoachingCard): void {
    this.showTranscriptPopup = true;
    this.selectedVideoTranscript = card.transcript;
    this.selectedCardTitle = (card.nugget_name == undefined || card.nugget_name == "") ? " " : card.nugget_name;
    this.selectedNuggetId = card.nugget_id;
    this.selectedCardImage = this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + card.icon);
  }

  public openPDF(card: CoachingCard): void {
    this.showPDFPopup = true;
    this.selectedPDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(card.blob_URL);
  }

  public hidePopup(name: string): void {
    if (name === "video") {
      this.pauseVideo();
      this.showVideoPopup = false;
    } else if (name === "transcript") {
      this.showTranscriptPopup = false;
    } else {
      this.showPDFPopup = false;
    }
  }

  public updateVideoTime(event): void {
    this.videoTimeStamp = this.videoPlayer.nativeElement.currentTime;
  }

  public pauseVideo(): void {
    this.resourcesService.updateCoachingCardVideoProgress(this.selectedNuggetId, this.videoTimeStamp).subscribe(() => {
      this.load();
    });
  }

  public hoverStaticImage(card: Resource): void {
    // card.showStaticImage = false;
  }

  public blurGifImage(card: Resource): void {
    // card.showStaticImage = true;
  }

  public getCards(group: string): Array<Resource> {
    if (!this.coachingCards)
      return;

    return this.coachingCards.filter(x => x.rc_group == group);
  }

  public toggleEditMode() {
    this.inEditingMode = !this.inEditingMode;
  }

  public openEditNuggetDialog(nugget: any, isSprintNugget: boolean) {
    this.nuggetToEdit = nugget;
    this.newNuggetDialogTitle = isSprintNugget ? "Edit Sprint Nugget" : "Edit Coaching Nugget"
    this.showNewNuggetDialog = true;
  }

  public openNewNuggetDialog(isSprintNugget: boolean) {
    this.nuggetToEdit = {
      resource_id: 0,
      resource_name: "",
      resource_description: "",
      icon: "",
      icon_png: "",
      resource_group_id: 0,
      blob_URL: "",
      transcript: "",
      play_time: new Date(),
      order_no: 0,
      client_id: 0,
      program_id: 0,
      is_active: true
    };
    this.newNuggetDialogTitle = "Create New Nugget";
    this.showNewNuggetDialog = true;
  }

  public closeNewNuggetDialog(nugget: Resource = null) {
    if (nugget != null) {
      this.coachingCards = this.coachingCards.filter(card => card.nugget_id != nugget.nugget_id);  
      this.coachingCards.push(nugget);
    }
    this.nuggetToEdit = null;
    this.showNewNuggetDialog = false;
    this.load();
  }

  public toggleDeleteNuggetDialog(nugget: any, open: boolean) {
    this.showDeleteNuggetDialog = open;
    
    if (open)
      this.nuggetToDelete = nugget;
    else
      this.nuggetToDelete = null;
  }

  public toggleNuggetActivationDialog(nugget: any, open: boolean) {
    this.showNuggetActivationDialog = open;
    
    if (open)
      this.nuggetToToggleActivationFor = nugget;
    else
      this.nuggetToToggleActivationFor = null;
  }

  public deleteNugget() {
    this.resouresService.deleteCoachingCard(this.nuggetToDelete.resource_id).subscribe(response => {
      if (response.isSuccess) {
        this.toastService.success("Nugget successfully deleted");
        this.toggleDeleteNuggetDialog(null, false);
      }
      else
        this.toastService.error("Nugget could not be deleted \n" + response.errorMessage);
    });
  }

  public toggleNuggetActivation(activate: boolean) {
    this.resouresService.toggleCoachingCardActivation(this.nuggetToToggleActivationFor.resource_id, activate).subscribe(response => {
      if (response.isSuccess) {
        this.nuggetToToggleActivationFor.is_active = activate;
        this.toastService.success("Nugget successfully " + activate ? "activated" : "deactivated");
        this.toggleNuggetActivationDialog(null, false);
      }
      else
        this.toastService.error("Nugget could not be " + activate ? "activated" : "deactivated" + "\n" + response.errorMessage);
    });
  }

  public getIsBigAdmin(): boolean {
    return this.authService.isBigAdmin();
  }

  public getIconImage(coahcingCard: Resource, image: string): SafeUrl {
    if (image === "static")
      return this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + coahcingCard.icon);
    else 
      // return this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + coahcingCard.hover_over_icon);
      return this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + coahcingCard.icon);
  }

  public getActivationStyle(card: Resource): string {
    let baseStyle = "float: left; clear: left;";

    if (card.is_active)
      baseStyle = baseStyle.concat(" color: green;");
    else
      baseStyle = baseStyle.concat(" color: red;");

    return baseStyle;
  }
}

export class CoachingCard implements ICoachingCard {
  nugget_id: number;
  nugget_name: string;
  nugget_description: string;
  icon: string;
  blob_URL: string;
  date_last_viewed: string;
  view_time: string;
  view_count: number;
  transcript: string;
  hover_over_icon: string;
  showStaticImage: boolean = true;
  rc_group: string;

  constructor(coachingCard: ICoachingCard, private domSanitizer: DomSanitizer) {
    Object.assign(this, coachingCard);
  }

  public getIconImage(image: string): SafeUrl {
    if (image === "static")
      return this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + this.icon);
    else 
      return this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + this.hover_over_icon);
  }
}