import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BusinessSprintResponse } from '../../../models/responses/business-sprint.response';
import { SetupService } from '../../../services/setup.service';
import * as moment from "moment";
import { Router } from '@angular/router';
import { SprintGoalResponse } from '../../../models/responses/sprint-goal.response';
import { BusinessAmbition } from '../../../models/responses/businessAmbition.response';

@Component({
  selector: 'app-business-sprint',
  templateUrl: './business-sprint.component.html',
  styleUrls: ['./business-sprint.component.css']
})
export class BusinessSprintComponent implements OnInit, OnChanges {
  @Input() mode: "ADD" | "EDIT" | "VIEW" = "ADD";
  @Input() businessSprints: BusinessSprintResponse[] = [];
  @Input() businessAmbition: BusinessAmbition = null;
  @Output() closeBusinessSprint: EventEmitter<any> = new EventEmitter();
  @Output() refreshSprint: EventEmitter<any> = new EventEmitter();

  isSprintEdit: boolean = false;
  isSprintGoal: boolean = false;
  formMode: "ADD" | "EDIT" | "VIEW" = "ADD";
  selectedData: BusinessSprintResponse = null;

  sprintGoals: SprintGoalResponse[] = [];

  constructor(private setupService: SetupService, private router: Router) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.businessSprints && changes.businessSprints.currentValue) {
      this.businessSprints = changes.businessSprints.currentValue as BusinessSprintResponse[];
    }
    if (changes.businessAmbition && changes.businessAmbition.currentValue) {
      this.businessAmbition = changes.businessAmbition.currentValue as BusinessAmbition;
    }
  }

  sprintGoalClick(data?: BusinessSprintResponse) {
    this.selectedData = data;
    this.getSrpintGoals(() => {
      this.isSprintGoal = true;
    });
  }

  getSrpintGoals(cb: () => void = null) {
    this.setupService.getSprintGoals(this.selectedData.project_id, this.selectedData.program_id, null).subscribe(results => {
      if (this.sprintGoals.length > 0) {
        this.sprintGoals = Object.assign({}, results);
      } else {
        this.sprintGoals = results;
      }
      cb && cb();
    });
  }

  actionClick(action: "ADD" | "EDIT" | "VIEW", data?: BusinessSprintResponse) {
    this.formMode = action;
    this.selectedData = data;
    this.isSprintEdit = true;
  }

  closeSprint(ev: number) {
    if (ev) {
      this.refreshSprint.emit();
    }
    this.isSprintEdit = false;
  }

  oncloseBusinessSprint(ev) {
    this.closeBusinessSprint.emit();
  }

  onCloseSprint() {
    this.isSprintGoal = false;
  }

  goToSprintGoals(): void {
    this.isSprintEdit = false;
    this.sprintGoalClick(this.selectedData);
  }
}
