import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { SettingsService } from "./settings.service";

@Injectable({ providedIn: 'root' })
export class CoachService {
    constructor(private http: HttpClient, private settingsService: SettingsService) {

    }

    public getCoachDetails(): Observable<CoachDetails> {
        return this.http.get<CoachDetails>(`${this.settingsService.settings.apiUrl}/coach/GetCoachDetails`);
    }

    public getCoachChat(coach_user_id: number): Observable<Array<CoachChat>> {
        let params = new HttpParams();
        params = params.append("coach_user_id", coach_user_id.toString());
        
        return this.http.get<Array<CoachChat>>(`${this.settingsService.settings.apiUrl}/coach/GetCoachChat`, {
            params: params
        });
    }

    public getClientsCoached(): Observable<Array<CoachClient>> {
        return this.http.get<Array<CoachClient>>(`${this.settingsService.settings.apiUrl}/coach/GetClientsCoached`);
    }

    public sendChatMessage(to_user_id: number, message: string): Observable<any> {
        let params = new HttpParams();
        params = params.append("to_user", to_user_id.toString());
        params = params.append("message", message);
        
        return this.http.post<Array<CoachChat>>(`${this.settingsService.settings.apiUrl}/coach/SendChatMessage`, {}, {
            params: params
        });
    }

    public searchCoachees(has_unread_messages: boolean, client_id: number, search_term: string): Observable<Array<Coachee>> {
        let params = new HttpParams();
            params = params.append("has_unread_messages", has_unread_messages.toString());
        if (client_id != null)
            params = params.append("client_id", client_id.toString());
        if (search_term != null)
            params = params.append("search_term", search_term);
        
        return this.http.get<Array<Coachee>>(`${this.settingsService.settings.apiUrl}/coach/SearchCoachees`, {
            params: params
        });
    }
}

export interface CoachDetails {
    first_name: string
    full_name: string
    specialism: string
    contact_info: string
    email_info: string
    inspirational_quote: string
    about_me: string
    job_title: string
    profile_pic: string
}

export class CoachChat {
    user_id: number;
    full_name: string;
    message_text: string;
    message_images: Array<string>;
    post_date: Date;
}

export class Coachee {
    user_id: number;
    full_name: string;
    profile_pic: string;
    client_name: string;
    last_message: string;
    has_unread: boolean;
    is_from_coach: boolean;
    date_last_message: Date;
}

export interface CoachClient {

}