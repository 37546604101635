import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NudgeRequest, WeeklyPlanRequest } from '../../../models/requests/nudge.request';
import { ComboResponse } from '../../../models/responses/combos.response';
import { GoalComboResponse } from '../../../models/responses/goal-combo.response';
import { ScheduleTimeResponse } from '../../../models/responses/schedule-time.response';
import { SprintNudgeResponse } from '../../../models/responses/sprint-nudge.response';
import { SessionModel } from '../../../models/session.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { SetupService } from '../../../services/setup.service';
import * as moment from 'moment';
import { PlanNudge, PlanService } from '../../../services/plan.service';
import { NudgeDetail } from '../../../models/responses/nudge-detail.response';
import { Observable } from 'rxjs';
import { NavPreventerService } from '../../../../core/guard/unsaved.changes.guard';

@Component({
  selector: 'app-add-nudge-plan',
  templateUrl: './add-nudge-plan.component.html',
  styleUrls: ['./add-nudge-plan.component.scss']
})
export class AddNudgePlanComponent implements OnInit {

  @Input() userActionId: number;
  @Input() nudgesData: PlanNudge[];
  @Input() mode: "ADD" | "EDIT" | "VIEW";
  @Output() onClose: EventEmitter<number> = new EventEmitter();

  addNudgeForm: FormGroup;
  requestParams: NudgeRequest = null;
  categoryList: ComboResponse[] = [];
  bsModalRef: BsModalRef;
  isFormDisabled: boolean = false;
  submitted: boolean = false;
  goalList: GoalComboResponse[] = [];
  currentUser: SessionModel;
  scheduleTimes: Array<ScheduleTimeResponse>;
  selectedNudge: PlanNudge;
  selectedDiary: WeeklyPlanRequest = null;
  selectedDetail: NudgeDetail = null;
  timepicker: Date = null;
  selectedDay: number;
  public currentPopover: any;
  public nudgeName: string = null;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private setupService: SetupService,
    private authService: AuthenticationService,
    private planService: PlanService,
    private navPreventerService: NavPreventerService
  ) {
    this.currentUser = this.authService.currentUserValue;
    this.addNudgeForm = this.fb.group({
      action_name: ["", Validators.required],
      action_description: [""],
      category_id: [null],
      program_goal_id: [0, Validators.required]
    });
  }

  ngOnInit(): void {
    this.getCategories();
    this.getGoals();
    this.getScheduleTime();
  }

  getGoals() {
    this.setupService.getGoalComboList().subscribe((results) => {
      this.goalList = results;
    });
  }

  getScheduleTime() {
    this.setupService.getScheduleTime().subscribe(result => {
      this.scheduleTimes = result;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.userActionId = changes.userActionId.currentValue as number;
      this.selectedNudge = changes.data.currentValue;
    }
    if (changes.mode && changes.mode.currentValue) {
      this.mode = changes.mode.currentValue;
      this.isFormDisabled = this.mode === "VIEW";
      if (this.mode === "ADD") {
        this.addNudgeForm.reset();
        this.selectedDetail = new NudgeDetail();
      }
    }
    if (changes.nudgesData && changes.nudgesData.currentValue) {
      this.nudgesData = changes.nudgesData.currentValue;
    }
    this.setFormControls();
    
  }

  setFormControls() {
    this.isFormDisabled
      ? this.addNudgeForm.disable()
      : this.addNudgeForm.enable();

    if (this.userActionId && this.mode != "ADD") {
      this.setupService.GetNudgeById(this.userActionId).subscribe(nudge => {
        this.selectedDetail = nudge;
        this.nudgeName = nudge.action_name;
        this.addNudgeForm.patchValue({
          action_name: nudge.action_name,
          action_description: nudge.action_description,
          category_id: nudge.category_id,
          program_goal_id: nudge.program_goal_id,
          scheduled_time_id: nudge.scheduled_time_id
        });
      }); 
    } else {
      this.nudgeName = null;
    }
  }

  onNudgeSelection(nudge: PlanNudge) {
    this.selectedNudge = nudge;
    this.userActionId = nudge.user_action_id;
    this.mode = 'EDIT';
    this.setFormControls();
  }

  onAddNudge() {
    this.addNudgeForm.reset();
    this.addNudgeForm.patchValue({
      scheduled_time_id: 5
    })
    this.mode = 'ADD';
    this.selectedNudge = null;
  }

  getCategories() {
    this.setupService.getCategoryComboList().subscribe((results) => {
      this.categoryList = results;
    });
  }

  get formControls() {
    return this.addNudgeForm.controls;
  }

  saveNudgeClick(e: Event) {
    e.preventDefault();
    this.submitted = true;
    if (this.addNudgeForm.valid) {
      let params = this.getRequestParams();
      this.setupService.nudgeListSaveUpdate(params).subscribe(results => {
          if (results !== -1) {
            var newPlan = new PlanNudge();
            newPlan.user_action_id = results;

            newPlan.d1_is_active = this.selectedDetail.d1_is_active;
            newPlan.d1_start_time = this.selectedDetail.d1_start_time;
            newPlan.D1_end_time = this.selectedDetail.d1_end_time;

            newPlan.d2_is_active = this.selectedDetail.d2_is_active;
            newPlan.d2_start_time = this.selectedDetail.d2_start_time;
            newPlan.D2_end_time = this.selectedDetail.d2_end_time;

            newPlan.d3_is_active = this.selectedDetail.d3_is_active;
            newPlan.d3_start_time = this.selectedDetail.d3_start_time;
            newPlan.D3_end_time = this.selectedDetail.d3_end_time;

            newPlan.d4_is_active = this.selectedDetail.d4_is_active;
            newPlan.d4_start_time = this.selectedDetail.d4_start_time;
            newPlan.D4_end_time = this.selectedDetail.d4_end_time;

            newPlan.d5_is_active = this.selectedDetail.d5_is_active;
            newPlan.d5_start_time = this.selectedDetail.d5_start_time;
            newPlan.D5_end_time = this.selectedDetail.d5_end_time;

            newPlan.d6_is_active = this.selectedDetail.d6_is_active;
            newPlan.d6_start_time = this.selectedDetail.d6_start_time;
            newPlan.D6_end_time = this.selectedDetail.d6_end_time;

            newPlan.d7_is_active = this.selectedDetail.d7_is_active;
            newPlan.d7_start_time = this.selectedDetail.d7_start_time;
            newPlan.D7_end_time = this.selectedDetail.d7_end_time;

            this.planService.savePlanNudges([newPlan]).subscribe(() => {
              this.selectedNudge = null;
              this.addNudgeForm.reset();
              this.selectedDetail = new NudgeDetail();
              this.onClose.emit(results);
              this.submitted = false;
              this.userActionId = null;
              this.navPreventerService.removeAllRoutingLocks();
            });
          }
        });
    }
  }

  getRequestParams() {
    let obj = new NudgeRequest();
    obj.action_name = this.addNudgeForm.value.action_name;
    obj.action_description = this.addNudgeForm.value.action_description;
    obj.category_id = this.addNudgeForm.value.category_id || null;
    obj.program_goal_id = this.addNudgeForm.value.program_goal_id || 0;
    obj.is_active = true;
    obj.program_id = this.currentUser.Program_ID;
    obj.user_id = this.currentUser.User_ID;
    //obj.scheduled_time_id = this.addNudgeForm.value.scheduled_time_id
    if (this.mode === "ADD") {
      obj.user_action_id = 0;
    } else {
      obj.user_action_id = this.userActionId;
    }
    return obj;
  }

  cancelClick() {
    this.addNudgeForm.reset();
    this.submitted = false;
    this.onClose.emit(-1);
  }

  openDeleteModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template, { ignoreBackdropClick: true });
  }

  handleDeleteConfirm(isConfirm: boolean) {
    if (isConfirm) {
      this.setupService.nudgeDelete(this.userActionId).subscribe(result => {
        if (result !== 0) {
          this.bsModalRef.hide();
          this.submitted = false;
          this.onClose.emit(0);
        }
      });
    } else {
      this.bsModalRef.hide();
    }
  }
  
  getFormatedDate(day: number) {
    let time: string;
    let isActive: boolean;
    
    switch(day) {
      case 1:
        time = this.selectedDetail.d1_start_time;
        isActive = this.selectedDetail.d1_is_active;
      break;
      case 2:
        time = this.selectedDetail.d2_start_time;
        isActive = this.selectedDetail.d2_is_active;
      break;
      case 3:
        time = this.selectedDetail.d3_start_time;
        isActive = this.selectedDetail.d3_is_active;
      break;
      case 4:
        time = this.selectedDetail.d4_start_time;
        isActive = this.selectedDetail.d4_is_active;
      break;
      case 5:
        time = this.selectedDetail.d5_start_time;
        isActive = this.selectedDetail.d5_is_active;
      break;
      case 6:
        time = this.selectedDetail.d6_start_time;
        isActive = this.selectedDetail.d6_is_active;
      break;
      case 7:
        time = this.selectedDetail.d7_start_time;
        isActive = this.selectedDetail.d7_is_active;
      break;
    }
     
    if (!time && isActive)
      return "Anytime"
    else if (!time && !isActive)
      return "";
    else
      return time.substring(0, 5);
  }

  getDayNames(dt: any) {
    return moment(dt).format('dddd').substring(0, 1);
  }

  getShortDayNames(dt: any) {
    return moment(dt).format('dd');
  }

  onTimeInputClick(startTime: string, day: number, popover: any) {
    this.currentPopover = popover;
    this.selectedDay = day;
    if (startTime) {
      this.timepicker = moment("01/01/2021 " + startTime).toDate();
    } else {
      this.timepicker = null;
    }
  }

  public closePopover(): void {
    this.currentPopover.hide();
  }

  updateTimeValue(ev: any) {
    if (ev != null) {
      switch(this.selectedDay) {
        case 1:
          this.selectedDetail.d1_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d1_is_active = true;
        break;
        case 2:
          this.selectedDetail.d2_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d2_is_active = true;
        break;
        case 3:
          this.selectedDetail.d3_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d3_is_active = true;
        break;
        case 4:
          this.selectedDetail.d4_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d4_is_active = true;
        break;
        case 5:
          this.selectedDetail.d5_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d5_is_active = true;
        break;
        case 6:
          this.selectedDetail.d6_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d6_is_active = true;
        break;
        case 7:
          this.selectedDetail.d7_start_time = moment(ev).format("HH:mm");
          this.selectedDetail.d7_is_active = true;
        break;
      }
    }
  }

  public clearTime() {
    switch(this.selectedDay) {
      case 1:
        this.selectedDetail.d1_start_time = null;
        this.selectedDetail.d1_is_active = true;
      break;
      case 2:
        this.selectedDetail.d2_start_time = null;
        this.selectedDetail.d2_is_active = true;
      break;
      case 3:
        this.selectedDetail.d3_start_time = null;
        this.selectedDetail.d3_is_active = true;
      break;
      case 4:
        this.selectedDetail.d4_start_time = null;
        this.selectedDetail.d4_is_active = true;
      break;
      case 5:
        this.selectedDetail.d5_start_time = null;
        this.selectedDetail.d5_is_active = true;
      break;
      case 6:
        this.selectedDetail.d6_start_time = null;
        this.selectedDetail.d6_is_active = true;
      break;
      case 7:
        this.selectedDetail.d7_start_time = null;
        this.selectedDetail.d7_is_active = true;
      break;
    }
  }

  public clearDate() {
    this.clearTime();

    switch(this.selectedDay) {
      case 1:
        this.selectedDetail.d1_is_active = false;
      break;
      case 2:
        this.selectedDetail.d2_is_active = false;
      break;
      case 3:
        this.selectedDetail.d3_is_active = false;
      break;
      case 4:
        this.selectedDetail.d4_is_active = false;
      break;
      case 5:
        this.selectedDetail.d5_is_active = false;
      break;
      case 6:
        this.selectedDetail.d6_is_active = false;
      break;
      case 7:
        this.selectedDetail.d7_is_active = false;
      break;
    }
  }
}
