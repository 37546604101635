import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../services/authentication.service';
import { CoachChat, CoachDetails, CoachService } from '../../services/coach.service';
import { SignalrService } from '../../services/signalr.service';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'coach-chat',
  templateUrl: './coach-chat.component.html',
  styleUrls: ['./coach-chat.component.scss']
})
export class CoachChatComponent implements OnInit {
  constructor(
    public authService: AuthenticationService,
    private coachService: CoachService,
    private signalRService: SignalrService,
    private userService: UserService) {
  }

  @Input() recipientId: number;
  @Input() isCoach: boolean;
  public chatMessages: Array<CoachChat>;
  public currentChartMessage: string;
  public userProfilePic!: string
  public coachProfilePic!: string
  public showImageUploader: boolean = false;
  public coach: CoachDetails;
  public showCoachPopup: boolean = false;
  public coachText: string;
  public coachChatTitle: string;

  ngOnInit(): void {
    this.signalRService.initiateSignalrConnection().then(() => {
      this.signalRService.monitorChat();
    });

    this.coachService.getCoachDetails().subscribe(result => {
      this.coach = result;

      if (this.coach.full_name == "No coach allocated") {
        this.coachText = "Who is my coach?"
        this.coachChatTitle = "Chat to my coach";
      } else {
        this.coachText = "About " + this.coach.first_name
        this.coachChatTitle = "Chat to my coach - " + this.coach.full_name;
      }
    })

    this.signalRService.chatHasOccured.subscribe((update: boolean) => {
      if (update != null) {
        this.load();
        return;
      }
    });

    this.signalRService.messagesChecked(this.recipientId);
    this.load();
  }

  private load(): void {
    this.coachService.getCoachChat(this.recipientId).subscribe(result => {
      this.chatMessages = result;

      this.userService.getUserProfilePic(this.recipientId).subscribe(pic => {
        if (pic)
          this.coachProfilePic = 'data:image/png;base64,' + pic;
      });
    });

    this.authService.currentUser.subscribe(x => {
      if (x.User_Profile_Pic) this.userProfilePic = 'data:image/png;base64,' + x.User_Profile_Pic;
    });
  }

  public submitMessage(image: string = null): void {
    // if (!this.hasAssignedCoach)
    //   return;
    
    let coachChat = new CoachChat();
    coachChat.user_id = this.authService.currentUserValue.User_ID;
    coachChat.full_name = this.authService.currentUserValue.User_First_Name;
    coachChat.message_text = this.currentChartMessage;
    coachChat.message_images = this.imagesToAddToNextMessage;
    coachChat.post_date = new Date();

    this.chatMessages.unshift(coachChat);

    this.coachService.sendChatMessage(this.recipientId, this.currentChartMessage).subscribe(result => {
      this.currentChartMessage = "";
      this.imagesToAddToNextMessage = [];
      this.signalRService.chatUpdated(this.recipientId, this.isCoach);
    })
  }

  getMessageFormat(sender_user_id: number) {
    return (sender_user_id == this.recipientId) ? "message senderFormat" : "message recipientFormat"
  }

  getMessagePosition(sender_user_id: number) {
    return (sender_user_id == this.recipientId) ? "message senderPosition" : "message recipientPosition"
  }


  public toggleAddImage(): void {
    this.showImageUploader = !this.showImageUploader;
  }

  public imagesToAddToNextMessage: Array<string> = [];

  public addImageToMessage(image: string) {
    this.imagesToAddToNextMessage.concat(image);
    this.showImageUploader = !this.showImageUploader;
  }

  public getFormattedDate(post_date: Date): string {
    let date = moment(post_date);

    if (date.isSame(new Date(), "day"))
      return date.format("HH:mm");
    else
      return date.format("DD/MM HH:mm");
  }

  public getInitials(nameString: string) {
    const fullName = nameString.split(' ');
    let initials = "";

    if (fullName.length > 0)
      initials = fullName[0][0];

    if (fullName.length > 1)
      initials = fullName[1][0];

    return initials.toUpperCase();
  }

  public userChatIsUnread(from_user: number): boolean {
    let chatWithUserIsUnread = this.signalRService.unreadCoacheeChatCoacheeIds.filter(user_id => user_id == from_user);
    return chatWithUserIsUnread.length > 0 ? true : false;
  }

  public toggleShowCoachPopup(): void {
    this.showCoachPopup = !this.showCoachPopup;
  }
}
