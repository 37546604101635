import { resourceLimits } from "worker_threads";
import { NudgeResponse } from "../models/responses/nudge.response";
import { TeamChartData } from "../models/responses/team-chart.response";

export const nudgePyramidSort = (arr: NudgeResponse[]) => {
    // if (arr.length === 0) return [];
    // var newArr: NudgeResponse[] = [];

    // //arr.sort((a, b) => b.overallCompletionRate - a.overallCompletionRate);
    // newArr.push(arr.pop());
    // while (arr.length) {
    //     newArr[arr.length % 2 === 0 ? 'unshift' : 'push'](arr.pop());
    // }
    return setNudgeAngles(arr);
};

export const teamPyramidSort = (arr: TeamChartData[]) => {
    // if (arr.length === 0) return [];
    // var newArr: TeamChartData[] = [];
    // //arr.sort((a, b) => b.overallCompletionRate - a.overallCompletionRate);
    // newArr.push(arr.pop());
    // while (arr.length) {
    //     newArr[arr.length % 2 === 0 ? 'unshift' : 'push'](arr.pop());
    // }
    return setNudgeAngles(arr);
};

export const setNudgeAngles = (arr: any[]) => {
    const pos = getRangeCalcution(arr);
    arr.map((x, index, arr) => {
        if (index === 0) {
            x.angle = pos.startFrom;
        } else {
            x.angle = pos.startFrom - (pos.interval * index)
        }
    });
    return arr;
}

const getRangeCalcution = (arr: any[]) => {
    var length = arr.length;
    var getViewArea = {
        top: rangeOptions2[Math.round(arr[0].overallCompletionRate / 10) * 10][0],
        bottom: rangeOptions[Math.round(arr[length - 1].overallCompletionRate / 10) * 10][0],
    };
    var calculateNewViewArea = getViewArea.top + getViewArea.bottom;
    var gap = 360 / length;//calculateNewViewArea / length;
    //gap = gap + 25;
    // if (length > 5) {
    //     gap = gap + (gap / 2);
    // }
    return { startFrom: getViewArea.top - gap + 5, interval: gap }
}

const getRange = (arr: any[]) => {
    var points = arr.map(x => x.overallCompletionRate);
    // const min = Math.min(arr.map(x => x.overallCompletionRate));
}

const rangeOptions = {
    100: [30],
    90: [50],
    80: [45],
    70: [40],
    60: [35],
    50: [30],
    40: [35],
    30: [20],
    20: [15],
    10: [25],
    0: [25]
};

const rangeOptions2 = {
    100: [55],
    90: [55],
    80: [50],
    70: [45],
    60: [40],
    50: [35],
    40: [30],
    30: [45],
    20: [40],
    10: [15],
    0: [30]
};



export const nudge2PyramidSort = (arr: NudgeResponse[]) => {
    if (arr.length === 0) return [];
    var newArr: NudgeResponse[] = [];
    //arr.sort((a, b) => b.overallCompletionRate - a.overallCompletionRate);
    newArr.push(arr.pop());
    while (arr.length) {
        newArr[arr.length % 2 === 0 ? 'unshift' : 'push'](arr.pop());
    }
    return setNudge2Angles(newArr);
};



export const setNudge2Angles = (arr: any[]) => {
    const pos = getRangeCalcution(arr);
    let averageCompletionRate = 0;
    let overallRate = 0;
    arr.map((x, index, arr) => {
        overallRate += x.overallCompletionRate;
    });

    averageCompletionRate = overallRate / arr.length;

    switch (arr.length) {
        case 1: {
            arr.map((x, index, arr) => {
                x.angle = 0;
            });
            return arr;
            break;
        }
        case 2: {
            let result = setNudgeFinalAngles(arr, 15, 30);
            return result;
            break;
        }
        case 3: {
            let result = null;
            if (averageCompletionRate > 60) {
                result = setNudgeFinalAngles(arr, 40, 40);
            } else {
                result = setNudgeFinalAngles(arr, 20, 20);
            }

            return result;
            break;
        }
        case 4: {
            let result = null;
            if (averageCompletionRate > 40) {
                result = setNudgeFinalAngles(arr, 40, 30);
            } else {
                result = setNudgeFinalAngles(arr, 20, 13);
            }
            return result;
            break;
        }
        case 5: {
            let result = null;
            if (averageCompletionRate > 80) {
                result = setNudgeFinalAngles(arr, 60, 30);
            } else if (averageCompletionRate > 70) {
                result = setNudgeFinalAngles(arr, 40, 30);
            } else if (averageCompletionRate > 50) {
                result = setNudgeFinalAngles(arr, 60, 29);
            } else {
                result = setNudgeFinalAngles(arr, 24, 11);
            }
            return result;
            break;
        }
        case 6: {
            let result = null;
            if (averageCompletionRate > 70) {
                result = setNudgeFinalAngles(arr, 60, 25);
            } else if (averageCompletionRate > 50) {
                result = setNudgeFinalAngles(arr, 60, 24);
            } else if (averageCompletionRate > 30) {
                result = setNudgeFinalAngles(arr, 50, 20);
            } else {
                result = setNudgeFinalAngles(arr, 25, 8);
            }
            return result;
            break;
        }
        case 7: {
            let result = null;
            if (averageCompletionRate > 70) {
                result = setNudgeFinalAngles(arr, 78, 25);
            } else if (averageCompletionRate > 50) {
                result = setNudgeFinalAngles(arr, 70, 22);
            } else {
                result = setNudgeFinalAngles(arr, 25, 7);
            }
            return result;
            break;
        }
        case 8: {
            let result = null;
            if (averageCompletionRate > 70) {
                result = setNudgeFinalAngles(arr, 90, 25);
            } else if (averageCompletionRate > 50) {
                result = setNudgeFinalAngles(arr, 70, 19);
            } else if (averageCompletionRate > 40) {
                result = setNudgeFinalAngles(arr, 55, 15);
            } else if (averageCompletionRate > 30) {
                result = setNudgeFinalAngles(arr, 44, 11);
            } else if (averageCompletionRate >= 20) {
                result = setNudgeFinalAngles(arr, 36, 9);
            } else if (averageCompletionRate >= 10) {
                result = setNudgeFinalAngles(arr, 25, 7);
            } else {
                result = setNudgeFinalAngles(arr, 25, 6);
            }
            return result;
            break;
        }
        default: {
            arr.map((x, index, arr) => {
                if (index === 0) {
                    x.angle = pos.startFrom;
                } else {
                    x.angle = pos.startFrom - (pos.interval * index)
                }
            });
            return arr;
            break;
        }
    }
}

export const setNudgeFinalAngles = (arr, startAngle, gap) => {
    arr.map((x, index, arr) => {
        x.angle = startAngle - index;
        startAngle -= gap;
    });
    return arr;
}
