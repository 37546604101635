import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appInitializer } from './core/initializer/app.initializer'
import { SidebarModule } from 'ng-sidebar';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION
} from 'ngx-ui-loader';
import { AccountModule } from './views/account/account.module';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './core/guard/auth.guard';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from './shared/services/authentication.service';
import { SettingsService } from './shared/services/settings.service';
import { NgxFreshdeskWebwidgetConfig, NgxFreshdeskWebwidgetModule } from 'ngx-freshdesk-webwidget';
import 'hammerjs';
import { NavPreventerService } from './core/guard/unsaved.changes.guard';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { MsalGuard, MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';

import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { environment } from '../environments/environment';
import { EditorModule } from '@progress/kendo-angular-editor';
import { SupportComponent } from './containers/support/support.component';
import { ReportingModule } from './views/reporting/reporting.module';
import { ComponentsModule } from './shared/components/components.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'



const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#4978bd',
  bgsPosition: POSITION.topCenter,
  bgsSize: 100,
  bgsType: SPINNER.ballScaleMultiple, // background spinner type
  fgsColor: '#4978bd',
  fgsType: SPINNER.fadingCircle, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 3, // progress bar thickness
  pbColor: '#4978bd',
  blur: 50,
  overlayColor: 'rgba(255,255,255,0.3)',
  fgsSize: 60
};

export class FreshdeskConfig extends NgxFreshdeskWebwidgetConfig {
  widgetId = 80000004736;
  locale = 'en';
  callback(FreshworksWidget) {
    FreshworksWidget('hide');
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    RouterModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AccountModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SidebarModule.forRoot(),

    DialogModule,
    TooltipsModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: '967007af-4268-41f8-80a4-45317f696a13', // Application (client) ID from the app registration
        authority: 'https://login.microsoftonline.com/common', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: environment.websiteUrl + 'personal/weekly-calendar'// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read', 'calendars.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([ 
          ['https://graph.microsoft.com/v1.0/me', ['user.read', 'calendars.read']],
          ['https://graph.microsoft.com/v1.0/$batch', ['calendars.readwrite']]
      ])
    }),
    SchedulerModule,
    EditorModule,
    ReportingModule,
    ComponentsModule,
    NgxExtendedPdfViewerModule 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    SupportComponent
  ],
  providers: [
    AuthGuard,
    NavPreventerService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: APP_INITIALIZER, 
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService, SettingsService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector,
    private localeService: BsLocaleService,
    private settingsService: SettingsService) {
      AppModule.injector = injector;
      this.localeService.use('en-gb');
  }
  
}
