import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService, TourStep } from '../../shared/services/resources.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  templateUrl: 'tours.component.html',
  styleUrls: ['tours.component.css']
})
export class ToursComponent {
  tourStepId: number;
  tourStepsData: Array<TourStep> = new Array();
  tourIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resourcesService: ResourcesService,
    private domSanitiser: DomSanitizer,
    private authService: AuthenticationService
    ) {    
    this.route.queryParams.subscribe(params => {
      this.tourStepId = params['tour_step_id'];
    });
   }

   ngOnInit() {
    this.loadTour(this.tourStepId);
   }

   loadTour(tourStepId: number) {
    this.resourcesService.getTour(tourStepId).subscribe(result => {
      this.tourStepsData = result;
    });
   }

   transformHtml() {
    let htmlString = this.tourStepsData[this.tourIndex] 
      ? this.tourStepsData[this.tourIndex].body_text 
      : '';
    let safeHtml = this.domSanitiser.bypassSecurityTrustHtml(htmlString);
    return safeHtml;
  }

  transformUrl() {
    let urlString = this.tourStepsData[this.tourIndex]
      ? this.tourStepsData[this.tourIndex].icon.toString()
      : '';
    let completeUrl = "data:image/png+xml;base64," + urlString;
    let safeUrl = this.domSanitiser.bypassSecurityTrustUrl(completeUrl);
    return safeUrl;
 }

  buttonClick() {    

    if (this.tourIndex < this.tourStepsData.length -1) {
      this.tourIndex++;
    } 
    else if (this.tourIndex == this.tourStepsData.length -1
        && this.tourStepId == 27) {
      this.router.navigateByUrl('/auth/setup-wiz-tryout');
    } 
    else if (this.tourStepId == 10) {
      this.authService.skipSlothScreen = true;
      this.router.navigateByUrl('/home');
    }
    else {
      this.router.navigateByUrl('/home');
    }
  }

  isBodyFirst(): boolean {
    if (this.tourStepsData[this.tourIndex])
      return this.tourStepsData[this.tourIndex].body_first
    else
      return false;
  }

  hasConfetti(): boolean {
    if (this.tourStepsData[this.tourIndex])
      return this.tourStepsData[this.tourIndex].has_confetti;
    else
      return false;
  }
}
