import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TeamChartData } from '../../models/responses/team-chart.response';
import { TeamService } from '../../services/team.service';
import { teamPyramidSort } from '../../utils/nudge.util';

@Component({
  selector: 'app-team-chart',
  templateUrl: './team-chart.component.html',
  styleUrls: ['./team-chart.component.scss']
})
export class TeamChartComponent implements OnInit {
  @Input() selectedUserId: number = 0;
  @Input() sprintId: number;
  @Output() onClickUser = new EventEmitter<any>();
  @ViewChild('mainContainer', { static: true }) mainContainer: ElementRef<HTMLElement>;
  imgSrc: string = ''
  public data: TeamChartData[];
  public totalOverallProgress: number;

  constructor(private teamService: TeamService) { }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.teamService.getTeamChartData(this.sprintId).subscribe(results => {
      this.data = teamPyramidSort(results);

      this.mapClassToUsers();
      this.createCustomStyle();
      
      this.teamService.getTeamSummary(this.sprintId).subscribe(results => {
        this.totalOverallProgress = results.acheivementOverallpc;
        this.findOverallValue();
      });
    });
    
  }

  findOverallValue() {
    if (!this.totalOverallProgress) {
      var length = this.data.length;
      var sum = this.data.reduce((sum, item) => sum + item.overallCompletionRate, 0);
      this.totalOverallProgress = sum / length;
    }
    if (this.totalOverallProgress >= 66.6) {
      this.imgSrc = './assets/img/archery_green.svg';
    } else if (this.totalOverallProgress >= 33.4 && this.totalOverallProgress < 66.5) {
      this.imgSrc = './assets/img/archery_amber.svg';
    } else {
      this.imgSrc = './assets/img/archery_red.svg';
    }
  }

  setSelectedUser(user: TeamChartData) {
    this.selectedUserId = user.user_id;
    this.onClickUser.emit(user);
  }

  getIsSelected(id: number) {
    return this.selectedUserId === id;
  }

  getPointClass(id: number, percentage: number): string {
    if (percentage >= 66.6) {
      return 'point-green';
    } else if (percentage >= 33.4 && percentage < 66.5) {
      return 'point-orange';
    } else {
      return 'point-red';
    }
  }

  mapClassToUsers() {
    this.data.map(x => {
      x.styleClass = this.getPointClass(x.user_id, x.overallCompletionRate);
    });
  }

  normalAbsoluteAngleDegrees(angle: number) {
    return (angle %= 360) >= 0 ? angle : (angle + 360);
  }

  createCustomStyle() {
    let style: string = '';
    this.data.forEach((x, index) => {

      var textPosition = "";
      if (x.angle < 0 && x.angle > -90) {
        textPosition = "top: 140%";
      } else if (x.angle <= -90 && x.angle > -180) {
        textPosition = "top: 140%";
      } else if (x.angle <= -180 && x.angle > -270) {
        textPosition = "bottom: 140%";
      } else {
        textPosition = "bottom: 140%";
      }

      let newStyle: string =
        `.point-container-${x.user_id} {
          width: calc(53% - (${x.overallCompletionRate}% * 0.33)) !important;
          transform: rotate(${x.angle}deg);
          transition-delay: ${index + 1}00ms;
        }
        .point-${x.user_id} {
          transform: rotate(-${this.normalAbsoluteAngleDegrees(x.angle)}deg);
        }
        .text-${x.user_id} {
          ${textPosition};
        }
        .popout-${x.user_id} {
          
        }`;
      style = style + newStyle;
    });
    this.addStyleToView(style);
  }

  addStyleToView(style: string): void {
    let styleElement = this.mainContainer.nativeElement.querySelector('style');
    if (!styleElement) {
      styleElement = document.createElement('style');
    }
    setTimeout(() => {
      styleElement.innerHTML = style;
      this.mainContainer.nativeElement.appendChild(styleElement);
    }, 100);
  }
  getImageSrc() {
    if (this.totalOverallProgress >= 90) {
      return './assets/img/archery_green.svg';
    } else if (this.totalOverallProgress >= 75 && this.totalOverallProgress < 90) {
      return './assets/img/archery_amber.svg';
    } else {
      return './assets/img/archery_red.svg';
    }
  }
}
