import { Component,EventEmitter,Input,OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})
export class TabNavigationComponent implements OnInit {
  constructor() {

  }

  @Output() onNavigateLeft: EventEmitter<any> = new EventEmitter();
  @Output() onNavigateHome: EventEmitter<any> = new EventEmitter();
  @Output() onNavigateRight: EventEmitter<any> = new EventEmitter();
  @Input() showLeft: boolean = true;
  @Input() showRight: boolean = true;

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showLeft && changes.showLeft.currentValue) {
        this.showLeft = changes.showLeft.currentValue;
    }

    if (changes.showRight && changes.showRight.currentValue) {
        this.showRight = changes.showRight.currentValue;
    }
  }

  public navigateLeft(): void {
    this.onNavigateLeft.emit();
  }

  public navigateHome(): void {
    this.onNavigateHome.emit();
  }

  public navigateRight(): void {
    this.onNavigateRight.emit();
  }
}