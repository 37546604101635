import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MotivationalNugget, ProcessService } from '../../services/process.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sprint-nuggets',
  templateUrl: './sprint-nuggets.component.html',
  styleUrls: ['./sprint-nuggets.component.scss']
})
export class SprintNuggetsComponent implements OnInit {

    public sprintNuggets: Array<MotivationalNugget> = new Array();

    constructor(
        private router: Router, 
        private processService: ProcessService, 
        private domSanitizer: DomSanitizer) { }

    public ngOnInit(): void {

        this.processService.getMotivationalNuggets().subscribe(nuggets => {
            this.sprintNuggets = nuggets;

            this.sprintNuggets.forEach(nugget => {
                nugget["safeIcon"] = this.domSanitizer.bypassSecurityTrustUrl("data:image/png+xml;base64," + nugget.icon);
                nugget["safeBlob"] = this.domSanitizer.bypassSecurityTrustResourceUrl(nugget.blob_URL);
            })
        });
    }

    public navigateToNuggets(): void {
        this.router.navigate(["nuggets"]);
    }
}