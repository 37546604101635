import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MeasureRequest } from '../../../models/requests/measure.request';
import { MeasuresResponse } from '../../../models/responses/measures.response';
import { AuthenticationService } from '../../../services/authentication.service';
import { SetupService } from '../../../services/setup.service';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.css']
})
export class MeasureComponent implements OnInit {
  @Input() mode: "ADD" | "EDIT" | "VIEW" = "ADD";
  @Input() measuresList: MeasuresResponse[] = [];
  @Input() companyMeasuresList: MeasuresResponse[] = [];
  @Output() refreshData: EventEmitter<number> = new EventEmitter();
  @Output() requestAddMeasure: EventEmitter<MeasureRequest> = new EventEmitter();

  selectedMeasure: MeasuresResponse = null;
  measureFormMode: "ADD" | "EDIT" | "VIEW" = "ADD";
  isMeasureFormVisible: boolean = false;

  constructor(private fb: FormBuilder, private authService: AuthenticationService, private setupService: SetupService) { }

  ngOnInit(): void {

  }

  actionClick(action: "ADD" | "EDIT" | "VIEW", data?: MeasuresResponse) {
    this.measureFormMode = action;
    this.selectedMeasure = data;
    this.isMeasureFormVisible = true;
  }

  onMeasureFormClose(ev: MeasureRequest) {
    if ("object" === typeof ev) {
      this.saveAndUpdateMeasures(ev as MeasureRequest)
      this.isMeasureFormVisible = false;
    } else {
      this.isMeasureFormVisible = false;
    }
  }

  saveAndUpdateMeasures(mdl: MeasureRequest) {
    mdl.program_id = this.authService.currentUserValue.Program_ID;
    mdl.user_program_id = this.authService.currentUserValue.User_Program_ID;
    mdl.program_goal_id = 0;
    mdl.user_id = this.authService.currentUserValue.User_ID;
    this.setupService.saveAndUpdateMeasuresList([mdl]).subscribe(data => {
      this.refreshData.emit();
    });
  }
}
