import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class NavPreventerService implements CanActivateChild {
  locks: any = {};
  public routingLocks = new BehaviorSubject<string>("");

  getRoutingLocks(): Observable<string> {
    return this.routingLocks.asObservable();
  }

  announceRoutingLocks(urlRequested: string) {
      this.routingLocks.next(urlRequested);
  }

  constructor() {
    // Bonus: If necessary also prevent closing the window:
    window.addEventListener('beforeunload', (event) => {
      if (this.hasRoutingLocks()) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.hasRoutingLocks()) {
      this.announceRoutingLocks(state.url);
      return false;
    }
    return true;
  }

  setRoutingLock(key: string) {
    this.locks[key] = true;
  }

  removeRoutingLock(key: string) {
    delete this.locks[key];
  }

  removeAllRoutingLocks() {
    this.locks = {};
    this.routingLocks = new BehaviorSubject<string>("");
  }

  hasRoutingLocks(): boolean {
    return !!Object.keys(this.locks).length;
  }
}