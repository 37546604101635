import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { debug } from 'console';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NavPreventerService } from '../../../../core/guard/unsaved.changes.guard';
import { NudgeRequest, WeeklyPlanRequest } from '../../../models/requests/nudge.request';
import { ComboResponse } from '../../../models/responses/combos.response';
import { ScheduleTimeResponse } from '../../../models/responses/schedule-time.response';
import { TeamSprintGoalResponse } from '../../../models/responses/team-sprint-goal.response';
import { SessionModel } from '../../../models/session.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { PlanNudge, PlanService } from '../../../services/plan.service';
import { SetupService } from '../../../services/setup.service';

@Component({
  selector: 'app-nudges-plan',
  templateUrl: './nudges-plan.component.html',
  styleUrls: ['./nudges-plan.component.scss']
})
export class NudgesPlanComponent implements OnInit, OnChanges {
  @Input() isFromSetup: boolean;
  @Input() selectedNudgeId: number;
  selectedRow = null;
  firstday = '';
  lastday = '';
  first = null;
  last = null;
  currentUser: SessionModel;
  selectedWeekPlan: WeeklyPlanRequest[] = [];
  isOutlookVisible: boolean = false;

  timepicker: Date = null;
  selectedNudge: PlanNudge = null;
  selectedDay: number;
  notifications_before_id: ComboResponse;
  notification_times_after_id: ComboResponse;
  beforeScheduleTimeList: ComboResponse[];
  afterScheduleTimeList: ComboResponse[];
  nudgeFormMode: string;
  isAddFormVisible: boolean = false;
  public sprintData: TeamSprintGoalResponse;
  public selectedGoalIndex: number = 0;
  public selectedGoalId: number;
  public bsModalRef: BsModalRef;
  public businessAmbition: string;
  public planNudges: PlanNudge[];
  public selectUserActionId: number;
  public currentPopover: any;
  public sendMobileNotifications: boolean = true;
  public sendEmailNotifications: boolean = true;
  public objectives: Array<any> = new Array();
  public daysOfWeek: Array<number> = [1,2,3,4,5,6,7];
  // public frequencyOptions: Array<ScheduleTimeResponse>;
  public showLoader: boolean = true;
  public showPreventLeavingMessage: boolean = false;
  public requestedURLBeforeLock: string = ";"

  constructor(
    private setupService: SetupService, 
    private authService: AuthenticationService, 
    public router: Router, 
    private modalService: BsModalService,
    private planService: PlanService,
    private toastrService: ToastrService,
    private navPreventerService: NavPreventerService) {

    this.currentUser = this.authService.currentUserValue;
    this.businessAmbition = this.authService.currentSprintValue?.project_name;
    this.getNudges();
    this.getWeekDates();
    this.getNotificationTimeList();
    this.getSprintGoals();
    //this.getFrequencyOptions();

    this.navPreventerService.getRoutingLocks().subscribe(value => {
      this.requestedURLBeforeLock = value;
      if (value)
        this.showPreventLeavingMessage = true;
    });
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nudgesData && changes.nudgesData.currentValue) {
      if (this.selectedNudgeId)
        this.findNudgeFromId();
    }
    if (changes.isFromSetup && changes.isFromSetup.currentValue) {
      this.isFromSetup = changes.isFromSetup.currentValue
    }
  }

  getWeekDates() {
    var curr = moment(this.authService.currentSprintValue?.next_targets_due);
    this.first = curr.get('date') - curr.get('day') + 1;
    this.last = this.first + 1;
    this.firstday = curr.startOf('isoWeeks').format('YYYY-MM-DD');
    this.lastday = curr.endOf('isoWeeks').format('YYYY-MM-DD');
  }

  getDayNames(dt: any) {
    return moment(dt).format('dddd').substring(0, 1);
  }

  getShortDayNames(dt: any) {
    return moment(dt).format('dd');
  }

  getFormatedDate(row: PlanNudge, day: number) {
    let itemIndex = this.planNudges.findIndex(item => item.user_action_id == row.user_action_id);
    let time: string;
    let isActive: boolean;
    
    switch(day) {
      case 1:
        time = this.planNudges[itemIndex].d1_start_time;
        isActive = this.planNudges[itemIndex].d1_is_active;
      break;
      case 2:
        time = this.planNudges[itemIndex].d2_start_time;
        isActive = this.planNudges[itemIndex].d2_is_active;
      break;
      case 3:
        time = this.planNudges[itemIndex].d3_start_time;
        isActive = this.planNudges[itemIndex].d3_is_active;
      break;
      case 4:
        time = this.planNudges[itemIndex].d4_start_time;
        isActive = this.planNudges[itemIndex].d4_is_active;
      break;
      case 5:
        time = this.planNudges[itemIndex].d5_start_time;
        isActive = this.planNudges[itemIndex].d5_is_active;
      break;
      case 6:
        time = this.planNudges[itemIndex].d6_start_time;
        isActive = this.planNudges[itemIndex].d6_is_active;
      break;
      case 7:
        time = this.planNudges[itemIndex].d7_start_time;
        isActive = this.planNudges[itemIndex].d7_is_active;
      break;
    }
     
    if (!time && isActive)
      return "Anytime"
    else if (!time && !isActive)
      return "";
    else
      return time.substring(0, 5);
  }

  onTimeInputClick(row: PlanNudge, day: number, popover: any) {
    let itemIndex = this.planNudges.findIndex(item => item.user_action_id == row.user_action_id);
    this.currentPopover = popover;
    this.selectedNudge = row;
    this.selectedDay = day;

    let startTime;
    switch(day) {
      case 1:
        startTime = this.planNudges[itemIndex].d1_start_time;
      break;
      case 2:
        startTime = this.planNudges[itemIndex].d2_start_time;
      break;
      case 3:
        startTime = this.planNudges[itemIndex].d3_start_time;
      break;
      case 4:
        startTime = this.planNudges[itemIndex].d4_start_time;
      break;
      case 5:
        startTime = this.planNudges[itemIndex].d5_start_time;
      break;
      case 6:
        startTime = this.planNudges[itemIndex].d6_start_time;
      break;
      case 7:
        startTime = this.planNudges[itemIndex].d7_start_time;
      break;
    }

    if (startTime) {
      this.timepicker = moment("01/01/2021 " + startTime).toDate();
    } else {
      this.timepicker = null;
    }
  }

  updateTimeValue(ev: any) {
    if(ev != null) {
      let itemIndex = this.planNudges.findIndex(item => item.user_action_id == this.selectedNudge.user_action_id);
      if (ev != null) {
        switch(this.selectedDay) {
          case 1:
            this.planNudges[itemIndex].d1_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d1_is_active = true;
          break;
          case 2:
            this.planNudges[itemIndex].d2_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d2_is_active = true;
          break;
          case 3:
            this.planNudges[itemIndex].d3_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d3_is_active = true;
          break;
          case 4:
            this.planNudges[itemIndex].d4_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d4_is_active = true;
          break;
          case 5:
            this.planNudges[itemIndex].d5_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d5_is_active = true;
          break;
          case 6:
            this.planNudges[itemIndex].d6_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d6_is_active = true;
          break;
          case 7:
            this.planNudges[itemIndex].d7_start_time = moment(ev).format("HH:mm");
            this.planNudges[itemIndex].d7_is_active = true;
          break;
        }

        this.navPreventerService.setRoutingLock("timeChanged")
      }
    }
  }

  public clearTime() {
    let itemIndex = this.planNudges.findIndex(item => item.user_action_id == this.selectedNudge.user_action_id);

    switch(this.selectedDay) {
      case 1:
        this.planNudges[itemIndex].d1_start_time = null;
        this.planNudges[itemIndex].d1_is_active = true;
      break;
      case 2:
        this.planNudges[itemIndex].d2_start_time = null;
        this.planNudges[itemIndex].d2_is_active = true;
      break;
      case 3:
        this.planNudges[itemIndex].d3_start_time = null;
        this.planNudges[itemIndex].d3_is_active = true;
      break;
      case 4:
        this.planNudges[itemIndex].d4_start_time = null;
        this.planNudges[itemIndex].d4_is_active = true;
      break;
      case 5:
        this.planNudges[itemIndex].d5_start_time = null;
        this.planNudges[itemIndex].d5_is_active = true;
      break;
      case 6:
        this.planNudges[itemIndex].d6_start_time = null;
        this.planNudges[itemIndex].d6_is_active = true;
      break;
      case 7:
        this.planNudges[itemIndex].d7_start_time = null;
        this.planNudges[itemIndex].d7_is_active = true;
      break;
    }
  }

  public clearDate() {
    this.clearTime();
    let itemIndex = this.planNudges.findIndex(item => item.user_action_id == this.selectedNudge.user_action_id);

    switch(this.selectedDay) {
      case 1:
        this.planNudges[itemIndex].d1_is_active = false;
      break;
      case 2:
        this.planNudges[itemIndex].d2_is_active = false;
      break;
      case 3:
        this.planNudges[itemIndex].d3_is_active = false;
      break;
      case 4:
        this.planNudges[itemIndex].d4_is_active = false;
      break;
      case 5:
        this.planNudges[itemIndex].d5_is_active = false;
      break;
      case 6:
        this.planNudges[itemIndex].d6_is_active = false;
      break;
      case 7:
        this.planNudges[itemIndex].d7_is_active = false;
      break;
    }
  }

  public closePopover(): void {
    this.currentPopover.hide();
  }

  saveDiary(openCalendar: boolean = false) {
    this.updateNotificationTime();
    this.planService.savePlanNudges(this.planNudges).subscribe(() => {
      this.navPreventerService.removeAllRoutingLocks();
      
      this.toastrService.success("Plan changed successfully", '', {
        positionClass: 'toast-bottom-right'
      });

      if (openCalendar)
        this.router.navigate(['personal/weekly-calendar']);
    });
  }

  onListSelect(row) {
    this.selectedRow = row;
  }

  onOutlookScheduleClick() {
    if (this.isFromSetup) {
      this.isOutlookVisible = true;
    } else {
      this.router.navigate(['personal/weekly-calendar']);
    }

  }
  getNotificationTimeList() {
    this.setupService.getNotificationTimeList().subscribe((results) => {
      this.beforeScheduleTimeList = results.filter(single => single.type == 'BEFORE');
      this.afterScheduleTimeList = results.filter(single => single.type == 'AFTER');
      this.getNotificationPreferencebyUserId();
    });
  }
  getNotificationPreferencebyUserId() {
    this.setupService.getNotificationPreferencebyUserId().subscribe((results) => {
      this.notifications_before_id = this.beforeScheduleTimeList.filter(x => x.value == results.notifications_before_id)[0];
      this.notification_times_after_id = this.afterScheduleTimeList.filter(x => x.value == results.notification_times_after_id)[0];

      this.sendMobileNotifications = results.notify_by_mobile;
      this.sendEmailNotifications = results.notify_by_email;
    });
  }
  updateNotificationTime() {
    if (this.notifications_before_id !== null || this.notification_times_after_id !== null) {
      let beforeId = this.notifications_before_id ? this.notifications_before_id.value : 0;
      let afterId = this.notification_times_after_id ? this.notification_times_after_id.value : 0;
      this.setupService.updateNotificationTime(beforeId, afterId, this.sendEmailNotifications, this.sendMobileNotifications).subscribe((results) => {

      });
    }

  }
  onAddNudgeClick() {
    this.selectedNudge = null;
    this.isAddFormVisible = true;
    this.nudgeFormMode = 'ADD';
  }

  private findNudgeFromId(): void {
    this.planNudges.forEach(nudge => {
      if (nudge.user_action_id == this.selectedNudgeId)
        this.onNudgeClick(nudge);
    });
  }

  onNudgeClick(nudge: PlanNudge) {
    this.selectUserActionId = nudge.user_action_id;
    this.isAddFormVisible = true;
    this.nudgeFormMode = 'EDIT';
    this.selectedNudge = nudge;
  }
  formClose(state: number) {
    this.isAddFormVisible = false;
    if (state !== -1) {
      this.getNudges();
    }
  }
  getNudges() {
    //this.setupService.getScheduleTime().subscribe(result => {
      //this.frequencyOptions = result;
    
      this.planService.getPlanNudges(this.authService.currentSprintValue.program_id).subscribe(result => {
        this.planNudges = result;

        this.planNudges.forEach(nudge => {
          if (this.objectives.filter(x => x.id == nudge.program_goal_id).length == 0) {
            this.objectives.push({
              id: nudge.program_goal_id,
              name: nudge.sprint_goal_name,
              expanded: true
            });
          }
        });

        this.showLoader = false;
      });
    //});
  }
  getSprintGoals() {
    this.setupService.getYourTeamSprintDetails().subscribe(res => {
      this.sprintData = res;
      this.selectedGoalId = this.sprintData?.sprintdetails[0].program_goal_id;
    })
  }

  //private getFrequencyOptions(): void {
    // this.setupService.getScheduleTime().subscribe(result => {
    //   this.frequencyOptions = result;
    // });
  //}

  toggleDialog(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template, { ignoreBackdropClick: true });
  }
  hidePopUp() {
    this.bsModalRef.hide();
  }

  public filteredNudges(objectiveId: number): Array<PlanNudge> {
    return this.planNudges.filter(x => x.program_goal_id == objectiveId);
  }

  // public calculatePercentageScheduled(row: PlanNudge): string {
  //   let itemIndex = this.planNudges.findIndex(item => item.user_action_id == row.user_action_id);
  //   let nudge = this.planNudges[itemIndex];

  //   let numOfScheduledDays = 0;

  //   if (nudge.d1_is_active) {
  //     numOfScheduledDays++;
  //   }
  //   if (nudge.d2_is_active) {
  //     numOfScheduledDays++;
  //   }
  //   if (nudge.d3_is_active) {
  //     numOfScheduledDays++;
  //   }
  //   if (nudge.d4_is_active) {
  //     numOfScheduledDays++;
  //   }
  //   if (nudge.d5_is_active) {
  //     numOfScheduledDays++;
  //   }
  //   if (nudge.d6_is_active) {
  //     numOfScheduledDays++;
  //   }
  //   if (nudge.d7_is_active) {
  //     numOfScheduledDays++;
  //   }

  //   return ((numOfScheduledDays / this.calculateDaysForFrequencyOption(nudge.scheduled_time_id)) * 100).toFixed(0);
  // }

  // public calculatePercentageTimed(row: PlanNudge): string {
  //   let itemIndex = this.planNudges.findIndex(item => item.user_action_id == row.user_action_id);
  //   let nudge = this.planNudges[itemIndex];
    
  //   let numOfTimedDays = 0;

  //   if (nudge.d1_start_time) {
  //     numOfTimedDays++;
  //   }
  //   if (nudge.d2_start_time) {
  //     numOfTimedDays++;
  //   }
  //   if (nudge.d3_start_time) {
  //     numOfTimedDays++;
  //   }
  //   if (nudge.d4_start_time) {
  //     numOfTimedDays++;
  //   }
  //   if (nudge.d5_start_time) {
  //     numOfTimedDays++;
  //   }
  //   if (nudge.d6_start_time) {
  //     numOfTimedDays++;
  //   }
  //   if (nudge.d7_start_time) {
  //     numOfTimedDays++;
  //   }
    
  //   return ((numOfTimedDays / this.calculateDaysForFrequencyOption(nudge.scheduled_time_id)) * 100).toFixed(0);
  // }

  // private calculateDaysForFrequencyOption(frequencyId: number): number {
  //   switch(frequencyId) {
  //     case 1:
  //       return 1;
  //     case 2:
  //       return 2;
  //     case 3:
  //       return 3;
  //     case 4:
  //       return 4;
  //     case 5:
  //       return 5;
  //     case 6:
  //       return 6;
  //     case 7:
  //       return 7;
  //     case 8:
  //       return 5;
  //     case 9:
  //       return 2;
  //     default:
  //       return 0;
  //   }
  // }

  // public calculateCellColour(row: PlanNudge, timed: boolean): string {
  //   let percentage;
  //   if (timed) {
  //     percentage = this.calculatePercentageTimed(row);
  //   } else {
  //     percentage = this.calculatePercentageScheduled(row);
  //   }

  //   if (percentage >= 66.6) {
  //     return 'green';
  //   } else if (percentage >= 33.4 && percentage < 66.5) {
  //     return 'amber';
  //   } else {
  //     return 'red';
  //   }
  // }

  public toggleObjective(objective): void {
    objective.expanded = !objective.expanded;
  }

  public incrementGoal(increment: number): void {
    this.selectedGoalIndex = this.selectedGoalIndex + increment;
    this.selectedGoalId = this.sprintData?.sprintdetails[this.selectedGoalIndex].program_goal_id;
  }

  public frequencyChanged(): void {
    this.navPreventerService.setRoutingLock("frequencyChanged");
  }

  public closeDialog(): void {
    this.showPreventLeavingMessage = false;
  }

  public clearLocksAndNavigate(): void {
    this.navPreventerService.removeAllRoutingLocks();
    this.router.navigate([this.requestedURLBeforeLock]);
  }
}
