import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TimeSpan } from "../utils/timespan";
import { SettingsService } from "./settings.service";

@Injectable({ providedIn: 'root' })
export class PlanService {
    constructor(
        private http: HttpClient, 
        private settingsService: SettingsService) {

    }

    getPlanNudges(program_id: number): Observable<Array<PlanNudge>> {
        let params = new HttpParams();
        params = params.append("program_id", program_id.toString());
        return this.http.get<Array<PlanNudge>>(`${this.settingsService.settings.apiUrl}/plan/GetPlanNudges`, { params: params });
    }

    savePlanNudges(planNudges: Array<PlanNudge>) {
        return this.http.post(`${this.settingsService.settings.apiUrl}/plan/SavePlanNudges`, planNudges);
    }

    getOOOInstances(): Observable<Array<OOOInstance>> {
        return this.http.get<Array<OOOInstance>>(`${this.settingsService.settings.apiUrl}/plan/GetOutOfOfficeInstances`);
    }

    saveUpdateOOOInstances(oooInstance: OOOInstance): Observable<any> {
        return this.http.post(`${this.settingsService.settings.apiUrl}/plan/SaveUpdateOutOfOfficeInstances`, oooInstance);
    }
}

export class PlanNudge {
    constructor(data?: any) {
        Object.assign(this, data)   
    }

    user_action_id: number;
    action_name: string;
    action_description: string;
    category_name: string;
    scheduled_time_id: number;
    scheduled_time_name: string;
    number_days_per_week: number;
    program_goal_id: number;
    sprint_goal_name: string;

    d1_is_active: boolean;
    d1_start_time: string;
    D1_end_time: string;

    d2_is_active: boolean;
    d2_start_time: string;
    D2_end_time: string;

    d3_is_active: boolean;
    d3_start_time: string;
    D3_end_time: string;

    d4_is_active: boolean;
    d4_start_time: string;
    D4_end_time: string;

    d5_is_active: boolean;
    d5_start_time: string;
    D5_end_time: string;

    d6_is_active: boolean;
    d6_start_time: string;
    D6_end_time: string;

    d7_is_active: boolean;
    d7_start_time: string;
    D7_end_time: string;
}

export class OOOInstance {
    constructor(data: any) {
        Object.assign(this, data)   
    }

    user_absence_id: number;
    user_id: number;
    from_date: Date;
    to_date: Date;
    reason: string;
}