import { Component,EventEmitter,Input,OnInit, Output, SimpleChanges} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'image-uploader-chat',
  templateUrl: './image-uploader-chat.component.html',
  styleUrls: ['./image-uploader-chat.component.scss']
})
export class ImageUploaderChatComponent implements OnInit {
    // @Output() imageChange: EventEmitter<string> = new EventEmitter<string>();
    // @Input() image: string;

    // @Input() defaultImage: string;
    // @Input() showIcon: boolean = true;

    // public imageChangedEvent: any = '';
    // public showCropper: boolean = false;
    // private originalImage: string;
    // public showFileSizeError: boolean = false;
    selectedFiles?: FileList;
    progressInfos: any[] = [];
    messages: string[] = [];

    previews: string[] = [];
    leftColumnPreviews: string[] = [];
    rightColumnPreviews: string[] = [];
    imageInfos?: Observable<any>;
    
    constructor() { }

    public ngOnInit(): void {
      // this.imageInfos = this.uploadService.getFiles();
    }

    // public ngOnChanges(changes: SimpleChanges): void {
    //     if(changes.image && changes.image.currentValue) {
    //       this.originalImage = changes.image.currentValue;
    //     }
    //   }

    // public processFile(event: any) {
    //     var fileSize = event.target.files[0].size;
    //     if (fileSize > 1000000)
    //     this.showFileSizeError = true;
    //     else {
    //     this.imageChangedEvent = event;
    //     this.showCropper = true;
    //     this.showFileSizeError = false;
    //     }
    // }

    // public imageCropped(event: ImageCroppedEvent) {
    //     this.image = event.base64.split("base64,")[1];
    // }

    // public selectCroppedImage() {
    //     this.showCropper = false;
    //     this.imageChange.emit(this.image);
    // }

    // public cancelCroppedImage() {
    //     this.showCropper = false;
    //     this.image = this.originalImage;
    // }

    // public getDefaultImage(): string {
    //   if (this.defaultImage)
    //     return this.defaultImage;
    //   else
    //     return "../../../assets/img/icons/camera-retro-solid.png";
    // }

    updateSelectedFiles(event: any): void {
      this.messages = [];
      this.progressInfos = [];
      this.leftColumnPreviews = [];
      this.rightColumnPreviews = [];
      this.selectedFiles = event.target.files;
    
      this.previews = [];
      if (this.selectedFiles && this.selectedFiles[0]) {
        const numberOfFiles = this.selectedFiles.length;
        for (let i = 0; i < numberOfFiles; i++) {
          const reader = new FileReader();
    
          reader.onload = (e: any) => {
            console.log(e.target.result);
            this.previews.push(e.target.result);

            if (i % 2 == 0)
              this.rightColumnPreviews.push(e.target.result);
            else
              this.leftColumnPreviews.push(e.target.result)
          };
    
          reader.readAsDataURL(this.selectedFiles[i]);
        }
      }
    }

    uploadFiles(): void {
      this.messages = [];
    
      if (this.selectedFiles) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    }

    upload(idx: number, file: File): void {
      this.progressInfos[idx] = { value: 0, fileName: file.name };
    
      if (file) {
        // this.uploadService.upload(file).subscribe({
        //   next: (event: any) => {
        //     if (event.type === HttpEventType.UploadProgress) {
        //       this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        //     } else if (event instanceof HttpResponse) {
        //       const msg = 'Uploaded the file successfully: ' + file.name;
        //       this.message.push(msg);
        //       this.imageInfos = this.uploadService.getFiles();
        //     }
        //   },
        //   error: (err: any) => {
        //     this.progressInfos[idx].value = 0;
        //     const msg = 'Could not upload the file: ' + file.name;
        //     this.message.push(msg);
        //   }});
      }
    }
}