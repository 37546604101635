import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";


@Injectable()
export class AddSetupService {

    constructor() {
        
    }

    get programId() {
        return JSON.parse(localStorage.getItem('programId'));
    }
    set programId(data: any) {
        localStorage.setItem('programId', data);
    }
    get projectId() {
        return JSON.parse(localStorage.getItem('projectId'));
    }
    set projectId(data: any) {
        localStorage.setItem('projectId', data);
    }
   
}