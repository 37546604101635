import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SessionModel } from '../models/session.model';
import { app_session_name, app_session_sprint_name, app_session_task_name } from '../constants/app.constant';
import { DecryptString, EncryptString } from '../utils/encrypation.util';
import { LoginResponse } from '../models/responses/login.response';
import { ApiResultDto } from '../models/responses/common/apiResultDto';
import { Sprint } from '../models/responses/sprint.response';
import { RegisterUserRequest } from '../models/requests/register.request';
import { ComboResponse } from '../models/responses/combos.response';
import { Profile } from '../models/requests/profile.request';
import { PermissionsResponse } from '../models/responses/permissions.response';
import { TaskResponse } from '../models/responses/task.response';
import { SettingsService } from './settings.service';
import { Router } from '@angular/router';
import { request } from 'https';
import { BasicCompany, Company } from './setup.service';
import { SignalrService } from './signalr.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<SessionModel>;
  public currentUser: Observable<SessionModel>;
  private currentSprintSubject: BehaviorSubject<Sprint>;
  public currentSprint: Observable<Sprint>;
  private currentTaskSubject: BehaviorSubject<TaskResponse>;
  public currentTask: Observable<TaskResponse>;
  private currentPermissionSubject: BehaviorSubject<PermissionsResponse[]>;
  public skipSlothScreen = false;

  sidebarHideChange: Subject<boolean> = new Subject<boolean>();
  isSidebarHide: boolean;

  public showClientLayoutChange: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient, private settingsService: SettingsService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<SessionModel>(DecryptString(localStorage.getItem(app_session_name)));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentSprintSubject = new BehaviorSubject<Sprint>(DecryptString(localStorage.getItem(app_session_sprint_name)));
    this.currentSprint = this.currentSprintSubject.asObservable();
    this.currentTaskSubject = new BehaviorSubject<TaskResponse>(DecryptString(localStorage.getItem(app_session_task_name)));
    this.currentTask = this.currentTaskSubject.asObservable();
    this.currentPermissionSubject = new BehaviorSubject<PermissionsResponse[]>([]);
    this.sidebarHideChange.subscribe((value) => {
      this.isSidebarHide = value;
    });
    this.sidebarHideChange.next(false);
  }

  public get currentUserValue(): SessionModel {
    return this.currentUserSubject.value;
  }
  public get currentSprintValue(): Sprint {
    return this.currentSprintSubject.value;
  }
  public get currentTaskValue(): TaskResponse {
    return this.currentTaskSubject.value;
  }
  public get currentPermissionValue(): PermissionsResponse[] {
    return this.currentPermissionSubject.value;
  }

  public get isNewUser(): boolean {
    // return this.currentSprintValue === null;
    return false;
  }
  public isBigAdmin(): boolean {
    return this.currentUserValue.User_Role === 5;
  }

  hideSidebarVisibility(value: boolean) {
    this.sidebarHideChange.next(value);
  }

  showhideClientLayout(clientId: number): void {
    this.showClientLayoutChange.next(clientId);
  }

  updateUserProfile(profile: Profile) {
    let newProfile = this.currentUserValue;
    newProfile.User_Full_Name = profile.user_profile.full_name;
    newProfile.User_First_Name = profile.user_profile.first_name;
    newProfile.User_Email = profile.user_profile.email_address;
    newProfile.User_Job_Title = profile.user_profile.job_title;
    newProfile.User_Profile_Pic = profile.user_profile.profile_pic;
    newProfile.time_zone_id = profile.user_profile.time_zone_id;
    newProfile.personality_type_id = profile.user_profile.personality_type_id;
    localStorage.setItem(app_session_name, EncryptString(JSON.stringify(newProfile)));
    this.currentUserSubject.next(newProfile);
  }

  updateSprintAndTask(sprint: Sprint, nextTask: TaskResponse) {
    localStorage.setItem(app_session_sprint_name, EncryptString(JSON.stringify(sprint)));
    localStorage.setItem(app_session_task_name, EncryptString(JSON.stringify(nextTask)));
    this.currentSprintSubject.next(sprint);
    this.currentTaskSubject.next(nextTask);
  }

  login(username: string, password: string): Observable<ApiResultDto<LoginResponse>> {
    return this.http.post<ApiResultDto<LoginResponse>>(`${this.settingsService.settings.apiUrl}/auth/login`, { email: username, password })
      .pipe(map(response => {
        if (response.isSuccess) {
          this.setAuthConfig(response);
        }
        return response;
      }));
  }

  loginWithAccessToken(token: string) {
    return this.http.post<ApiResultDto<LoginResponse>>(`${this.settingsService.settings.apiUrl}/auth/LoginWithEncryptEmail`, { email: token })
      .pipe(map(response => {
        if (response.isSuccess) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let sessionModel = new SessionModel();
          sessionModel.Client_ID = response.result.user.client_Id;
          sessionModel.User_ID = response.result.user.user_Id;
          sessionModel.User_Email = response.result.user.email_Address;
          sessionModel.User_Full_Name = response.result.user.full_Name;
          sessionModel.User_First_Name = response.result.user.first_Name;
          sessionModel.User_Token = response.result.token;
          sessionModel.User_Job_Title = response.result.user.job_Title;
          sessionModel.Program_ID = response.result.sprint?.program_id || 0;
          sessionModel.User_Program_ID = response.result.sprint?.user_program_id || 0;
          sessionModel.User_Profile_Pic = response.result.user.profile_Pic;
          sessionModel.User_Role = response.result.user.role_id;
          localStorage.setItem(app_session_name, EncryptString(JSON.stringify(sessionModel)));
          localStorage.setItem(app_session_sprint_name, EncryptString(JSON.stringify(response.result.sprint)));
          localStorage.setItem(app_session_task_name, EncryptString(JSON.stringify(response.result.nextTask)));
          this.currentUserSubject.next(sessionModel);
          this.currentSprintSubject.next(response.result.sprint);
          this.currentTaskSubject.next(response.result.nextTask);
        }
        return response;
      }));
  }

  requestOneTimeAccessToken(email: string) {
    return this.http.get<ApiResultDto<string>>(`${this.settingsService.settings.apiUrl}/auth/gotat?userEmail=${email}`);
  }

  validateOneTimeAccessToken(email: string, token: string) {
    return this.http.get<ApiResultDto<LoginResponse>>(`${this.settingsService.settings.apiUrl}/auth/votat?userEmail=${email}&token=${token}`)
      .pipe(map(response => {
        if (response.isSuccess) {
          this.setAuthConfig(response);
        }
        return response;
      }));
  }

  InviteTryoutUser(userEmails: Array<string>, client: BasicCompany) {
    const tryoutUserInvite = {
      userEmails: userEmails,
      client: client
    };
    return this.http.post<ApiResultDto<string>>(`${this.settingsService.settings.apiUrl}/auth/InviteTryoutUser`, tryoutUserInvite);
  }

  InviteNewlyAddedUser(email: string, clientId: number) {
    return this.http.get<ApiResultDto<string>>(`${this.settingsService.settings.apiUrl}/auth/InviteNewlyAddedUser?userEmail=${email}&clientId=${clientId}`);
  }

  getPermission() {
    if (this.currentPermissionValue.length > 0) {
      return of(this.currentPermissionValue);
    }
    return this.http.get<PermissionsResponse[]>(`${this.settingsService.settings.apiUrl}/load/getPermissionList?user_id=${this.currentUserValue.User_ID}`);
  }

  register(params: RegisterUserRequest, accountCreationAlreadyStarted: boolean) {
    return this.http.post<ApiResultDto<number>>(`${this.settingsService.settings.apiUrl}/auth/RegisterUser?accountCreationAlreadyStarted=${accountCreationAlreadyStarted}`, params);
  }

  getManagerComboList() {
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Load/GetCoachAndManagerList?user_id=${this.currentUserValue?.Client_ID}`)
      .pipe(map(results => {
        return results.filter(x => x.type === "MANAGER");
      }));
  }

  changePassword(body: any, completingAccountCreation: boolean) {
    return this.http.post<ApiResultDto<LoginResponse>>(`${this.settingsService.settings.apiUrl}/auth/changepassword?completingAccountCreation=${completingAccountCreation}`, body)
    .pipe(map(response => {
      if (response.isSuccess) {
        this.setAuthConfig(response);
      }
      return response;
    }));
  }

  forgotPassword(email: string) {
    return this.http.post<ApiResultDto<boolean>>(`${this.settingsService.settings.apiUrl}/auth/ForgotPassword?email=${email}`, null);
  }

  otpValidate(otp: string, email: string) {
    return this.http.post<ApiResultDto<LoginResponse>>(`${this.settingsService.settings.apiUrl}/auth/OTPAuthentication?OTP=${otp}&email_address=${email}`, null)
      .pipe(map(response => {
        if (response.isSuccess) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let sessionModel = new SessionModel();
          sessionModel.Client_ID = response.result.user.client_Id;
          sessionModel.User_ID = response.result.user.user_Id;
          sessionModel.User_Email = response.result.user.email_Address;
          sessionModel.User_Full_Name = response.result.user.full_Name;
          sessionModel.User_First_Name = response.result.user.first_Name;
          sessionModel.User_Token = response.result.token;
          sessionModel.User_Job_Title = response.result.user.job_Title;
          sessionModel.Program_ID = response.result.sprint?.program_id || 0;
          sessionModel.User_Program_ID = response.result.sprint?.user_program_id || 0;
          sessionModel.User_Profile_Pic = response.result.user.profile_Pic;
          sessionModel.User_Role = response.result.user.role_id;
          localStorage.setItem(app_session_name, EncryptString(JSON.stringify(sessionModel)));
          localStorage.setItem(app_session_sprint_name, EncryptString(JSON.stringify(response.result.sprint)));
          localStorage.setItem(app_session_task_name, EncryptString(JSON.stringify(response.result.nextTask)));
          this.currentUserSubject.next(sessionModel);
          this.currentSprintSubject.next(response.result.sprint);
          this.currentTaskSubject.next(response.result.nextTask);

        }
        return response;
      }));
  }
  emailVerification(email: string) {
    return this.http.post<ApiResultDto<any>>(`${this.settingsService.settings.apiUrl}/auth/EmailVerification?&email=${email}`, null);
  }

  resetPassword(password: string, email: string) {
    return this.http.post<ApiResultDto<boolean>>(`${this.settingsService.settings.apiUrl}/auth/ResetPassword`, {
      email: email,
      password: password
    });
  }

  updatePassword(password: string, user_id: number) {
    return this.http.post<ApiResultDto<boolean>>(`${this.settingsService.settings.apiUrl}/auth/UpdatePassword`, {
      user_id: user_id,
      password: password
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(app_session_name);
    localStorage.removeItem(app_session_sprint_name);
    localStorage.removeItem(app_session_task_name);
    localStorage.removeItem("refreshToken");
    this.currentUserSubject.next(null);
    this.stopRefreshTokenTimer();
    this.router.navigate(['auth/login']);
  }

  refreshExpiredAccessToken(): Observable<ApiResultDto<any>> {
    let sessionModel = DecryptString(localStorage.getItem(app_session_name));
    let refreshToken = localStorage.getItem("refreshToken");

    return this.http.post<ApiResultDto<any>>(`${this.settingsService.settings.apiUrl}/auth/RefreshExpiredAccessToken`, {
      accessToken: sessionModel.User_Token,
      refreshToken: refreshToken
    })
      .pipe(map(response => {
        if (response.isSuccess) {

          sessionModel.User_Token = response.result.accessToken;
          localStorage.setItem(app_session_name, EncryptString(JSON.stringify(sessionModel)));
          localStorage.setItem("refreshToken", response.result.refreshToken);

          this.currentUserSubject.next(sessionModel);
          this.startRefreshTokenTimer(response.result.accessToken);
        } else {
          this.logout();
        }
        return response;
      }));
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer(token: string) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(token.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshExpiredAccessToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }

  public somethingWentWrong(email: string): Observable<any> {
    return this.http.post<any>(`${this.settingsService.settings.apiUrl}/auth/SomethingWentWrong`, {
      email: email
    });
  }

  private setAuthConfig(response: ApiResultDto<LoginResponse>) {
    if (response.result === null)
      return;

    // store user details and jwt token in local storage to keep user logged in between page refreshes
    let sessionModel = new SessionModel();
    sessionModel.Client_ID = response.result.user.client_Id;
    sessionModel.User_ID = response.result.user.user_Id;
    sessionModel.User_Email = response.result.user.email_Address;
    sessionModel.User_Full_Name = response.result.user.full_Name;
    sessionModel.User_First_Name = response.result.user.first_Name;
    sessionModel.User_Token = response.result.token;
    sessionModel.User_Job_Title = response.result.user.job_Title;
    sessionModel.Program_ID = response.result.sprint?.program_id || 0;
    sessionModel.User_Program_ID = response.result.sprint?.user_program_id || 0;
    sessionModel.User_Profile_Pic = response.result.user.profile_Pic;
    sessionModel.User_Role = response.result.user.role_id;
    localStorage.setItem(app_session_name, EncryptString(JSON.stringify(sessionModel)));
    localStorage.setItem(app_session_sprint_name, EncryptString(JSON.stringify(response.result.sprint)));
    localStorage.setItem(app_session_task_name, EncryptString(JSON.stringify(response.result.nextTask)));
    localStorage.setItem("refreshToken", response.result.refreshToken);
    this.currentUserSubject.next(sessionModel);
    this.currentSprintSubject.next(response.result.sprint);
    this.currentTaskSubject.next(response.result.nextTask);
    this.startRefreshTokenTimer(response.result.token);
  }

  directApiRequest(url: string) {
    return this.http.get<any>(`${url}`);
  }

  getUnreadChatsForUser(user_id: number) {
    return this.http.get<Array<number>>(`${this.settingsService.settings.apiUrl}/auth/GetUnreadChats?user_id=${user_id}`);
  }

  markChatWithUserAsRead(from_user_id: number, to_user_id: number) {
    return this.http.get(`${this.settingsService.settings.apiUrl}/auth/MarkChatWithUserAsRead?from_user_id=${from_user_id}&to_user_id=${to_user_id}`);
  }

  getUsersUnreadChats(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.settingsService.settings.apiUrl}/auth/GetUsersUnreadChats`);
  }

  getUsersCoachChats(coach_id: number): Observable<number> {
    return this.http.get<number>(`${this.settingsService.settings.apiUrl}/auth/GetUsersCoachChats?coach_id=${coach_id}`);
  }
}
