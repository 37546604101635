import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ChartOptions } from "chart.js";
import { TimeSpent } from "../../models/responses/time-spent.response";

@Component({
  selector: 'app-time-spent-chart',
  templateUrl: './time-spent-chart.component.html',
})

export class TimeSpentChartComponent implements OnInit, OnChanges {
    timeSpentChart = {
        isLoaded: false,
        labels: [],
        data: [],
        options: {},
    };
    @Input() data: TimeSpent[] = [];
    @Output() onChartClick = new EventEmitter<any>();
    constructor(){

    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.data && changes.data.currentValue && changes.data.currentValue.length > 0){
            this.data = changes.data.currentValue as TimeSpent[] || [];
            this.setDataToChart();
        }
    }

    setDefaultChartOptions() {
        const options: ChartOptions = {
            responsive: true,
            tooltips: {
                enabled: true,
            },
            legend: {
                position: 'bottom',
            },
        };
        this.timeSpentChart.options = options;
        this.timeSpentChart.isLoaded = true;
    }

    setDataToChart(): void {
        let data = this.data.map(x => Number.parseInt(x.completion_rate) > 0 && Number.parseInt(x.completion_rate));
        let labels = this.data.map(x => Number.parseInt(x.completion_rate) > 0 && x.action_category);
        var backgroundColors = [];
        var hoverBackgroundColors = [];
        data.map(x => {
            if(x >= 90){
                backgroundColors.push("rgba(133, 202, 135)");
            } else if(x >= 75 && x < 90){
                backgroundColors.push("rgba(254, 204, 86)");
            } else{
                backgroundColors.push("rgba(241, 57, 94)");
            }
        });
        this.timeSpentChart.data = [
            {
                data: data,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: hoverBackgroundColors,
            },
        ];
        this.timeSpentChart.labels = labels;
        this.setDefaultChartOptions();
    }

    handleChartClick(e: any): void {
        this.onChartClick.emit(e);
    }
}