import { Component, Input, OnInit } from '@angular/core';
import { MotivationalNugget } from '../../../services/process.service';

@Component({
  selector: 'nugget-transcript',
  templateUrl: './nugget-transcript.component.html',
  styleUrls: ['./nugget-transcript.component.scss']
})
export class NuggetTranscriptComponent implements OnInit {

    @Input() nugget: MotivationalNugget;

    constructor() { }

    public ngOnInit(): void {

    }
}