import { Component,EventEmitter,Input,OnInit, Output, SimpleChanges} from '@angular/core';
import { Icon, SaveIcon, SetupService } from '../../services/setup.service';

@Component({
  selector: 'values-icon-picker',
  templateUrl: './values-icon-picker.component.html',
  styleUrls: ['./values-icon-picker.component.scss']
})
export class ValuesIconPickerComponent implements OnInit {
    @Input() clientId: number;
    @Output() iconIdChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() iconId: number;
    public icons: Array<Icon>;

    private _newIcon: string;

    get newIcon(): string {
        return this._newIcon;
    }
    set newIcon(value: string) {
        this._newIcon = value;
        this.saveIcon();
    }

    public showIconSelector: boolean = false;
    public showLoader: boolean = false;
    public showChooseIcon: boolean = false;
    public showExistingLibrary: boolean = false;
    public showExistingUploads: boolean = false;
    public image: string;

    constructor(private setupService: SetupService) {

    }

    ngOnInit(): void {
        if (this.iconId)
            this.load();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    public load(): void {
        this.setupService.getIcon(this.clientId, this.iconId).subscribe(result => {
            this.image = result.icon;
        });
    }

    public selectExistingLibrary(): void {
        this.showLoader = true;
        this.setupService.getIconList(this.clientId, 2).subscribe(result => {
            this.icons = result;
            this.showIconSelector = true;
            this.showLoader = false;
            this.showExistingLibrary = true;
            this.showExistingUploads = false;
        });
    }

    public selectExistingUploads(): void {
        this.showLoader = true;
        this.setupService.getIconList(this.clientId, 1).subscribe(result => {
            this.icons = result;
            this.showIconSelector = true;
            this.showLoader = false;
            this.showExistingLibrary = false;
            this.showExistingUploads = true;
        });
    }

    private saveIcon(): void {
        var icon = new SaveIcon();
        icon.client_id = this.clientId;
        icon.icon = this.newIcon;
        icon.icon_category_id = 1;
        icon.is_active = true;

        this.setupService.saveAndUpdateIcon(icon).subscribe(result => {
            icon.icon_id = result;
            this.selectIcon(icon);
            this.closeDialog();
        });
        
    }

    public selectIcon(icon: Icon): void {
        this.iconIdChange.emit(icon.icon_id);
        this.image = icon.icon;
        this.closeDialog();
    }

    public showChooseIconDialog(): void {
        this.showChooseIcon = true;
    }

    public closeDialog(): void {
        this.showChooseIcon = false;
    }
}