import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FeedbackService } from '../../services/feedback.service';
import { SetupService } from '../../services/setup.service';

@Component({
  selector: 'app-schedule-change',
  templateUrl: './schedule-change.component.html',
  styleUrls: ['./schedule-change.component.css']
})
export class ScheduleChangeComponent implements OnInit {

  constructor(private router: Router, private feedbackService: FeedbackService, private setupService: SetupService, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  onClickAction(state: boolean) {
    if (state) {
      this.feedbackService.rescheduleWeeklyNudge().subscribe(result => {
        this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
        this.router.navigate(['/plan']);
      });
    } else {
      this.feedbackService.rescheduleWeeklyNudge().subscribe(result => {
        this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
        location.reload();
      });
    }
  }

}
