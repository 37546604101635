import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgxRolesService } from 'ngx-permissions';
import { AuthenticationService } from '../../shared/services/authentication.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UserValidatedGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService, private rolePermissionService: NgxRolesService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.rolePermissionService.roles$.subscribe(results => {
      if (!results) {
        return false;
      }
      const sprint = this.authService.currentSprintValue;
      const nextTask = this.authService.currentTaskValue;

      if (sprint !== null && nextTask !== null && sprint.sprint_template_id != 0) {
        if (sprint.is_sprint_active == true) {
          return true;
        }
        
        if (moment(sprint.training_date).isAfter(moment())) {
          if (this.authService.skipSlothScreen) {
            return true;
          } else {
            this.router.navigateByUrl('tours?tour_step_id=10');
            return false;
          }
        } else {
          this.router.navigateByUrl('tours?tour_step_id=27');
          return false;
        }
      }

      if (sprint !== null && nextTask !== null && nextTask.task_type_id === 5) {
        this.router.navigate(['auth', 'setup-wiz']);
        this.authService.hideSidebarVisibility(true);
        return false;
      } else {
        this.authService.hideSidebarVisibility(false);
      }
    });
    return true;
  }
}
