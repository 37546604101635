import { Component,EventEmitter,Input,OnInit, Output, SimpleChanges} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
    @Output() imageChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() image: string;

    @Input() defaultImage: string;
    @Input() showIcon: boolean = true;

    public imageChangedEvent: any = '';
    public showCropper: boolean = false;
    private originalImage: string;
    public showFileSizeError: boolean = false;
    
    constructor() {

    }

    public ngOnInit(): void {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(changes.image && changes.image.currentValue) {
          this.originalImage = changes.image.currentValue;
        }
      }

    public processFile(event: any) {
        var fileSize = event.target.files[0].size;
        if (fileSize > 1000000)
        this.showFileSizeError = true;
        else {
        this.imageChangedEvent = event;
        this.showCropper = true;
        this.showFileSizeError = false;
        }
    }

    public imageCropped(event: ImageCroppedEvent) {
        this.image = event.base64.split("base64,")[1];
    }

    public selectCroppedImage() {
        this.showCropper = false;
        this.imageChange.emit(this.image);
    }

    public cancelCroppedImage() {
        this.showCropper = false;
        this.image = this.originalImage;
    }

    public getDefaultImage(): string {
      if (this.defaultImage)
        return this.defaultImage;
      else
        return "../../../assets/img/icons/camera-retro-solid.png";
    }
}