import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AddSetupService } from '../../../../views/account/add-setup/add-setup.service';
import { BusinessSprintResponse } from '../../../models/responses/business-sprint.response';
import { BusinessAmbition } from '../../../models/responses/businessAmbition.response';
import { SetupService } from '../../../services/setup.service';

@Component({
  selector: 'app-business-ambition-list',
  templateUrl: './business-ambition.component.html',
  styleUrls: ['./business-ambition.component.css']
})
export class BusinessAmbitionComponent implements OnInit {
  @Input() mode: "ADD" | "EDIT" | "VIEW" = "ADD";
  @Input() businessAmbitions: BusinessAmbition[] = [];
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @Output() onClickNew: EventEmitter<boolean> = new EventEmitter();

  isAmbitionFormVisible: boolean = false;
  isBusinessSprintVisible: boolean = false;
  formMode: string;
  selectedData: BusinessAmbition = null;
  businessSprints: BusinessSprintResponse[] = [];

  constructor(private router: Router, private setupService: SetupService, private addSetupService: AddSetupService) { }

  ngOnInit(): void {

  }

  actionClick(action: string, data?: BusinessAmbition) {
    this.formMode = action;
    this.selectedData = data;
    this.isAmbitionFormVisible = true;
  }

  toggleSprints(data?: BusinessAmbition) {
    this.selectedData = data;
    if (!this.isBusinessSprintVisible) {
      this.getBusinessSprints(() => {
        this.isBusinessSprintVisible = !this.isBusinessSprintVisible;
      });
    } else {
      this.isBusinessSprintVisible = !this.isBusinessSprintVisible;
    }
  }

  getBusinessSprints(cb: () => void = null) {
    this.setupService.getBusinessSprint(this.selectedData.project_id).subscribe(result => {
      this.businessSprints = result;
      cb && cb();
    });
  }

  formClose(state: number) {
    this.isAmbitionFormVisible = false;
    this.selectedData = null;
    if (state > -1) {
      this.refreshData.emit();
    }
  }
  addBusinessAmbitionClick() {
    this.onClickNew.emit();
  }
}
