import { Component, OnInit } from '@angular/core';
import { RoleEnum } from '../../shared/enums/roles.enum';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { IDashboard, IGroup, ReportingService } from '../../shared/services/reporting.service';
import { IOptions } from './dashboards/my/mydashboard.component';


@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {

  constructor(
    private reportingService: ReportingService,
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => {
      var currentSession = x;
      this.isManager = currentSession.User_Role === RoleEnum.Manager;
    });
  }

  public dashboards: Dashboard[];
  public groups: IGroup[];
  public tabs: Tab[] = new Array();
  public isMultiClient: boolean;
  public clients: any[] = new Array();
  private isManager: boolean;

  ngOnInit(): void {
    this.reportingService.getAvailableDashboardGroups(0).subscribe(result => {
      
      result.forEach(group => {
        this.tabs.push(new Tab({
          name: group.dashboard_group_name,
          type: TabType.Dashboard,
          group:  group
        }));
      });

      this.tabs.push(new Tab({
        name: "Report Packs",
        type: TabType.Exports,
        group: {
          dashboard_group_id: null
        }
      }));

      //Load first dashboard
      if (this.tabs.length > 0)
        this.onTabSelect(TabType.Dashboard, this.tabs[0].group.dashboard_group_id, this.tabs[0].group.is_multi_client);
    });
  }

  onTabSelect(type: TabType, groupId: number, isMultiClient: boolean): void {
    if (type == TabType.Exports) {
      this.clearDashboards();
      this.isMultiClient = false;
    } else if (type == TabType.Dashboard) {
      this.isMultiClient = isMultiClient;

      if (isMultiClient) {
        this.reportingService.getAccessibleClientList().subscribe(result => {
          this.clients = result;
          var selectedClientId = 0;
          if (this.clients && this.clients.length > 0)
          selectedClientId = this.clients[0].client_id;

          this.loadDashboard(groupId, selectedClientId);
        });
      } else {
        this.loadDashboard(groupId, 0);
      }
    }
  }

  loadDashboard(groupId: number, selectedClientId: number) {
    this.reportingService.getAvailableDashboards(groupId).subscribe(result => {
      //Reset dashboard array
      this.dashboards = new Array();
      
      result.forEach(dashboard => {
        var newDashboard = new Dashboard(dashboard);
        newDashboard.options.selectedClient = selectedClientId,
        newDashboard.options.coached_user_id = dashboard.user_id;
        this.dashboards.push(newDashboard);
      });

      //Load first dashboard
      if (this.dashboards.length > 0) {
        this.dashboards[0].options.isCurrent = true;
      }
    });
  }

  onDashboardSelect(dashboardId: number): void {
    this.dashboards.forEach(dashboard => {
      if (dashboard.dashboard_id == dashboardId)
        dashboard.options.isCurrent = true;
      else
        dashboard.options.isCurrent = false;
    })
  }

  clearDashboards(): void {
    this.dashboards = new Array();
  }

  clientChange(clientId: number): void {
    this.dashboards.forEach(dashboard => {
      dashboard.options.selectedClient = clientId;
    });
  }
}

export class Tab {
  constructor(data: any) {
    Object.assign(this, data);
  }

  name: string;
  type: TabType;
  group: IGroup;
}

export enum TabType {
  Dashboard = 0,
  Exports = 1
}

export class Dashboard implements IDashboard {
  constructor(data: any) {
    Object.assign(this, data);
    this.options = {
      isCurrent: null,
      selectedClient: 0,
      coached_user_id: 0,
      program_id: 0
    }
  }

  dashboard_id: number
  dashboard_name: string;
  user_id: number;
  options: IOptions;
}