import { Component,Input,OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
    @Input() timeLine: TimelineObject;

    public startDate: moment.Moment;
    public endDate: moment.Moment;
    public todaysDate: moment.Moment =  moment(new Date());
    public days: Array<moment.Moment> = [];
    public weekLabels: Array<WeekLabel> = [];

    constructor() {

    }

    ngOnInit(): void {
        this.startDate = moment(this.timeLine.sprint_start_date);
        this.endDate = moment(this.timeLine.sprint_end_date);

        let dayIncrement = moment(this.startDate);

        while(dayIncrement < this.endDate) {
            this.days.push(moment(dayIncrement));

            dayIncrement = dayIncrement.add(1, 'day');
        }
    }

    public getWeekLabel(day: moment.Moment): string {
        let daysDifferent = day.diff(this.startDate, 'days');
        let weekNumber = Math.floor(daysDifferent / 7);
        return "Week " + weekNumber;
    }

    public showWeekLabel(date: moment.Moment): string {
        let daysDifferent = date.diff(this.startDate, 'days');
        let weekNumber = Math.floor(daysDifferent / 7);

        if (date.day() == 1 && weekNumber > 0) //day is Tuesday
            return "block";
        else
            return "none";
    }

    public getDayColour(day: moment.Moment): string {
        if (day.isAfter(this.todaysDate)) {
            return "#D9D9D9";
        } else {
            return "#B0D5BA";
        }
    }
}

export class WeekLabel {
    public label: string;
    public xPos: number;
    public width: number;
    public index: number;
}

export class TimelineObject {
    public sprint_start_date: Date;
    public sprint_end_date: Date;
}