import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { NgxRolesService } from "ngx-permissions";
import { Observable } from "rxjs";
import { RoleEnum } from "../../shared/enums/roles.enum";
import { AuthenticationService } from "../../shared/services/authentication.service";

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<boolean> {
    constructor(private authService: AuthenticationService, private router: Router, private rolePermissionService: NgxRolesService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        var sprint = this.authService.currentSprintValue;
        var nextTask = this.authService.currentTaskValue;
        this.authService.getPermission().subscribe(results => {
            var permissions = results.map(x => x.activity_id.toString());
            var userRole = RoleEnum[this.authService.currentUserValue.User_Role];
            this.rolePermissionService.addRoleWithPermissions(userRole, permissions);
            return true;
        });
    }
}