import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WeekViewModel } from "ngx-bootstrap/datepicker/models";
import { Observable } from "rxjs";
import * as internal from "stream";
import { SettingsService } from "./settings.service";
import { Resource, ResourceGroup } from "./process.service";

@Injectable({ providedIn: 'root' })
export class ResourcesService {
    constructor(
        private http: HttpClient, 
        private settingsService: SettingsService) {

        }

        public getCoachingCards(client_id: number): Observable<Array<Resource>> {
            return this.http.get<Array<Resource>>(`${this.settingsService.settings.apiUrl}/resources/GetCoachingCards?client_id=${client_id}`);
        }

        public saveCoachingCard(coachingCard: Resource): Observable<any> {
            coachingCard.resource_name = coachingCard.nugget_name;
            coachingCard.resource_id = coachingCard.nugget_id;
            coachingCard.resource_description = coachingCard.nugget_description;

            return this.http.post<any>(`${this.settingsService.settings.apiUrl}/resources/SaveCoachingCard`, coachingCard);
        }

        public deleteCoachingCard(nugget_id: number): Observable<any> {
            return this.http.get<any>(`${this.settingsService.settings.apiUrl}/resources/DeleteCoachingCard?nugget_id=${nugget_id}`);
        }

        public toggleCoachingCardActivation(nugget_id: number, activate: boolean): Observable<any> {
            return this.http.get<any>(`${this.settingsService.settings.apiUrl}/resources/ToggleCoachingCardActivation?nugget_id=${nugget_id}&activate=${activate}`);
        }

        public getResourceGroups(): Observable<Array<ResourceGroup>> {
            return this.http.get<Array<ResourceGroup>>(`${this.settingsService.settings.apiUrl}/resources/GetResourceGroups`);
        }

        public uploadVideo(video: VideoUpload): Observable<any> {
            return this.http.post<any>(`${this.settingsService.settings.apiUrl}/resources/UploadVideo`, video);
        }

        public updateCoachingCardVideoProgress(nugget_id: number, time: number): Observable<any> {
            var params = new HttpParams();
            params = params.append("nugget_id", nugget_id.toString());
            params = params.append("time", Math.floor(time).toString());

            return this.http.post(`${this.settingsService.settings.apiUrl}/resources/UpdateCoachingCardVideoProgress`, {}, {
                params: params
            });
        }

        public getTour(tour_template_id: number): Observable<any> {
            return this.http.get<any>(`${this.settingsService.settings.apiUrl}/resources/GetTour?tour_template_id=${tour_template_id}`);
        }

        public getPDF(nugget_id: number): Observable<any> {
            return this.http.get(`${this.settingsService.settings.apiUrl}/resources/GetPDF?nugget_id=${nugget_id}`, { responseType: 'blob' });
        }
}

export interface ICoachingCard{
    nugget_id: number;
    nugget_name: string;
    nugget_description: string;
    icon: string;
    blob_URL: string;
    date_last_viewed: string;
    view_time: string;
    view_count: number;
    rc_group: string;
}

export class VideoUpload {
    data: number[];
    filename: string;
}

export class TourStep {
    step_name: string;
    body_text: string;
    icon: Array<number>;
    button_text: string;
    body_first: boolean;
    has_confetti: boolean;
}