import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NudgeResponse } from '../../models/responses/nudge.response';

@Component({
  selector: 'app-nudge-chart2',
  templateUrl: './nudge-chart2.component.html',
  styleUrls: ['./nudge-chart2.component.scss']
})
export class NudgeChart2Component implements OnInit, OnChanges, AfterViewChecked {
  @Input() height: number = 0;
  @Input() mode: 'min' | 'max' = 'min';
  @Input() selectedNudgeId: number = 0;
  @Input() data: NudgeResponse[];
  @Input() showImage: boolean = false;
  @Output() onClickNudge = new EventEmitter<any>();
  @ViewChild('mainContainer', { static: true }) mainContainer: ElementRef<HTMLElement>;
  @Input() totalOverallProgress: number;
  imgSrc: string = '';

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.mapClassToNudges();
  }

  ngAfterViewChecked() {
    if (this.height !== 0) {
      let height = this.height;
      // this.renderer.setStyle(this.mainContainer.nativeElement, "height", `${height}px`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.data = changes.data?.currentValue as NudgeResponse[] || [];
      if (changes.selectedNudgeId) {
        this.selectedNudgeId = changes.selectedNudgeId?.currentValue;
      }
      this.mapClassToNudges();
      this.createCustomStyle();
      this.findOverallValue();
    }
    if (changes.totalOverallProgress && changes.totalOverallProgress.currentValue) {
      this.totalOverallProgress = changes.totalOverallProgress.currentValue;
      if (this.totalOverallProgress >= 66.6) {
        this.imgSrc = './assets/img/brain_green.svg';
      } else if (this.totalOverallProgress >= 33.4 && this.totalOverallProgress < 66.5) {
        this.imgSrc = './assets/img/brain_amber.svg';
      } else {
        this.imgSrc = './assets/img/brain_red.svg';
      }
    }
  }

  findOverallValue() {
    if (!this.totalOverallProgress) {
      var length = this.data.length;
      var sum = this.data.reduce((sum, item) => sum + item.overallCompletionRate, 0);
      this.totalOverallProgress = sum / length;
    }
    if (this.totalOverallProgress >= 66.6) {
      this.imgSrc = './assets/img/brain_green.svg';
    } else if (this.totalOverallProgress >= 33.4 && this.totalOverallProgress < 66.5) {
      this.imgSrc = './assets/img/brain_amber.svg';
    } else {
      this.imgSrc = './assets/img/brain_red.svg';
    }
  }

  setSelectedNudge(nudge: NudgeResponse) {
    this.selectedNudgeId = nudge.user_action_id;
    this.onClickNudge.emit(nudge);
  }

  getIsSelected(id: number) {
    return this.selectedNudgeId === id;
  }

  getPointClass(id: number, percentage: number): string {
    if (percentage >= 66.6) {
      return 'point-green';
    } else if (percentage >= 33.4 && percentage < 66.5) {
      return 'point-orange';
    } else {
      return 'point-red';
    }
  }

  mapClassToNudges() {
    this.data.map(x => {
      x.styleClass = this.getPointClass(x.user_action_id, x.overallCompletionRate);
    });
  }

  normalAbsoluteAngleDegrees(angle: number) {
    return (angle %= 360) >= 0 ? angle : (angle + 360);
  }

  createCustomStyle() {
    let style: string = '';
    this.data.forEach((x, index) => {
      let newStyle: string =
        `.point-container-${x.user_action_id} {
          width: calc(55% + (-${x.overallCompletionRate}% / 2)) !important;
          min-width: 165px;
          transform: rotate(${x.angle}deg);
          transition-delay: ${index + 1}00ms;
        }
        .point-${x.user_action_id} {
          transform: rotate(-${this.normalAbsoluteAngleDegrees(x.angle)}deg);
        }`;
      style = style + newStyle;
    });
    this.addStyleToView(style);
  }

  addStyleToView(style: string): void {
    let styleElement = this.mainContainer.nativeElement.querySelector('style');
    if (!styleElement) {
      styleElement = document.createElement('style');
    }
    setTimeout(() => {
      styleElement.innerHTML = style;
      this.mainContainer.nativeElement.appendChild(styleElement);
      setTimeout(() => {
        this.mainContainer.nativeElement.classList.add(this.mode);
      }, 1700);
    }, 100);
  }

}
