import { Component,OnInit, ViewChild} from '@angular/core';
import { StatsMyProgressComponent } from './stats-my-progress/stats-my-progress.component';

@Component({
  selector: 'home-stats',
  templateUrl: './home-stats.component.html',
  styleUrls: ['./home-stats.component.scss']
})
export class HomeStatsComponent implements OnInit {

  @ViewChild('statsMyProgress') statsMyProgress: StatsMyProgressComponent;
  

  constructor() {

  }

  ngOnInit(): void {

  }

  public updateStats(): void {
    this.statsMyProgress.load();
  }
}