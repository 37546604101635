import { NudgeDayTrackerComponent } from "./nudge-day-tracker.component";

export class NudgeDayTrackerStyling {
    constructor(component: NudgeDayTrackerComponent) {
        this.component = component;
    }

    private component: NudgeDayTrackerComponent;

    public getDotColour(statusId: number): Array<string> {
        if (statusId) {
            let classes = new Array();
        
            switch (statusId) {
                case 0:
                    classes.push("white-dot");
                break;
                case 1:
                    classes.push("grey-dot");
                break;
                case 2:
                    classes.push("green-dot");
                break;
                case 3:
                    classes.push("red-dot");
                break;
                case 4:
                    classes.push("amber-dot");
                break;
                case 5:
                    classes.push("blue-dot");
                break;
            }
        
            return classes;
        } else {
            return null;
        }
    }

    public getBorderColour(statusId: number): string {
        if (statusId) {
            switch (statusId) {
                case 0:
                    return "#f2f2f2";
                break;
                case 1:
                    return "#d9d9d9";
                break;
                case 2:
                    return "#689f76";
                break;
                case 3:
                    return "#cc114f";
                break;
                case 4:
                    return "#ff9169";;
                break;
                case 5:
                    return "#5373a2";
                break;
            }
        } else {
            return "#f2f2f2";
        }
    }
    
    public getTickClasses(userAction: any): Array<string> {
        if (userAction) {
            let classes = this.getDotColour(userAction.status_id);
    
            if (this.component.selectedDay.isSame(this.component.dayToProcess) && !this.component.dayisSnoozed)
                classes.push("today-tick");
    
            return classes;
        } else {
            return null;
        }
    }
    
      public getToday(index: number): any {
        if (index != null) {
            let classes = new Array();
            
            if (this.component.selectedIndex == index) {
                classes.push("today");
            }

            if (index < (this.component.selectedIndex - 6) || index > (this.component.selectedIndex + 7))
                classes.push("hideDay");
        
            return classes;
        } else {
            return null
        }
      }
}