import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { SettingsService } from './shared/services/settings.service';
import { SignalrService } from './shared/services/signalr.service';

@Component({
  // tslint:disable-next-line
  selector: 'app-root',
  template: '<router-outlet></router-outlet><ngx-ui-loader></ngx-ui-loader>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, 
      private msalBroadcastService: MsalBroadcastService,
      private signalRService: SignalrService) { 
        signalRService.initiateSignalrConnection().then(() => {
          signalRService.monitorExports();
          signalRService.monitorChat();
        });
    }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // this.msalBroadcastService.msalSubject$
    //   .pipe(
    //     filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //   )
    //   .subscribe((result: EventMessage) => {
    //     debugger;
    //   });
  }
}
