import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NudgeResponse } from '../../models/responses/nudge.response';
import { DashboardService } from '../../services/dashboard.service';
import { nudgePyramidSort } from '../../utils/nudge.util';

@Component({
  selector: 'app-nudge-chart',
  templateUrl: './nudge-chart.component.html',
  styleUrls: ['./nudge-chart.component.scss']
})
export class NudgeChartComponent implements OnInit, OnChanges {
  @Input() mode: 'min' | 'max' = 'min';
  @Input() selectedNudgeId: number = 0;
  @Input() userProgramId: number;
  public data: NudgeResponse[];
  @Input() showImage: boolean = false;
  @Output() onClickNudge = new EventEmitter<any>();
  @ViewChild('mainContainer', { static: true }) mainContainer: ElementRef<HTMLElement>;
  @Input() totalOverallProgress: number;
  @Output() onChartClick = new EventEmitter<any>();
  imgSrc: string = ''

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.data) {
      
    //   this.data = changes.data?.currentValue as NudgeResponse[] || [];
    //   if (changes.selectedNudgeId) {
    //     this.selectedNudgeId = changes.selectedNudgeId?.currentValue;
    //   }
    //   this.mapClassToNudges();
    //   this.createCustomStyle();
    //   this.findOverallValue();
    // }
    if (changes.totalOverallProgress && changes.totalOverallProgress.currentValue) {
      this.totalOverallProgress = changes.totalOverallProgress.currentValue;
      if (this.totalOverallProgress >= 66.6) {
        this.imgSrc = './assets/img/brain/150x150_brain-green-02.svg';
      } else if (this.totalOverallProgress >= 33.4 && this.totalOverallProgress < 66.5) {
        this.imgSrc = './assets/img/brain/150x150_brain-amber-02.svg';
      } else {
        this.imgSrc = './assets/img/brain/150x150_brain-red-02.svg';
      }
    }
  }

  public load(): void {
    this.dashboardService.getNudgeChartData(this.userProgramId).subscribe(result => {
      this.data = nudgePyramidSort(result);

      this.mapClassToNudges();
      this.createCustomStyle();
      this.findOverallValue();

      //This forces a new array so that the chart will display new values
      //this.POINTS = this.POINTS.slice(0);
    });
  }

  findOverallValue() {
    if (!this.totalOverallProgress) {
      var length = this.data.length;
      var sum = this.data.reduce((sum, item) => sum + item.overallCompletionRate, 0);
      this.totalOverallProgress = sum / length;
    }
    if (this.totalOverallProgress >= 66.6) {
      this.imgSrc = './assets/img/brain/150x150_brain-green-02.svg';
    } else if (this.totalOverallProgress >= 33.4 && this.totalOverallProgress < 66.5) {
      this.imgSrc = './assets/img/brain/150x150_brain-amber-02.svg';
    } else {
      this.imgSrc = './assets/img/brain/150x150_brain-red-02.svg';
    }
  }

  setSelectedNudge(nudge: NudgeResponse) {
    this.selectedNudgeId = nudge.user_action_id;
    this.onClickNudge.emit(nudge);
  }

  getIsSelected(id: number) {
    return this.selectedNudgeId === id;
  }

  getPointClass(id: number, percentage: number): string {
    if (percentage >= 66.6) {
      return 'point-green';
    } else if (percentage >= 33.4 && percentage < 66.5) {
      return 'point-orange';
    } else {
      return 'point-red';
    }
  }

  mapClassToNudges() {
    this.data.map(x => {
      x.styleClass = this.getPointClass(x.user_action_id, x.overallCompletionRate);
    });
  }

  normalAbsoluteAngleDegrees(angle: number) {
    return (angle %= 360) >= 0 ? angle : (angle + 360);
  }

  createCustomStyle() {
    //width: calc(62% + (-${x.overallCompletionRate}% / 2)) !important;
    let style: string = '';

    this.data.forEach((x, index) => {
      
      var textPosition = "";
      if (x.angle < 0 && x.angle > -90) {
        textPosition = "top: 100%";
      } else if (x.angle <= -90 && x.angle > -180) {
        textPosition = "top: 100%";
      } else if (x.angle <= -180 && x.angle > -270) {
        textPosition = "bottom: 110%";
      } else {
        textPosition = "bottom: 110%";
      }

      let newStyle: string =
        `.point-container-${x.user_action_id} {
          width: calc(53% - (${x.overallCompletionRate}% * 0.33)) !important;
          transform: rotate(${x.angle}deg);
          transition-delay: ${index + 1}00ms;
        }
        .point-${x.user_action_id} {
          transform: rotate(-${this.normalAbsoluteAngleDegrees(x.angle)}deg);
        }
        .text-${x.user_action_id} {
          ${textPosition};
        }`;
      style = style + newStyle;
    });
    this.addStyleToView(style);
  }

  addStyleToView(style: string): void {
    let styleElement = this.mainContainer.nativeElement.querySelector('style');
    if (!styleElement) {
      styleElement = document.createElement('style');
    }
    setTimeout(() => {
      styleElement.innerHTML = style;
      this.mainContainer.nativeElement.appendChild(styleElement);
      setTimeout(() => {
        this.mainContainer.nativeElement.classList.add(this.mode);
      }, 1700);
    }, 100);
  }

  clickBrain() {
    this.onChartClick.emit();
  }
}
