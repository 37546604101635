import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss']
})
export class ProgressChartComponent implements OnInit, OnChanges {
  @Input() percentage: number;
  @Input() showImage: boolean = false;
  @Input() responsive: boolean = true;
  @Input() radius: number = 50;
  @Input() imageUrl: string = './assets/img/brain_percentage.svg';
  @Input() isTeam: boolean;
  @Output() onChartClick = new EventEmitter<any>();
  outerStrokeColor: string = 'RGB(202,3,72)';
  title: Array<string>;
  subTitle: string;
  showSubtitle: boolean;
  imageHeight: number;
  imageWidth: number;
  constructor() { }

  ngOnInit(): void {
    if (this.isTeam) {
      this.title = [this.percentage.toString() + "%"];
      this.subTitle = "";
      this.showSubtitle = false;
      this.imageHeight = 60;
      this.imageWidth = 60;
    } else {
      this.title = ["", "", "", ""];
      this.subTitle = this.percentage.toString() + "%";
      this.showSubtitle = true;
      this.imageHeight = 60;
      this.imageWidth = 50;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.percentage && changes.percentage.currentValue) {
      this.percentage = Math.round(changes.percentage.currentValue);
      this.setStrokeColor();
    } else {
      this.percentage = 0;
      this.setStrokeColor();
    }

    if (this.isTeam) {
      this.title = [this.percentage.toString() + "%"];
    } else {
      this.subTitle = this.percentage.toString() + "%";
    }

    if (changes.imageUrl && changes.imageUrl.currentValue) {
      this.imageUrl = changes.imageUrl.currentValue;
    }
  }

  setStrokeColor() {
    if (this.percentage === 0) {
      this.outerStrokeColor = 'RGB(202,3,72)';
    } else if (this.percentage >= 66.6) {
      this.outerStrokeColor = 'RGB(104,159,118)';
    } else if (this.percentage >= 33.4 && this.percentage < 66.5) {
      this.outerStrokeColor = 'RGB(239,121,85)';
    } else {
      this.outerStrokeColor = 'RGB(202,3,72)';
    }
  }

  handleChartClick(e: any) {
    this.onChartClick.emit(e);
  }
}
