import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ApiResultDto } from '../../shared/models/responses/common/apiResultDto';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            var errObj = err.error as ApiResultDto<any>;

            if (err.status === 401) {
                this.authenticationService.logout();
            }
            if (err.status === 400) {
                this.toastr.error(errObj.errorMessage || "Oops!, Something went wrong. Please contact administrator.", "")
            }
            if (err.status === 500) {
                this.toastr.error(errObj.errorMessage)
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}