import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsDatepickerInlineDirective, BsDaterangepickerDirective, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { TopTipResponse } from '../../shared/models/responses/topltip.response';
import { DashboardService } from '../../shared/services/dashboard.service';
import { BsDatePickerUtils } from '../../shared/utils/bsDatepicker.util';
import * as moment from 'moment';
import { DateRangeResponse } from '../../shared/models/responses/daterange.response';
import { NudgeResponse } from '../../shared/models/responses/nudge.response';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Sprint } from '../../shared/models/responses/sprint.response';
import { RoleEnum } from '../../shared/enums/roles.enum';
import { getSprintLabel } from '../../shared/utils/sprint.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NudgeChartComponent } from '../../shared/components/nudge-chart/nudge-chart.component';
import { HomeStatsComponent } from '../../shared/components/home-stats/home-stats.component';
import { CoachDetails, CoachService } from '../../shared/services/coach.service';

@Component({
  selector: 'app-tab-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(BsDatepickerInlineDirective) datePicker: BsDatepickerInlineDirective;
  @ViewChild('dp', { static: false }) dp: BsDaterangepickerDirective;
  @ViewChild('nudgeChart') nudgeChart: NudgeChartComponent;
  @ViewChild('homeStats') homeStats: HomeStatsComponent;
  
  bsDatePickerUtils: BsDatePickerUtils;
  todayDate: Date = new Date();
  calenderStartDate: Date;
  calenderEndDate: Date;
  bsConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[] = [];
  TOPTIP: TopTipResponse;
  ENGAGEMENT_RATING: number = 0;
  UserName: string = '';
  GreetingText: string = '';
  nextTaskType: number = 0;
  currentSprint: Sprint;
  isManager: boolean = false;
  sprintLabel: string = "";
  bsModalRef: BsModalRef;
  showTopTips: boolean = false;
  showTrackerDesc: boolean = false;
  public showCoachChat: boolean = true;
  public sprintName: string = "";

  firstday = '';
  lastday = '';
  first = null;
  last = null;


  constructor(
    private router: Router, 
    private apiService: DashboardService, 
    private authService: AuthenticationService) {
    this.UserName = this.apiService.currentSession?.User_Full_Name?.split(' ')[0] || "";
    this.nextTaskType = this.authService.currentTaskValue?.task_type_id || 0;
    this.currentSprint = this.authService.currentSprintValue;
    this.sprintName = this.authService.currentSprintValue.program_name;
    
    this.isManager = this.apiService.currentSession.User_Role === RoleEnum.Manager;
    this.sprintLabel = getSprintLabel(this.currentSprint?.weekNo, this.currentSprint?.duration);

    if (this.authService.currentUserValue.Client_ID == 5) {
      this.showCoachChat = false;
    }
  }

  ngOnInit(): void {
      this.bsConfig = {
        showWeekNumbers: false,
        daysDisabled: [0, 6],
      }
      this.GreetingText = this.apiService.getGreetingText();
      this.bindDashboard();
  }

  ngAfterViewInit() {
    if (this.datePicker) {
      this.bsDatePickerUtils = new BsDatePickerUtils(this.datePicker);
      this.bsDatePickerUtils.viewChanged.subscribe(date => {
        this.calenderStartDate = moment(date).startOf('month').toDate();
        this.calenderEndDate = moment(date).endOf('month').toDate();
        this.callCalenderDataService();
      });

      this.mapDatepickerFromState();
    }
  }

  mapDatepickerFromState() {
    this.apiService.getCalenderValue.map((x) => {
      this.mapToDatePicker(x.results);
    });
  }

  bindDashboard(): void {
    this.apiService.getAssessments().subscribe(assesmentResponse => {
      this.ENGAGEMENT_RATING = assesmentResponse.avgEngagementRating;
    });
  }

  callCalenderDataService(): void {
    const strStartDate = moment(this.calenderStartDate).format("YYYY-MM-DD");
    const strEndDate = moment(this.calenderEndDate).format("YYYY-MM-DD");
    this.apiService.getCalendarData(strStartDate, strEndDate).subscribe((result) => {
      if (result.isNew && result.results.length > 0) {
        this.mapToDatePicker(result.results)
      }
    });
  }

  mapToDatePicker(results: DateRangeResponse[]) {
    results.map(x => {
      this.dateCustomClasses.push({
        date: new Date(x.diaryDate),
        classes: this.getDateBackgroundClass(x.overallCompletionRate)
      })
    });
    this.dp.dateCustomClasses = this.dateCustomClasses;
    this.dp.setConfig();
    const el = document.body.querySelector('.bs-datepicker-body table tbody');
    if (null !== el) {
      el.querySelectorAll('tr').item(2).querySelectorAll('td').item(1).querySelector('span').click();
    }
  }

  getDateBackgroundClass(percentage: number) {
    if (percentage >= 90) {
      return ['bg-success'];
    } else if (percentage >= 75 && percentage < 90) {
      return ['bg-warning'];
    } else {
      return ['bg-danger'];
    }
  }

  handleNudgeClick(nudge: NudgeResponse) {
    this.router.navigate(['progress', nudge.user_action_id]);
  }

  handleSetupClick() {
    this.router.navigate(['business-ambitions', 'create']);
  }

  topTipsClick() {
    this.showTopTips = true;
  }

  showTrackerDescription(): void {
    this.showTrackerDesc = true;
  } 

  hideTrackerDescription(): void {
    this.showTrackerDesc = false;
  } 

  public refreshTracker(): void {
    this.nudgeChart.load();
  }

  public updateStats(): void {
    if (!this.showCoachChat)
      this.homeStats.updateStats();
  }
  
  public getCoach(): number {
    return this.authService.currentSprintValue.coach_id;
  }
}
