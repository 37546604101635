export class NudgeDetail {
    user_action_id: number;
    program_goal_id: number;
    user_objective_guid: string;
    user_program_id: number;
    program_id: number;
    action_name: string;
    action_description: string;
    allow_qty: number;
    category_id: number;
    category_name: string;
    scheduled_time_id: number;
    is_active: boolean;
    d1_is_active: boolean;
    d2_is_active: boolean;
    d3_is_active: boolean;
    d4_is_active: boolean;
    d5_is_active: boolean;
    d6_is_active: boolean;
    d7_is_active: boolean;
    d1_start_time: string;
    d2_start_time: string;
    d3_start_time: string;
    d4_start_time: string;
    d5_start_time: string;
    d6_start_time: string;
    d7_start_time: string;
    d1_end_time: string;
    d2_end_time: string;
    d3_end_time: string;
    d4_end_time: string;
    d5_end_time: string;
    d6_end_time: string;
    d7_end_time: string;
}