import { Component,Input,OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { exampleJournal } from './example-journal';
import { IJournal } from './journal.interface';

@Component({
  selector: 'reflection-journal',
  templateUrl: './reflection-journal.component.html',
  styleUrls: ['./reflection-journal.component.scss']
})
export class ReflectionJournalComponent implements OnInit {

    @Input() data: IJournal;
    public tabIndex: number = 0;

    constructor(private domSanitizer: DomSanitizer) {
        
    }

    ngOnInit(): void {
        //this.data = exampleJournal;
        this.tabIndex = this.data.weeks.length - 1;
    }

    public getIcon(base64: string): any {
        return this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + base64);
    }

    public onTabSelect(e: SelectEvent): void {
        this.tabIndex = e.index;
      }

    public showLeft(): boolean {
        return this.tabIndex > 0;
    }

    public showRight(): boolean {
        if (this.data.weeks)
            return this.tabIndex != (this.data.weeks.length - 1);
        else
            return false;
    }

    public navigateLeft(): void {
        this.tabIndex = this.tabIndex - 1;
      }
    
      public navigateHome(): void {
        this.tabIndex = this.data.weeks.length - 1;
      }
    
      public navigateRight(): void {
        this.tabIndex = this.tabIndex + 1;
      }
}