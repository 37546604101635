import { ChangeDetectionStrategy, Component,ElementRef,OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { EverydayActions, ProcessService } from '../../../services/process.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'my-everyday-actions',
  templateUrl: './my-everyday-actions.component.html',
  styleUrls: ['./my-everyday-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyEverydayActionsComponent implements OnInit {

    public showAddNudgeDialog: boolean = false;
    public everyDayActions: Array<EverydayActions>;
    public offset: number = 0;
    @ViewChild('myEverydayActionsContainer') myEverydayActionsContainer: ElementRef;

    constructor(
        public utils: UtilitiesService,
        private processService: ProcessService,
        private authService:AuthenticationService,
        private router: Router) {

    }

    ngOnInit(): void {
        this.processService.getEveryDayActions(this.authService.currentUserValue.User_Program_ID).subscribe(results => {
            this.everyDayActions = results;
        });
    }

    public navigateToPlan(): void {
        this.router.navigate(['/personal'], {
            queryParams: {
              tabIndex: 1
            }});
    }

    public scroll(right: boolean): void {
        if (right)
            this.offset += 210;
        else
            this.offset -= 210;
    }

    public setDayPosition(index: number): number {
        return (index * 210) - this.offset;
    }

    public isRightArrowVisible(): boolean {
        if (this.myEverydayActionsContainer) {
            let containerWidth = this.myEverydayActionsContainer.nativeElement.offsetWidth;
            let widthOfRemainingElements = (this.everyDayActions.length * 210) - this.offset;
            return widthOfRemainingElements > containerWidth;
        } else 
            return true;
    }

    public openDialog(): void {
        this.showAddNudgeDialog = true;
    }

    public closeDialog(): void {
        this.showAddNudgeDialog = false;
    }
}