import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "./settings.service";

@Injectable({ providedIn: 'root' })
export class KeyMeasuresService {

  constructor(
    private http: HttpClient, 
    private settingsService: SettingsService) { }

  getKeyResult(kpiId: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("KPI_id", kpiId.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/KeyResult/GetKeyResultHistory`, {
      params: params
    });
  }

  updateKeyResult(keyResultHistory: SaveKeyResultHistory): any {
    return this.http.post(`${this.settingsService.settings.apiUrl}/KeyResult/SaveKeyResultHistory`, keyResultHistory);
  }
}

export class SaveKeyResultHistory {
  constructor(data: any) {
    Object.assign(this, data)   
  }

  KPI_history_id: number;
  KPI_id: number;
  KPI_date: Date;
  KPI_value: number;
  is_active: boolean;
}

