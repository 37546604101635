import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SprintNudgeResponse } from '../../shared/models/responses/sprint-nudge.response';
import { SetupService } from '../../shared/services/setup.service';

@Component({
  selector: 'app-tab-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {
  //nudgesData: SprintNudgeResponse[];
  selectedNudgeId: number;

  constructor(private setupService: SetupService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.selectedNudgeId = params["id"];
    });
    // this.getNudges();
  }

  // getNudges() {
  //   this.setupService.getNudges().subscribe(results => {
  //     this.nudgesData = results;
  //   });
  // }
}
