import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SettingsService } from '../../shared/services/settings.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
      private authenticationService: AuthenticationService,
      private settingsService: SettingsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        if (request.url.endsWith("settings.json") ||
            request.url.endsWith("RefreshExpiredAccessToken")) {
            return next.handle(request); 
        }

        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.User_Token;
        
        if (isLoggedIn) {
            const isApiUrl = request.url.startsWith(this.settingsService.settings.apiUrl);
            if (isApiUrl) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.User_Token}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}
