import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { ReportingService } from '../../../../shared/services/reporting.service';
import { Report } from '../../reports/report';
import { KeyMeasuresService, SaveKeyResultHistory } from '../../../../shared/services/key-measures.service';
import * as moment from 'moment';
import { CoachService } from '../../../../shared/services/coach.service';


@Component({
  selector: 'app-mydashboard',
  templateUrl: './mydashboard.component.html',
  styleUrls: ['./mydashboard.component.scss']
})
export class MyDasboardComponent implements OnInit {

    constructor(
        private reportingService: ReportingService,        
        private keyMeasuresService: KeyMeasuresService,
        private authService: AuthenticationService,
        private toastr: ToastrService) {

    }

    @Input() dashboardId: number;
    @Input() options: IOptions;
    @Input() showEllipsisMenu: boolean = false;
    selectedClientId: number;
    public originalDashboardId: number = 0;
    public drillDownTitle: string = "";

    public reports: Report[] = new Array();
    public dataLoaded: boolean = false;
    public showLoader: boolean = true;

    private program_id: number = 0;

    ngOnInit(): void {

    }

    ngDoCheck() {
        if (this.options.selectedClient != this.selectedClientId) {
            this.dataLoaded = false;
            this.selectedClientId = this.options.selectedClient;
        }

        if (this.options.isCurrent) {
            this.loadDashboard();
        }
    }

    loadDashboard(): void {
        if (!this.dataLoaded) {
            this.showLoader = true;
            this.dataLoaded = true;
            this.program_id = this.options.program_id;
            if (this.authService.currentSprintValue && (this.program_id == 0 || this.program_id == undefined))
            this.program_id = this.authService.currentSprintValue.program_id

            this.reportingService.getDashboard(this.program_id, this.dashboardId, 0, this.selectedClientId, this.options.coached_user_id).subscribe(result => {
                this.reports = new Array();
                result.forEach(report => {
                    this.reports.push(new Report(this.reportingService, report, true));
                });
                this.showLoader = false;
            });
        }
    }

    public exportReport(reportId: number, name: string): void {
        this.reportingService.exportFile(1, reportId, this.program_id, this.selectedClientId, name).subscribe(result => { 
            this.toastr.success('Your export has been queued for processing', '', {
                positionClass: 'toast-bottom-right', 
                timeOut: 10000
              });
        });
    }

    public drilldownClicked(dataItem: any): void {
        this.drillDownTitle = dataItem.title;
        this.originalDashboardId = this.dashboardId;
        this.dashboardId = dataItem.dashboardId;
        this.options.coached_user_id = dataItem.id;
        this.dataLoaded = false;
        this.loadDashboard();
    }

    public backToParentDash(): void {
        this.drillDownTitle = "";
        this.dashboardId = this.originalDashboardId;
        this.originalDashboardId = 0;
        this.options.coached_user_id = 0;
        this.dataLoaded = false;
        this.loadDashboard();
    }



    // Kendo-dialog Section

    showUpdateTrackerDialog = false;
    currentKeyResult: any;
    toggleDialogFunctionTrigger() {  
        if (this.showUpdateTrackerDialog === true) {            
            this.showUpdateTrackerDialog = !this.showUpdateTrackerDialog;
            return;
        }
        
        this.keyMeasuresService.getKeyResult(this.activeReport.report.KPI_id).subscribe(result => {
            this.currentKeyResult = result;
            this.showUpdateTrackerDialog = !this.showUpdateTrackerDialog;
        });
    }

    activeReport: any;
    setActiveResult(report: any) {
        this.activeReport = report;     
    }

    addSubtractValue: boolean = false;
    replaceValue: boolean = false;
    newKRResultValue: number;
    newKRTargetAchievedPc: number;
    dateOfNewKRResult: Date = new Date(2000, 2, 10);
    getCurrentKRTotal(): number {
        if (this.addSubtractValue && this.replaceValue) {
            this.newKRTargetAchievedPc = undefined;
            return;
        }

        if (this.addSubtractValue) {
            var newValue = (parseInt(this.currentKeyResult.current_total_value) + this.newKRResultValue);
            this.newKRTargetAchievedPc = Math.round((newValue / this.currentKeyResult.target_value) * 100);
            return newValue;
        }

        if (this.replaceValue) {
            this.newKRTargetAchievedPc = Math.round((this.newKRResultValue / this.currentKeyResult.target_value) * 100);
            return this.newKRResultValue;
        }

        this.newKRTargetAchievedPc = this.currentKeyResult.target_achieved_pc;
        return this.currentKeyResult.current_total_value;
    }

    updateKeyResult() {
        var keyResultHistory = new SaveKeyResultHistory({
            KPI_history_id: 0,
            KPI_id: this.currentKeyResult.kpI_id,
            KPI_date: this.dateOfNewKRResult,
            KPI_value: this.getCurrentKRTotal(),
            is_active: true
        });
        this.keyMeasuresService.updateKeyResult(keyResultHistory).subscribe();
    }

    checkCanUpdate() {
        return (!this.getCurrentKRTotal() || !this.currentKeyResult.target_achieved_pc);
    }
}

export interface IOptions {
    isCurrent: boolean;
    selectedClient: number;
    coached_user_id: number;
    program_id: number;
}
