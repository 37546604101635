import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ResourcesService, VideoUpload } from '../../services/resources.service';
import { ClientProgram, EmailTemplate, SetupService } from '../../services/setup.service';
import { Resource, ResourceGroup } from '../../services/process.service';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';


@Component({
  selector: 'app-create-nugget',
  templateUrl: './create-nugget.component.html',
  styleUrls: ['./create-nugget.component.css']
})
export class CreateNuggetComponent implements OnInit {
  @Output() dialogClosed = new EventEmitter<Resource>();
  @Input() nuggetToUpdate: Resource;

  newNuggetForm: FormGroup;
  isFormSubmitted: boolean = false;
  resourceGroups: Array<ResourceGroup> = [];
  programsForClient: Array<ClientProgram> = [];
  videoToUpload: VideoUpload;
  private newVideoUploaded: boolean = false;

  constructor(
      public fb: FormBuilder,
      private toastService: ToastrService,
      private resourcesService: ResourcesService,
      private setupService: SetupService, 
      private authenticationService: AuthenticationService
    ) {
    this.newNuggetForm = this.fb.group({
        nugget_name: ["", Validators.required],
        nugget_description: [""],
        resource_group: [Validators.required],
        transcript: [""],
        program: [],
    });
  }

  ngOnInit(): void {
    this.nuggetToUpdate.icon = this.nuggetToUpdate.icon.toString() == "" ? undefined : this.nuggetToUpdate.icon;
    this.resourcesService.getResourceGroups().subscribe(result => {
      this.resourceGroups = result;
    });
    this.setupService.getClientPrograms(this.authenticationService.currentUserValue.Client_ID).subscribe(result => {
      this.programsForClient = result;
    });
  }

  public closeNewNuggetDialog(newNugget: Resource = null) {
    this.newNuggetForm.reset();
    this.dialogClosed.emit(newNugget);
  }

  get formControls() {
    return this.newNuggetForm.controls;
  }

  saveNewNugget(event: any) {
    event.preventDefault();
    this.isFormSubmitted = true;

    if (this.newNuggetForm.valid) {      
      this.nuggetToUpdate.order_no = this.resourceGroups
        .find(x => x.resource_group_id == this.nuggetToUpdate.resource_group_id).order_no;

      if (this.newVideoUploaded) {
        this.resourcesService.uploadVideo(this.videoToUpload).subscribe(videoUploadResult => {
        
          this.nuggetToUpdate.blob_URL = videoUploadResult.result;
          this.saveNugget();
        });
      } else {
        this.saveNugget();
      }
    }
  }

  private saveNugget() {
    this.nuggetToUpdate.date_last_viewed = "";

    this.resourcesService.saveCoachingCard(this.nuggetToUpdate).subscribe((saveResult: any) => {
      if (saveResult.isSuccess) {
        this.toastService.success(`Success! Nugget ${this.nuggetToUpdate.nugget_id == null ? "created" : "updated"}.`);
        
        if (this.nuggetToUpdate.nugget_id == null)
          this.nuggetToUpdate.nugget_id = saveResult.result;

        this.newNuggetForm.reset();
        this.closeNewNuggetDialog(this.nuggetToUpdate);
      }
      else {
        this.toastService.error(`Save Failed: ${saveResult.errorMessage}`);
      }
    });
  }

  public setVideoToUpload(event: any) {
    this.newVideoUploaded = true;
    this.videoToUpload = event;
  }

  public selectedResourceGroupChange(event: any) {
    this.nuggetToUpdate.resource_group_id = undefined;
    this.nuggetToUpdate.resource_group_id = event.resource_group_id;
  }
}
