import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MotivationalNugget } from '../../../services/process.service';
import { ResourcesService } from '../../../services/resources.service';

@Component({
  selector: 'nugget-video',
  templateUrl: './nugget-video.component.html',
  styleUrls: ['./nugget-video.component.scss']
})
export class NuggetVideoComponent implements OnInit {

    @Input() nugget: MotivationalNugget;
    @ViewChild('video') videoPlayer: ElementRef;
    private videoTimeStamp: number = 0;

    constructor(private resourcesService: ResourcesService) { }

    public ngOnInit(): void {

    }

    // public updateVideoTime(event): void {
    //     this.videoTimeStamp = this.videoPlayer.nativeElement.currentTime;
    // }
    
    // public pauseVideo(): void {
    //     this.resourcesService.updateCoachingCardVideoProgress(this.nugget.nugget_id, this.videoTimeStamp).subscribe();
    // }
}