import { Component,EventEmitter,Input,OnInit, Output, SimpleChanges} from '@angular/core';
import { ResourcesService } from '../../services/resources.service';
import { ClientProgram, SetupService } from '../../services/setup.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'video-uploader',
  templateUrl: './video-uploader.component.html',
  styleUrls: ['./video-uploader.component.scss']
})
export class VideoUploaderComponent implements OnInit {
    @Output() videoChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() showIcon: boolean = true;
    @Input() existingVideo: any = null;

    public videoChangedEvent: any;
    public selectedVideo: any = null;
    private videoAsBytes: any;
    public showFileSizeError: boolean = false;
    public videoSelected: boolean = false;
    
    constructor() { }

    public ngOnInit(): void {      
      if (this.existingVideo == "")
        this.existingVideo = null;
      
      if (this.existingVideo != "" && this.existingVideo != null)
        document.querySelector("video").src = this.existingVideo;
    }

    public processFile(event: any) {
        this.videoSelected = false;
        var fileSize = event.target.files[0].size;
        if (fileSize > 500000000)
          this.showFileSizeError = true;
        else {
          this.selectedVideo = event.target.files.item(0);
          document.querySelector("video").src = URL.createObjectURL(event.target.files[0]);            
          this.videoChangedEvent = event;
          this.showFileSizeError = false;
        }
    }

    public selectVideo() {
      this.getByteArray(this.selectedVideo).then((byteArray) => {
        this.videoAsBytes = byteArray;

        let upload = {
          data: [].slice.call(this.videoAsBytes),
          filename: this.videoChangedEvent.target.files[0].name
        };
  
        this.videoChange.emit(upload);
        this.videoSelected = true;
      });
    }

    public cancelVideo() {
      this.selectedVideo = null;
      this.videoSelected = false;
      document.querySelector("video").src = null;
      this.videoChange.emit(null);
    }
    
    getByteArray(file) {
      let fileReader = new FileReader();

      return new Promise(function(resolve, reject) {
          fileReader.readAsArrayBuffer(file);
          fileReader.onload = function(ev: any) {
              const array = new Uint8Array(ev.target.result);
              const fileByteArray = [];
              for (let i = 0; i < array.length; i++) {
                  fileByteArray.push(array[i]);
              }
              resolve(array);
          }
          fileReader.onerror = reject;
      });
    }
}