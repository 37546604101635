import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Output, EventEmitter  } from '@angular/core';
import { BaseComponent } from '../../../views/base.view';
import { AuthenticationService } from '../../services/authentication.service';
import { DashboardService } from '../../services/dashboard.service';
import { SignalrService } from '../../services/signalr.service';
import { TeamService } from '../../services/team.service';
import * as moment from 'moment';
import { TaskAndSprintDetails } from '../../models/responses/login.response';
import { TrackerDay } from '../../models/responses/tracker-day.response';
import { FeedbackService } from '../../services/feedback.service';
import { NudgeDayTrackerStyling } from './nudge-day-tracker.styling';
import * as confetti from 'canvas-confetti';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavPreventerService } from '../../../core/guard/unsaved.changes.guard';
import { SetupService } from '../../services/setup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'nudge-day-tracker',
  templateUrl: './nudge-day-tracker.component.html',
  styleUrls: ['./nudge-day-tracker.component.scss']
})

export class NudgeDayTrackerComponent extends BaseComponent implements OnInit {

  constructor(
        private signalrService: SignalrService,
        private authenticationService: AuthenticationService,
        private dashboardService: DashboardService,
        private teamService: TeamService,
        private feedbackService: FeedbackService,
        private navPreventerService: NavPreventerService,
        private setupService: SetupService,
        private router: Router) {
      super(signalrService, authenticationService);

      this.navPreventerService.getRoutingLocks().subscribe(value => {
        this.requestedURLBeforeLock = value;
        if (value)
          this.showPreventLeavingMessage = true;
      });
  }

  @ViewChild('dayContainer') dayContainer: ElementRef;
  @ViewChild('confetti') confetti: ElementRef;
  @Output() finishButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() nudgeClicked: EventEmitter<any> = new EventEmitter();

  private taskAndSprint : TaskAndSprintDetails;
  public selectedIndex: number;

  private isDragging: boolean = false;
  private startX: number = 0;
  private endX: number = 0;
  private calculatedSelectedDayPositions: Array<number> = new Array();

  public get selectedDay(): moment.Moment {
    return moment(this.trackerDays[this.selectedIndex].diary_date).startOf("day");
  }

  public get dayToProcess(): moment.Moment {
    return moment(this.taskAndSprint.sprint.next_update_due).startOf("day");
  }

  public get currentTask(): number {
    return this.taskAndSprint.nextTask.task_type_id;
  }

  public get selectedDayInPast(): boolean {
    return this.selectedDay.isBefore(this.dayToProcess);
  }

  public get selectDayIsInRealTimeFuture(): boolean {
    return this.selectedDay.isAfter(moment());
  }

  public trackerDays: Array<TrackerDay>;
  public todaysNudges: Array<any>;
  public cellsToShow: number = 14;
  public selectedDayTitle: string;
  public sortBy: number = 1;
  public initFlag: boolean = true;
  public containerWidth: number = 45;
  public dotSize: number = 40;
  public marginSize: number = 2;
  public styling: NudgeDayTrackerStyling = new NudgeDayTrackerStyling(this);
  public finishDayLoading: boolean = false;
  public snoozeLoading: boolean = false;
  public unsnoozeLoading: boolean = false;
  public showFinishButton: boolean = true;
  public showSnoozeButton: boolean = true;
  public disableFinishButton: boolean = false;
  public disableSnoozeButton: boolean = false;
  public dayisSnoozed: boolean = false;
  public aNudgeHasBeenTicked: boolean = false;
  public allNudgesHaveBeenTicked: boolean = false;
  public showConfirmFinishDayDialog: boolean = false;
  public showFeedback: boolean = false;
  public showWeeklySurvey: boolean = false;
  public sortOptions: Array<any> = [{ text: "Sprint Goal" }, { text: "Time of Day" }]
  public selectedDayStatusId: number;
  public waterConsumed: number = 3;
  public originalWaterConsumed: number = 3;
  public weekNo: number;
  public isUpToDate: boolean = true;
  public fallenBehindUpdate: boolean = false;
  public fallenBehindSkip: boolean = false;
  public fallenBehindLeave: boolean = false;
  public showPreventLeavingMessage: boolean = false;
  public requestedURLBeforeLock: string = "";

  public ngOnInit(): void {
    super.setupSignalR().then(() => {
      this.signalrService.updateHasOccured.subscribe((update: boolean) => {
        if (update != null) {
          this.load().subscribe();
        }
      });
    });

    this.setupService.getFallenBehindStatus().subscribe(result => {
      this.isUpToDate = result;
    });
    
    this.load().subscribe();
  }

  private load(presetIndex: number = null): Observable<any> {
    return forkJoin([
      this.teamService.getSprint(this.userId()), 
      this.dashboardService.getTrackerDays()]).pipe(map(([sprintDetails, trackerDays]) => {
        this.taskAndSprint = sprintDetails;

        if (this.taskAndSprint.nextTask.task_type_id == 2)
          this.showFeedback = true;

        if (moment(this.taskAndSprint.sprint.next_update_due).isSameOrAfter(moment(this.taskAndSprint.sprint.next_survey_due)))
          this.showWeeklySurvey = true;
        else 
          this.showWeeklySurvey = false;

        this.trackerDays = trackerDays;

        let currentTaskDay = moment(this.taskAndSprint.sprint.next_update_due);
        let index = 0;
        this.trackerDays.forEach(trackerDay => {
          //Setup array with zero values for position, calculation later on
          this.calculatedSelectedDayPositions.push(0);

            let day = moment(trackerDay.diary_date);
            
            if (day.startOf("day").isSame(currentTaskDay.startOf("day"))) {
              if (presetIndex)
                this.selectedIndex = presetIndex;
              else
                this.selectedIndex = index;
                
              this.selectDay(this.selectedIndex);
            }
            index++;
        });
      }));
  }

  public ngAfterViewInit(): void {
      //this.setDragEvent();
  }

  public getDayLetter(date: string): string {
    return moment(date).format("ddd").substring(0, 1);
  }

  public getDayNumber(date: string): string {
    return moment(date).format("DD");
  }

  public selectDay(index: number): void {
    
    if (moment(this.trackerDays[index].diary_date).isSameOrAfter(moment(this.taskAndSprint.sprint.user_sprint_start_date)) &&
        moment(this.trackerDays[index].diary_date).isBefore(moment(this.taskAndSprint.sprint.user_sprint_end_date))) {
      this.selectedIndex = index;
      let diaryDate = this.trackerDays[index].diary_date;

      if (this.selectedDay.isBefore(this.dayToProcess)) {
        this.showFinishButton = false;
        this.showSnoozeButton = false;
      }

      if (this.selectedDay.isSame(this.dayToProcess)) {
        this.showFinishButton = true;
        this.showSnoozeButton = true;
      }

      if (this.selectedDay.isAfter(this.dayToProcess)) {
        this.showFinishButton = false;
        this.showSnoozeButton = true;
      }

      if (this.selectedDay.isAfter(moment())) {
        this.showFinishButton = false;
      }

      this.selectedDayStatusId = this.trackerDays[index].status_id;
      if (this.selectedDayStatusId == 5)
        this.dayisSnoozed = true;
      else
        this.dayisSnoozed = false;

      this.selectedDayTitle = this.getDayTitle(diaryDate);
      this.weekNo = this.getWeekNo(diaryDate);

      this.dashboardService.getTrackerNudges(moment(diaryDate).format("YYYY-MM-DD"), this.sortBy).subscribe(result => {
          this.todaysNudges = result;
          //Determine if any nudges have been ticked
          this.aNudgeHasBeenTicked = false;
          this.allNudgesHaveBeenTicked = true;

          this.todaysNudges.forEach(group => {
            group.user_actions.forEach(userAction => {
              if (userAction.status_id == 2) {
                this.aNudgeHasBeenTicked = true;
              } else {
                this.allNudgesHaveBeenTicked = false;
              }
            });
          });

          this.nudgeClicked.emit();
      });
    }
  }

  private getDayTitle(diaryDate: string): string {
    return moment(diaryDate).format("dddd DD MMMM");
  }

  private getWeekNo(diaryDate: string): number { 
    return moment(diaryDate).diff(moment(this.taskAndSprint.sprint.user_sprint_start_date), 'week') + 1;
  }

  public tickNudge(event: any, userAction: any): void {
    if (this.selectedDay.isSame(this.dayToProcess) && !this.dayisSnoozed) {
      userAction["loading"] = true;
      this.disableFinishButton = true;
      this.disableSnoozeButton = true;

      switch (userAction.status_id) {
        case 1:
          userAction.status_id = 2;
          this.rainConfetti(event);
          break;
        case 2:
            userAction.status_id = 1;
            break;
      }

      let params = {
        user_diary_id: userAction.user_diary_id,
        isStatus: userAction.status_id == 2,
        diary_date: this.trackerDays[this.selectedIndex].diary_date,
        user_action_id: userAction.user_action_id
      }
      this.feedbackService.updateNudgeStatus(params).subscribe(() => {
        this.load().subscribe(() => {
          userAction.loading = true;
          this.disableFinishButton = false;
          this.disableSnoozeButton = false;
          this.signalrService.updateOtherClients();
        });
      });
    }
  }

  private rainConfetti(event: any): void {
    this.confetti.nativeElement.setAttribute("style", "display: block;");
    let confettiAnimation = confetti.create(this.confetti.nativeElement)({
      shapes: ['square'],
      spread: 70,
      particleCount: 70,
      startVelocity: 20,
      scalar: 0.2,
      angle: 90,
      origin: {
        x: 0.5,
        y: 0
      }
    });

    setTimeout(() => {
      this.confetti.nativeElement.setAttribute('style', 'display: none;');
      confettiAnimation.reset();
    }, 3000);
  }

  private schoolPrideConfetti(): void {
    //this.confetti.nativeElement.setAttribute("style", "display: block;");

    let colors = ['#bb0000', '#ffffff'];
    confetti.create(this.confetti.nativeElement)({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors
    });

    confetti.create(this.confetti.nativeElement)({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors
    });

    // setTimeout(() => {
    //   this.confetti.nativeElement.setAttribute('style', 'display: none;');
    //   confettiAnimation.reset();
    // }, 3000);
  }

  public checkFinishMyDay(): void {
    if (!this.allNudgesHaveBeenTicked)
      this.showConfirmFinishDayDialog = true;
    else
      this.finishDay();
  }

  public finishDay(): void {
    this.showConfirmFinishDayDialog = false;
    this.finishDayLoading = true;
    var userActions = new Array();
    this.todaysNudges.forEach(value => {
      userActions = userActions.concat(value.user_actions);
    });

    var list = userActions.map(x => {
      return {
        user_diary_id: x.user_diary_id,
        isStatus: x.status_id === 2,
        diary_date: this.trackerDays[this.selectedIndex].diary_date,
        user_action_id: x.user_action_id
      }
    });

    this.feedbackService.finishMyDay(list).subscribe(result => {
      this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
      this.load().subscribe(() => {
        this.signalrService.updateOtherClients();
        this.finishButtonClicked.emit();
        this.finishDayLoading = false;
      });
    });
  }

  public closeDialog(name: string): void {
    switch(name) {
      case "finishDay":
        this.showConfirmFinishDayDialog = false;
      break;
      case "feedback":
        this.showFeedback = false;
        this.showWeeklySurvey = false;
      break;
    }
  }

  public snoozeChange(event: boolean) {
      this.dayisSnoozed = event;
      this.selectedDayStatusId = this.trackerDays[this.selectedIndex].status_id;
      if (this.selectedDayStatusId == 5 && !this.dayisSnoozed)
        this.navPreventerService.setRoutingLock("daySnoozed");
      else if (this.selectedDayStatusId != 5 && this.dayisSnoozed)
        this.navPreventerService.setRoutingLock("daySnoozed");
      else 
        this.navPreventerService.removeRoutingLock("daySnoozed");
  }

  public snoozeDay(): void {
    this.snoozeLoading = true;
    this.feedbackService.updateIamOnHoliday(this.trackerDays[this.selectedIndex].diary_date).subscribe(result => {
      this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
      this.signalrService.updateOtherClients();
      this.load().subscribe(() => {
        this.snoozeLoading = false;
        this.dayisSnoozed = false;
        this.navPreventerService.removeRoutingLock("daySnoozed");
      });
    });
  }

  public unsnoozeDay(): void {
    this.unsnoozeLoading = true;
    this.feedbackService.Unsnooze(this.trackerDays[this.selectedIndex].diary_date).subscribe(result => {
      this.signalrService.updateOtherClients();
      this.load().subscribe(() => {
        this.unsnoozeLoading = false;
        this.navPreventerService.removeRoutingLock("daySnoozed");
      });
    });
  }

  public goToToday() {
    let index = 0;
    this.trackerDays.forEach(day => {
      if (moment(day.diary_date).startOf("day").isSame(this.dayToProcess)) {
        this.selectDay(index);
      }
      index++;
    });
  }

  public setDayPosition(index: number): string {
      let containerWidth = this.dayContainer.nativeElement.offsetWidth;
      let arrowPosition = containerWidth / 2;
      let cellWidth = this.containerWidth;
      let offsetWith = cellWidth * this.selectedIndex;

      return (arrowPosition - (cellWidth/2) + index * cellWidth - offsetWith - 25).toString();
  }

  // public setDragEvent(): void {
  //     this.dayContainer.nativeElement.addEventListener('mousedown', event => {
  //       // this.hasDragged = true;
  //       this.isDragging = true;
  //       this.startX = event.pageX;
  //       this.endX = event.pageX;
  //   });

  //   this.dayContainer.nativeElement.addEventListener('mouseup', event => {
  //     this.isDragging = false;

  //     let moveValue = this.startX - this.endX

  //     if (this.startX > this.endX) {
  //       if (moveValue < 100)
  //         this.selectedIndex++;
  //       else if (moveValue < 200)
  //         this.selectedIndex = this.selectedIndex + 2;
  //       else
  //         this.selectedIndex = this.selectedIndex + 3;
  //     }
  //     else {
  //       if (moveValue < 100)
  //         this.selectedIndex--;
  //       else if (moveValue < 200)
  //         this.selectedIndex = this.selectedIndex - 2;
  //       else
  //         this.selectedIndex = this.selectedIndex - 3;
  //     }

  //     this.startX = 0;
  //     this.endX = 0;
  //   });

  //   this.dayContainer.nativeElement.addEventListener('mousemove', event => {
  //     if (this.isDragging) {
  //       this.endX = event.pageX;
  //     }
  //   });
  // }

  public onSortItemClick(item: any): void {
    if (item.text == "Sprint Goal") {
      this.sortBy = 0;
    } else if (item.text == "Time of Day") {
      this.sortBy = 1;
    }
    this.load(this.selectedIndex).subscribe();
  }

  public displayWeeklySurvey(): void {
    this.showFeedback = true;
    this.showWeeklySurvey = false;
  }

  public waterOnHover(index: number): void {
    let adjustedIndex = index + 1;
    this.originalWaterConsumed = this.waterConsumed;
    this.waterConsumed = adjustedIndex;
  }

  public waterOnBlur(): void {
    this.waterConsumed = this.originalWaterConsumed;
  }

  public getWaterIcon(index: number): string {
    let adjustedIndex = index + 1;
    if (adjustedIndex <= this.waterConsumed) {
      return "../../../../assets/img/icons/glass-light-blue.svg";
    } else {
      return "../../../../assets/img/icons/glass-light-grey.svg";
    }
  }

  public selectWaterConsumed(index: number): void {
    let adjustedIndex = index + 1;
    this.waterConsumed = adjustedIndex;
    this.originalWaterConsumed = adjustedIndex;
    //Make API call
  }

  updateChanged(event): void {
    if (event.target.checked)
    {
      this.fallenBehindUpdate = true;
      this.fallenBehindSkip = false;
      this.fallenBehindLeave = false;
    }
  }

  skipChanged(event): void {
    if (event.target.checked)
    {
      this.fallenBehindUpdate = false;
      this.fallenBehindSkip = true;
      this.fallenBehindLeave = false;
    }
  }

  leaveChanged(event): void {
    if (event.target.checked)
    {
      this.fallenBehindUpdate = false;
      this.fallenBehindSkip = false;
      this.fallenBehindLeave = true;
    }
  }

  fallenBehindDecision(): void {
    let userId = this.authenticationService.currentUserValue.User_ID;

    if (this.fallenBehindUpdate) {
      this.isUpToDate = true;
      this.setupService.updateFallenBehindDate(userId).subscribe(result => {
        //this.router.navigate(['/widget'], { relativeTo: this.activeRoute });
      });
    } 
    else if (this.fallenBehindSkip) {
      this.setupService.updateFallenBehind(userId, this.authenticationService.currentSprintValue.user_program_id, false)
        .subscribe(result => {
          this.setupService.updateFallenBehindDate(userId).subscribe(result => {
            location.reload();
          });
        });
    } else if (this.fallenBehindLeave) {
      this.setupService.updateFallenBehind(userId, this.authenticationService.currentSprintValue.user_program_id, true)
        .subscribe(result => {
          this.setupService.updateFallenBehindDate(userId).subscribe(result => {
            location.reload();
          });
        });
    }
  }

  public closePreventLeaveDialog(): void {
    this.showPreventLeavingMessage = false;
  }

  public clearLocksAndNavigate(): void {
    this.navPreventerService.removeAllRoutingLocks();
    this.router.navigate([this.requestedURLBeforeLock]);
  }

  public navigateToPlan(): void {
    this.router.navigate(["/personal"], { queryParams: { tabIndex: 1 } });
  }
}
