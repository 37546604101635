import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessAmbition } from '../../../models/responses/businessAmbition.response';
import { SetupService } from '../../../services/setup.service';
import { ComboResponse } from "../../../models/responses/combos.response";
import { BusinessAmbitionRequest } from '../../../models/requests/business-ambition.request';
import { SessionModel } from '../../../models/session.model';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-add-business-ambition',
  templateUrl: './add-business-ambition.component.html',
  styleUrls: ['./add-business-ambition.component.css']
})
export class AddBusinessAmbitionComponent implements OnInit, OnChanges {
  @Input() data: BusinessAmbition;
  businessAmbitionForm: FormGroup;
  @Input() mode: "ADD" | "EDIT" | "VIEW" = "ADD";
  @Output() onClose: EventEmitter<number> = new EventEmitter();
  isFormDisabled: boolean = false;
  coaches: ComboResponse[] = []
  submitted: boolean = false;
  currentUser: SessionModel;

  constructor(private fb: FormBuilder, private setupService: SetupService, private authService: AuthenticationService) {
    this.currentUser = this.authService.currentUserValue;
    this.getCoachList();
    this.businessAmbitionForm = this.fb.group({
      project_id: [0],
      project_name: ['', Validators.required],
      project_details: [''],
      coach: [null, Validators.required],
      owner: [null]
    })
  }

  ngOnInit(): void {

  }
  get formControls() {
    return this.businessAmbitionForm.controls;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue as BusinessAmbition;
      changes.data.currentValue = null;
    }
    if (changes.mode && changes.mode.currentValue) {
      this.mode = changes.mode.currentValue;
      this.isFormDisabled = this.mode === "VIEW";
    }
    this.setFormControls();
  }

  setFormControls() {
    this.isFormDisabled
      ? this.businessAmbitionForm.disable()
      : this.businessAmbitionForm.enable();
    this.data &&
      this.businessAmbitionForm.patchValue({
        project_id: this.data.project_id,
        project_name: this.data.project_name,
        project_details: this.data.project_details,
        coach: this.data.coach_id,
        owner: this.data.owner
      });
  }

  cancelClick() {
    this.onClose.emit(-1);
  }

  getCoachList() {
    this.setupService.getBusinessAmbitionsCoachList().subscribe((results) => {
      this.coaches = results;
    });
  }

  getRequestParams() {
    let obj = new BusinessAmbitionRequest();
    obj.project_id = this.businessAmbitionForm.value.project_id
    obj.project_name = this.businessAmbitionForm.value.project_name;
    obj.project_details = this.businessAmbitionForm.value.project_details;
    obj.coach_user_id = this.businessAmbitionForm.value.coach;
    obj.is_active = true;
    obj.client_id = this.currentUser.Client_ID;
    obj.owner_user_id = this.currentUser.User_ID;
    return obj;
  }

  saveBusinessAmbitionClick(e: Event) {
    e.preventDefault();
    this.submitted = true;
    if (this.businessAmbitionForm.valid) {
      let params = this.getRequestParams();
      this.setupService.saveAndUpdateBusinessAmbition(params).subscribe(results => {
        if (results !== 0) {
          this.businessAmbitionForm.reset();
          this.onClose.emit(results);
          this.submitted = false;
        }
      });
    }
  }
}
