import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { ReportingService } from '../../../shared/services/reporting.service';
import { UserService } from '../../../shared/services/user.service';
import { Report } from '../reports/report';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

    constructor(
      private CFR: ComponentFactoryResolver, 
      private router: Router) {

    }

    @Input() report: Report;
    @Input() coachedUserId: number;
    @Input() height: string = null;
    @Output() onDrilldownClick = new EventEmitter<any>();
    @ViewChild("viewContainerRef", { read: ViewContainerRef }) VCR: ViewContainerRef;

    public drilldownTitle: string;

    ngOnInit(): void {
      
    }

    public onSelect(event): void {
        let componentFactory = this.CFR.resolveComponentFactory(ChartComponent);
        let childComponentRef = this.VCR.createComponent(componentFactory);

        let childComponent = childComponentRef.instance;
        // childComponent.unique_key = ++this.child_unique_key;
        // childComponent.parentRef = this;



        // add reference for newly created component
        //this.componentsReferences.push(childComponentRef);
    }

    remove() {
      // if (this.VCR.length < 1) return;
  
      // let componentRef = this.componentsReferences.filter(
      //   x => x.instance.unique_key == key
      // )[0];
  
      // let vcrIndex: number = this.VCR.indexOf(componentRef as any);
  
      // removing component from container
      this.drilldownTitle = "";
      this.VCR.remove(0);
  
      // removing component from the list
      // this.componentsReferences = this.componentsReferences.filter(
      //   x => x.instance.unique_key !== key
      // );
    }

    public getSafeURL(URL: string): any {
      return 'https://localhost:44334/' + URL;
      // this.userService.getUserProfilePic(114).subscribe(result => {
      //   let a = this.createImageFromBlob(result);
      //   //debugger;
      //   return a;
      // });
    }

    private createImageFromBlob(image: Blob): any {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        return reader.result;
      }, false);
  
      if (image) {
        reader.readAsDataURL(image);
      }
    }

    public drillDown(dataItem: any): void {
      if (dataItem.dashboardId) {
        this.onDrilldownClick.emit(dataItem);
      }
    }
}
