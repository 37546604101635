import * as moment from "moment";
import { Sprint } from "../models/responses/sprint.response";

const getcalculatedSprint = (sprint: Sprint) => {
  if (!sprint) return null;
  var startDate: string, endDate: string, currentWeek: number, totalWeeks: number;
  startDate = moment(sprint.user_sprint_start_date).format("DD/MM/YYYY");
  endDate = moment(sprint.user_sprint_end_date).format("DD/MM/YYYY");
  totalWeeks = Math.ceil(moment.duration(moment(sprint.user_sprint_end_date).diff(sprint.user_sprint_start_date)).asWeeks());
  // currentWeek = Math.ceil(moment.duration(moment().diff(sprint.user_sprint_start_date)).asWeeks());
  currentWeek = sprint.weekNo;
  return { startDate, endDate, currentWeek, totalWeeks }
}

const getSprintLabel = (weekNo: number, duration: number) => {
  if (weekNo && duration) {
    if (weekNo === 1) {
      return "First Week";
    } else if (weekNo === duration) {
      return "Final Week";
    } else if (weekNo > duration) {
      return "Sprint Complete";
    } else {
      return `Week ${weekNo} of ${duration}`;
    }
  }
  return "";
}

export { getcalculatedSprint, getSprintLabel };
