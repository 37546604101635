import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { INavData } from '@coreui/angular';
import { debug } from 'console';
import * as moment from 'moment';
import { NgxRolesService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { filter, take } from 'rxjs/operators';
import { RoleEnum } from '../../shared/enums/roles.enum';
import { MainMenu } from '../../shared/models/responses/main-menu-response';
import { Sprint } from '../../shared/models/responses/sprint.response';
import { SessionModel } from '../../shared/models/session.model';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ProcessService } from '../../shared/services/process.service';
import { Company, SetupService } from '../../shared/services/setup.service';
import { SignalrService } from '../../shared/services/signalr.service';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ["./default-layout.component.css"]
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarHide = true;
  public mainMenus: Array<MainMenuItem> = new Array();
  current: SessionModel;
  userProfilePic: string = "/assets/img/icons/50x50_NoPersonPhoto.svg";
  currentSprint: Sprint;
  calculatedSprint: { startDate: string; endDate: string; currentWeek: number; totalWeeks: number; };
  firstday = '';
  lastday = '';
  first = null;
  last = null;
  showSupportPopup: boolean = false;
  showChatPopup: boolean = false;
  public showClientLayout: boolean = false;
  public showSprintName = false;
  public selectedClient: Company;
  public selectedSprintName: string = "";
  showDownloadManager: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private setupService: SetupService,
    private router: Router, 
    private processService: ProcessService,
    public signalrService: SignalrService,
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer
  ) {
    this.authenticationService.sidebarHideChange.subscribe(x => this.sidebarHide = x);

    // this.authenticationService.showClientLayoutChange.subscribe(clientId => {
    //   this.getClientDetails(clientId);
    // });

    this.authenticationService.currentUser.subscribe(x => {
      this.current = x;
      if (this.current.User_Profile_Pic) {
        this.userProfilePic = 'data:image/png;base64,' + this.current.User_Profile_Pic;
      }
    });

    this.setSprintTitle(this.authenticationService.currentSprintValue);
    this.authenticationService.currentSprint.subscribe(x => this.setSprintTitle(x));
    this.calculatedSprint = this.processService.getCalculatedSprint || null;
    this.currentSprint = this.authenticationService.currentSprintValue;
    this.getWeekDates();
    this.calculateSelectedNavItem();

    this.router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        
        this.currentlySelected = event.url.split(';')[0];

        this.selectedSprintName = "";
        if (this.currentlySelected.startsWith("/config")) {

          let params = event.url.split('?')[1];
          if (params != undefined) {
            let paramArray = params.split('&');
            paramArray.forEach(param => {
              let keyValue = param.split("=");
              switch (keyValue[0]) {
                case "client_id":
                  let clientId = parseInt(keyValue[1]);
                  if (clientId) {
                    this.showClientLayout = true;
                    this.getClientDetails(clientId);
                  }
                  else
                  {
                    this.showClientLayout = false;
                  }
                break;
                case "sprint_id":
                  let sprintId = parseInt(keyValue[1]);
                  if (sprintId) {
                    this.showSprintName = true;
                    this.getSprintName(sprintId);
                  }
                  else
                  {
                    this.showSprintName = false;
                  }
                break;
              }
            });
        }
          
        }
        else
        {
          this.showClientLayout = false;
          this.showSprintName = false;
        }
      }
    });

    this.signalrService.exportHasOccured.subscribe((type: number) => {
      if (type) {
        this.toastr.info('Your export is ready', '', {
          positionClass: 'toast-bottom-right', 
          timeOut: 10000
        }).onTap
          .pipe(take(1))
          .subscribe(() => this.exportToastClicked());
        }
    });
  }

  private getClientDetails(clientId: number): void {
    if (clientId) {
      this.setupService.getClient(clientId).subscribe(client => {
        this.selectedClient = client;
        this.showClientLayout = client != null;
      });
    } else {
      this.selectedClient = null;
      this.showClientLayout = false;
    }
  }

  private getSprintName(sprintId): void {
    this.setupService.getSprintById(sprintId).subscribe(sprint => {
      this.selectedSprintName = "SPRINT: " + sprint.sprintDetail.sprint_name.toUpperCase();
    });
  }
  
  public _opened: boolean = true;
  private currentlySelected = "dashboard";
  public sprintTitle: string;
 
  ngOnInit(): void {
    this.loadMainMenus();
    this.signalrService.updateMessagesStatuses();
  }

  public displayNotificationBadge(menuId: number) {
    if (menuId == 1 && this.signalrService.hasUnreadChatsFromCoach) {
      return true;
    }
    if (menuId == 3 && this.signalrService.hasUnreadChatsFromCoachees) {
      return true;
    }
    return false;
  }

  private loadMainMenus(): void {
    this.setupService.GetMainMenus().subscribe(result => {
      result.forEach(mainMenu => {
        this.mainMenus.push(new MainMenuItem(mainMenu, this.domSanitizer));
      });
    });
  }

  private setSprintTitle(sprint: Sprint) {
    if (sprint) {
      var sprintEndDate = moment(moment(sprint.user_sprint_end_date, "DD/MM/YYYY").format("YYYY-MM-DD"));
      var currentDayInProcess = moment(moment(sprint.next_update_due).format("YYYY-MM-DD"));

      if (sprint == null)
        this.sprintTitle = "";
      else if (sprintEndDate.isBefore(currentDayInProcess))
        this.sprintTitle = "sprint finished";
      else
        this.sprintTitle = "sprint week " + sprint.weekNo;
    }
  }

  public _toggleSidebar() {
    this._opened = !this._opened;
  }

  navigateTo(location: string, menu_id: number): void {
    if (menu_id == 10) {
      this.toggleShowSupportPopup();
    } else {
      this.router.navigate(["/" + location]).finally(() => {
        this.calculateSelectedNavItem();
      });
    }
  }

  public navigateToSprintSetup(): void {
    this.router.navigate(['/config/sprint-selector'], { queryParams: { client_id: this.selectedClient.client_id } });
  }

  public navigateToClientSetup(): void {
    this.router.navigate(['/config/company-setup'], { queryParams: { client_id: this.selectedClient.client_id } });
  }

  isSelected(location: string, menuItemId: number): string {
    if (menuItemId == 10)
      return "notFound";
    else if (menuItemId == 1 && (this.currentlySelected.startsWith("/home") || this.currentlySelected.startsWith("/personal")))
      return "listItemSelected";
    else if (this.currentlySelected.startsWith(location)) { 
      return "listItemSelected";
    }
    else return "notFound";
  }

  isIconSelected(location: string, mainIcon: boolean) {
    if (location === this.currentlySelected &&
        mainIcon)
      return "hideNavIcon";
    else if (location !== this.currentlySelected &&
      !mainIcon)
      return "hideNavIcon";
    else return "";
  }

  logout(): void  {
    this.authenticationService.logout();
  }

  getWeekDates() {
    if (this.currentSprint) {
      var curr = moment(this.currentSprint.next_update_due);
      this.first = curr.get('date') - curr.get('day') + 1;
      this.last = this.first + 1;
      this.firstday = curr.startOf('isoWeeks').format('YYYY-MM-DD');
      this.lastday = curr.endOf('isoWeeks').format('YYYY-MM-DD');
    }
  }

  calculateSelectedNavItem() {
    this.currentlySelected = this.router.url;
  }

  toggleShowSupportPopup() {
    this.showSupportPopup = !this.showSupportPopup;
  }

  public supportRequestSaved(): void {
    this.showSupportPopup = false;
  }

  private exportToastClicked() {
    this.navigateTo("/reporting", 0);
  }

  // public closeClientLayout(): void {
  //   this.router.navigate(["/config"]);
  //   this.authenticationService.showhideClientLayout(null);
  // }

  public getClientLogo(): string {
    if (this.selectedClient && this.selectedClient.logo)
      return "data:image/png;base64," + this.selectedClient.logo;
    else 
      return "../../../assets/img/icons/building-thin-blue.svg";
  }

  toggleDownloadManager() {
    //this.signalrService.exportsChecked();
    this.showDownloadManager = true;
  }

  public getIconImage(icon: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl("data:image/svg+xml;base64," + icon);
  }

  toggleShowChatPopup() {
    this.showChatPopup = !this.showChatPopup;
  }

  public getCoach(): number {
    return this.authenticationService.currentSprintValue.coach_id;
  }

  public getIsBigAdmin(): boolean {
    return this.authenticationService.isBigAdmin();
  }

  public isDevMode(): boolean {
    return isDevMode();
  }

  public chatIconString: string = "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMwMDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDouNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im0yMCwxNy4xMXYyLjUzcy0xLjk2LTIuNTMtMS45Ni0yLjUzaC03LjI2Yy0uMzEuMDEtLjU2LS4yMy0uNTYtLjU1di03LjY4Yy0uMDEtLjMuMjMtLjU2LjU1LS41NmwxMS40Ni0uMDJjLjMxLDAsLjU2LjI2LjU2LjU2djcuNjhjLjAxLjMxLS4yMy41Ni0uNTUuNTZoLTIuMjRaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJtMTQuODUsOC4zMXYtMi4zM2MwLS4zLS4yNS0uNTYtLjU3LS41NmwtMTEuNDYuMDJjLS4zMSwwLS41Ni4yNi0uNTYuNTZ2Ny42OGMuMDEuMzEuMjYuNTYuNTcuNTZoMi4yNHMwLDIuNTMsMCwyLjUzbDEuOTYtMi41NGgzLjE3Ii8+PC9zdmc+"

  public menuItemHover(item: MainMenuItem): void {
    item.isHovering = true;
  }

  public menuItemBlur(item: MainMenuItem): void {
    item.isHovering = false;
  }
}

export interface INavItem {
  name?: string;
  url?: string | any[];
  icon?: string;
  altIcon?: string;
}

export class MainMenuItem implements MainMenu {
  menu_id: number;
  menu_name: string;
  icon: string;
  is_multi_client: boolean;
  icon_URL: string;
  icon_hover_URL: string;
  public isHovering: boolean = false;

  constructor(mainMenu: MainMenu, private domSanitizer: DomSanitizer) {
    Object.assign(this, mainMenu);
  }

  public getUrl(): string {
    switch(this.menu_id) {
      case 1:
        return "/home";
      case 2:
        return "/config/manage-sprint";
      case 3:
        return "/coach";
      case 4:
        return "/config/manage-company";
      case 5:
        return "/config/manage-clients";
      case 6:
        return "/nuggets";
      case 7:
        return "/config/manage-company";
      case 8:
        return "/config/manage-sprint";
    }

    return "";
    //throw Error("No menu item found for id " + this.menu_id)
  }

  public getIconImage(): SafeUrl {
    //return this.domSanitizer.bypassSecurityTrustUrl("data:image/svg+xml;base64," + this.icon);
    if (this.isHovering)
      return this.domSanitizer.bypassSecurityTrustUrl(this.icon_hover_URL);
    else
      return this.domSanitizer.bypassSecurityTrustUrl(this.icon_URL);
  }
}