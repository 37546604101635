import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReportingComponent } from './reporting.component';
import { ReportingRoutingModule } from './reporting-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ExportsComponent } from './exports/exports.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ViewsModule } from '../views.module';
import { DialogModule } from '@progress/kendo-angular-dialog';


@NgModule({
  declarations: [ReportingComponent, ExportsComponent],
    imports: [
      FormsModule,
      CommonModule,
      ReportingRoutingModule,
      TabsModule.forRoot(),
      ToastrModule.forRoot(),
      NgxChartsModule,
      ViewsModule,
      DialogModule,
    ],
  exports: [
    ExportsComponent
  ]
})
export class ReportingModule { }
