import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
    public getBase64ImgString(icon: string, defaultIcon: string, type: string): string {
        if (icon)
            return "data:image/" + type + ";base64," + icon;
        else
            return defaultIcon;
    }
}