import { Component,Input,OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'key-results',
  templateUrl: './key-results.component.html',
  styleUrls: ['./key-results.component.scss']
})
export class KeyResultsComponent implements OnInit {

    @Input() keyResult: KeyResult;

    public chartSeries = [
        {
            data: [],
            type: "line",
            color: "#767171",
            style: "smooth",
            markers: { 
                visible: false 
            },
            width: 3
        },
        {
            data: [],
            type: "area",
            color: "#D9D9D9",
            line: {
                style: "smooth"
            }
        }
    ]

    public gridLines = {
        visible: false
    }

    public categoryAxis = {
        visible: false,
        majorGridLines: this.gridLines,
        minorGridLines: this.gridLines
    }

    public valueAxis = {
        visible: false,
        majorGridLines: this.gridLines,
        minorGridLines: this.gridLines
    }

    

    constructor() {
        
    }

    ngOnInit(): void {
        this.chartSeries[0].data = this.keyResult.data;
        this.chartSeries[0].color = this.keyResult.highlightColour;
        this.chartSeries[1].data = this.keyResult.data;
        this.chartSeries[1].color = this.keyResult.colour;
        this.keyResult.from = moment(this.keyResult.from);
        this.keyResult.to = moment(this.keyResult.to);
    }
}

export class KeyResult {
    public title: string;
    public value: string;
    public changeDirection: string;
    public changeValue: string;
    public changePercentage: number;
    public from: moment.Moment;
    public to: moment.Moment;
    public footer: string;
    public colour: string;
    public highlightColour: string;
    public data: Array<number>;
}