import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { ComponentsModule } from '../../shared/components/components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SetupComponent } from './setup/setup.component';



@NgModule({
  declarations: [SetupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccountRoutingModule,
    ComponentsModule,
    NgSelectModule
  ]
})
export class AccountModule { }
