import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { CanActivateAuthChild } from './core/guard/auth.canActivateChild';
import { AuthGuard } from './core/guard/auth.guard';
import { NavPreventerService } from './core/guard/unsaved.changes.guard';
import { UserValidatedGuard } from './core/guard/user.canActivate';
import { UserResolver } from './core/guard/user.resolver';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { ToursComponent } from './views/tours/tours.component';
import { OneTimeAccessComponent } from './views/auth/one-time-access/one-time-access.component';
import { NuggetsComponent } from './views/nuggets/nuggets.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'personal',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'tours',
    component: ToursComponent,
    data: {
      title: 'Nudge Tour'
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'screenshot',
    loadChildren: () => import('./views/reporting/screenshot/screenshot.module').then(m => m.ScreenshotModule),
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    resolve: [UserResolver],
    canActivateChild: [CanActivateAuthChild, NavPreventerService],
    children: [
      {
        path: 'config',
        loadChildren: () => import('./views/config/config.module').then(m => m.ConfigModule),
      },
      {
        path: 'dashboard',
        redirectTo: 'personal',
        pathMatch: 'full'
      },
      {
        path: 'plan',
        loadChildren: () => import('./views/plan/plan.module').then(m => m.PlanModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'progress',
        loadChildren: () => import('./views/progress/progress.module').then(m => m.ProgressModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'progress/:nudge',
        loadChildren: () => import('./views/progress/progress.module').then(m => m.ProgressModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'team',
        loadChildren: () => import('./views/team/team.module').then(m => m.TeamModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'nudges',
        loadChildren: () => import('./views/nudge-tab/nudge-tab.module').then(m => m.NudgeTabModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'reflect',
        loadChildren: () => import('./views/reflect/reflect.module').then(m => m.ReflectModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'dashboard2',
        loadChildren: () => import('./views/dashboard2/dashboard.module').then(m => m.DashboardModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'coach',
        loadChildren: () => import('./views/coach/coach.module').then(m => m.CoachModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'reporting',
        loadChildren: () => import('./views/reporting/reporting.module').then(m => m.ReportingModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'home',
        loadChildren: () => import('./views/personal/personal.module').then(m => m.PersonalModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'personal',
        loadChildren: () => import('./views/personal/personal.module').then(m => m.PersonalModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'company',
        loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'clients',
        loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        runGuardsAndResolvers: 'always',
        canActivate: [UserValidatedGuard]
      },
      {
        path: 'nuggets',
        component: NuggetsComponent,
        canActivate: [UserValidatedGuard]
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
