export class SessionModel {
  User_ID: number;
  User_First_Name: string;
  User_Full_Name: string
  User_Email: string;
  User_Outlook_ID: string;
  User_Token: string;
  User_Job_Title: string;
  Program_ID: number;
  User_Program_ID: number;
  User_Profile_Pic: string;
  Client_ID: number;
  User_Role: number;
  time_zone_id: number;
  personality_type_id: number;
}
