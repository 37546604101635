import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NudgeChartComponent } from './nudge-chart/nudge-chart.component';
import { ProgressChartComponent } from './progress-chart/progress-chart.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { EngagementChartComponent } from './engagement-chart/engagement-chart.component';
import { ChartsModule } from 'ng2-charts';
import { TimeSpentChartComponent } from './time-spent-chart/time-spent-chart.component';
import { AddNudgeComponent } from './nudges/add-nudge/add-nudge.component';
import { RouterModule } from '@angular/router';
import { MeasureComponent } from './nudges/measure/measure.component';
import { AddMeasureComponent } from './nudges/add-measure/add-measure.component';
import { NudgesPlanComponent } from './nudges/nudges-plan/nudges-plan.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CompanyMeasureComponent } from './nudges/company-measure/company-measure.component';
import { BusinessAmbitionComponent } from './nudges/business-ambition/business-ambition.component';
import { AddBusinessAmbitionComponent } from './nudges/add-business-ambition/add-business-ambition.component';
import { BusinessSprintComponent } from './nudges/business-sprint/business-sprint.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddSetupService } from '../../views/account/add-setup/add-setup.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { TodaysNudgesComponent } from './todays-nudges/todays-nudges.component';
import { ScheduleChangeComponent } from './schedule-change/schedule-change.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { WeeklyCalendarComponent } from './weekly-calendar/weekly-calendar.component';
import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TeamChartComponent } from './team-chart/team-chart.component';
import { AddNudgePlanComponent } from './nudges/add-nudge-plan/add-nudge-plan.component';
import { NudgeChart2Component } from './nudge-chart2/nudge-chart2.component';
import { NudgeDayTrackerComponent } from './nudge-day-tracker/nudge-day-tracker.component';
import {IvyCarouselModule} from '../components/carousel/carousel.module'
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ChartsModule as KendoCharts } from "@progress/kendo-angular-charts";
import { LoaderComponent } from './loader/loader.component';
import { CoachChatComponent } from './coach-chat/coach-chat.component';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ClientPickerComponent } from './client-picker/client-picker.component';
import { ImplementationComponent } from './implementation/implementation.component';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper'
import { ValuesIconPickerComponent } from './values-icon-picker/values-icon-picker.component';
import { GraphCalendarComponent } from './graph-calendar/graph-calendar.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { HomeStatsComponent } from './home-stats/home-stats.component';
import { StatsMyProgressComponent } from './home-stats/stats-my-progress/stats-my-progress.component';
import { MotvationalNuggetsComponent } from './home-stats/motivational-nuggets/motivational-nuggets.component';
import { MyEverydayActionsComponent } from './home-stats/my-everyday-actions/my-everyday-actions.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { HtmlWidgetComponent } from './html-widget/html-widget.component';
import { SafeHtmlPipe } from '../utils/htmlPipe.util';
import { TimelineComponent } from './timeline/timeline.component';
import { KeyResultsComponent } from './key-results/key-results.component';
import { ReflectionJournalComponent } from './reflection-journal/reflection-journal.component';
import { ImageUploaderChatComponent } from './image-uploader-chat/image-uploader.component-chat';
import { CreateNuggetComponent } from './create-nugget/create-nugget.component';
import { VideoUploaderComponent } from './video-uploader/video-uploader.component';
import { SprintNuggetsComponent } from './sprint-nuggets/sprint-nuggets.component';
import { NuggetCardComponent } from './nugget-card/nugget-card.component';
import { NuggetVideoComponent } from './nugget-card/nugget-video/nugget-video.component';
import { NuggetTranscriptComponent } from './nugget-card/nugget-transcript/nugget-transcript.component';
import { NuggetPDFComponent } from './nugget-card/nugget-pdf/nugget-pdf.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

export class CustomDateFormatter extends CalendarNativeDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('ca', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }
}

@NgModule({
  declarations: [
    NudgeChartComponent,
    ProgressChartComponent,
    StarRatingComponent,
    EngagementChartComponent,
    TimeSpentChartComponent,
    AddNudgeComponent,
    MeasureComponent,
    AddMeasureComponent,
    NudgesPlanComponent,
    CompanyMeasureComponent,
    BusinessAmbitionComponent,
    AddBusinessAmbitionComponent,
    BusinessSprintComponent,
    FeedbackComponent,
    TodaysNudgesComponent,
    NudgeDayTrackerComponent,
    ScheduleChangeComponent,
    WeeklyCalendarComponent,
    TeamChartComponent,
    AddNudgePlanComponent,
    NudgeChart2Component,
    LoaderComponent,
    CoachChatComponent,
    ClientPickerComponent,
    ImplementationComponent,
    ImageUploaderComponent,
    ValuesIconPickerComponent,
    GraphCalendarComponent,
    HomeStatsComponent,
    StatsMyProgressComponent,
    MotvationalNuggetsComponent,
    MyEverydayActionsComponent,
    TabNavigationComponent,
    HtmlWidgetComponent,
    SafeHtmlPipe,
    TimelineComponent,
    KeyResultsComponent,
    ReflectionJournalComponent,
    ImageUploaderChatComponent,
    CreateNuggetComponent,
    VideoUploaderComponent,
    SprintNuggetsComponent,
    NuggetCardComponent,
    NuggetVideoComponent,
    NuggetTranscriptComponent,
    NuggetPDFComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    RatingModule.forRoot(),
    RouterModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule,
    DragDropModule,
    NgSelectModule,
    IvyCarouselModule,
    InputsModule,
    DialogModule,
    LayoutModule,
    ButtonsModule,
    GridModule,
    ListViewModule,
    IndicatorsModule,
    DropDownsModule,
    KendoCharts,
    DateInputsModule,
    ImageCropperModule,
    SchedulerModule,
    NgxExtendedPdfViewerModule,
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
      }
    }),
  ],
  exports: [
    NudgeChartComponent,
    ProgressChartComponent,
    StarRatingComponent,
    EngagementChartComponent,
    TimeSpentChartComponent,
    AddNudgeComponent,
    MeasureComponent,
    NudgesPlanComponent,
    CompanyMeasureComponent,
    BusinessAmbitionComponent,
    AddBusinessAmbitionComponent,
    BusinessSprintComponent,
    FeedbackComponent,
    TodaysNudgesComponent,
    NudgeDayTrackerComponent,
    ScheduleChangeComponent,
    WeeklyCalendarComponent,
    TeamChartComponent,
    NudgeChart2Component,
    LoaderComponent,
    CoachChatComponent,
    ClientPickerComponent,
    ImplementationComponent,
    ImageUploaderComponent,
    ValuesIconPickerComponent,
    GraphCalendarComponent,
    HomeStatsComponent,
    TabNavigationComponent,
    HtmlWidgetComponent,
    SafeHtmlPipe,
    TimelineComponent,
    KeyResultsComponent,
    ReflectionJournalComponent,
    ImageUploaderChatComponent,
    CreateNuggetComponent,
    VideoUploaderComponent,
    SprintNuggetsComponent,
    NuggetCardComponent,
    NuggetVideoComponent,
    NuggetTranscriptComponent,
    NuggetPDFComponent
  ],
  providers: [AddSetupService]
})
export class ComponentsModule { }

