import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { 

  }

  public settings: ISettings = null;

  public setupSettings(): Promise<any> {
    return new Promise(resolve => {
      this.http.get<ISettings>("./../../../settings.json").subscribe(settings => {
        this.settings = settings;
        resolve(settings);
      })
    });
  }
}

export interface ISettings {
    apiUrl: string,
    signalrUrl: string,
    production: boolean,
    websiteUrl: string
}