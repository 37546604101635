export class NudgeRequest {
    user_action_id: number;
    program_id: number;
    action_name: string;
    action_description: string;
    category_id: number;
    is_active: boolean;
    program_goal_id: number;
    user_id: number;
    sprint_template_suggested_nudge_id: number;
}


export class WeeklyPlanRequest {
    user_diary_id: number;
    user_action_id: number;
    diary_date: string;
    user_id: number;
    status_id: number;
    start_time: string;
    end_time: string;
    is_active: boolean;
    isChecked: boolean
    
}
