import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import { ReportingService } from '../../services/reporting.service';

@Component({
  selector: 'client-picker',
  templateUrl: './client-picker.component.html',
  styleUrls: ['./client-picker.component.scss']
})
export class ClientPickerComponent implements OnInit {

  @Output() onChange: EventEmitter<number> = new EventEmitter();

  constructor(private reportingService: ReportingService) { }

  public clients: any[] = new Array();

  public ngOnInit(): void {
    this.reportingService.getAccessibleClientList().subscribe(result => {
        this.clients = result;

        if (this.clients && this.clients.length > 0)
            this.onChange.emit(this.clients[0].client_id);
      });
  }
  
  public clientChange(clientId: number): void {
    this.onChange.emit(clientId);
  }
}
