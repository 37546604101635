import { Component, Input, OnInit } from '@angular/core';
import { MotivationalNugget } from '../../services/process.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NuggetVideoComponent } from './nugget-video/nugget-video.component';
import { NuggetTranscriptComponent } from './nugget-transcript/nugget-transcript.component';
import { NuggetPDFComponent } from './nugget-pdf/nugget-pdf.component';

@Component({
  selector: 'nugget-card',
  templateUrl: './nugget-card.component.html',
  styleUrls: ['./nugget-card.component.scss']
})
export class NuggetCardComponent implements OnInit {

    @Input() nugget: MotivationalNugget;

    constructor(private dialogService: DialogService) { }

    public ngOnInit(): void {

    }

    public viewNugget():  void {
        let popup = this.dialogService.open({
            title: "View",
            content: this.nugget.blob_URL.endsWith('.pdf') ? NuggetPDFComponent : NuggetVideoComponent
        });
        if (this.nugget.blob_URL.endsWith('.pdf')) {
            popup.dialog.instance.width = "80vw";
            popup.dialog.instance.height = "80vh";
        }
        popup.content.instance.nugget = this.nugget;
    }

    public viewTranscript(event: any): void {
        let popup = this.dialogService.open({
            title: "Transcript",
            content: NuggetTranscriptComponent
        });
        popup.content.instance.nugget = this.nugget;
        event.stopPropagation();
    }
}