import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { NumberPipe } from "@progress/kendo-angular-intl";
import { GroupDescriptor, process, State } from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { ReportingService } from "../../../shared/services/reporting.service";

export class Report {
    constructor(private reportingService: ReportingService, private report: any, private animation: boolean) {
        this._reportingService = reportingService;
        Object.assign(this, report);

        this.rawData = this.data;

        if (this.report.reportType == 2)
            this.dataStateChange(null);
    }

    private _reportingService: ReportingService;

    public id: number;
    public drilldown: Report
    public reportType: number;
    public reportWidth: number;
    public title: string;

    //Chart
    public series: any;
    public valueAxes: any;
    public crossingValues: any;
    public categories: any;
    public type: number = 8;
    public labels: any;
    public tooltip: any;
    public gap: number = 0.25;
    public spacing: number = 0.25;
    public legend: any;
    public categoryTitle: string;
    public metricValue: string;
    public metricText: string;

    //Table
    public rawData: any;
    public data: any;
    public columns: any;
    public groupable: boolean = true;
    public pageable: boolean = true;

    public pageSize: number = 8;
    public gridView: GridDataResult;
    public state: State = {
      skip: 0,
      take: 8,
      sort: [],
      group: [],
      filter: {
        logic: "and",
        filters: [],
      },
    };

    //Gauge
    public value: any;
    public colors: any;
    public gaugeType: any;
    public gauges: any;
    

    public seriesLabels: any = {
        visible: true, // Note that visible defaults to false
        padding: 3,
        font: "bold 16px Arial, sans-serif",
      };

    public dataStateChange(state: DataStateChangeEvent): void {
        if (state)
          this.state = state;
  
        if (this.report.groups && this.report.groupable)
          this.state.group = this.report.groups;
        
        if (this.report.data)
          this.gridView = process(this.report.data, this.state);
      }
}