import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ChartOptions, ChartSize } from "chart.js";
import { Engagement } from "../../models/responses/engagement.response";

@Component({
  selector: 'app-enagagement-chart',
  templateUrl: './engagement-chart.component.html'
})

export class EngagementChartComponent implements OnInit, OnChanges {
    weeklyEngagementChart = {
        isLoaded: false,
        labels: [],
        data: [],
        options: {},
    };
    @Input() data: Engagement[] = [];
    @Output() onChartClick = new EventEmitter<any>();
    constructor(){

    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.data && changes.data.currentValue && changes.data.currentValue.length > 0){
            this.data = changes.data.currentValue as Engagement[] || [];
            this.setDataToChart();
        }
    }

    setDefaultChartOptions() {
        const options: ChartOptions = {
            showLines: false,
            responsive: true,
            legend: {
                display: false,
            },
            title: {
                text: 'Week',
                position: 'bottom',
                display: true
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        min: 1,
                        max: 10,
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        min: 1,
                        max: 10,
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: (tooltip) => `Week ${tooltip[0].label}`,
                }
            },
            onResize:(newSize) => this.handleChartResize(newSize)
        };
        this.weeklyEngagementChart.options = options;
        this.weeklyEngagementChart.labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    }

    handleChartResize(newSize: ChartSize) {
        let data = this.weeklyEngagementChart.data;
        let width = newSize.width / 10;
        let barWidth = width*90/100
        data[0].barThickness = barWidth;
    };

    setDataToChart(): void {
        this.setDefaultChartOptions();
        let data = this.data.map(x => x.npS_score);
        var backgroundColors = [];
        var hoverBackgroundColors = [];
        data.map(x => {
            if(x >= 9){
                backgroundColors.push("rgba(241, 57, 94)");
                hoverBackgroundColors.push("rgba(241, 57, 94, 0.7)");
            } else if(x >= 7.5 && x < 9){
                backgroundColors.push("rgba(254, 204, 86)");
                hoverBackgroundColors.push("rgba(254, 204, 86, 0.7)");
            } else{
                backgroundColors.push("rgba(133, 202, 135)");
                hoverBackgroundColors.push("rgba(133, 202, 135, 0.7)");
            }
        });
        this.weeklyEngagementChart.data = [
            {
                data: data,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: hoverBackgroundColors,
                borderWidth: {left: 2, right: 2, top: 2, bottom: 0},
                borderColor: hoverBackgroundColors,
                hoverBorderColor: hoverBackgroundColors,
                barThickness: 67,
                label: 'Engagement',
            },
        ];
        this.weeklyEngagementChart.isLoaded = true;
    }

    handleChartClick(e: any): void {
        this.onChartClick.emit(e)
    }
}