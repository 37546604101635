import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  connection: signalR.HubConnection;
  updateHasOccured: BehaviorSubject<boolean>;
  exportHasOccured: BehaviorSubject<number>;
  chatHasOccured: BehaviorSubject<boolean>;
  chatHasBeenRead: BehaviorSubject<any>;
  public hasNewExports: boolean = false;
  public unreadCoacheeChatCoacheeIds: Array<number> = new Array<number>();
  public hasUnreadChatsFromCoachees: boolean = false;
  public hasUnreadChatsFromCoach: boolean = false;

  constructor(private settingsService: SettingsService,
        private toasterService: ToastrService,
        private authenticationService: AuthenticationService) { 
    this.updateHasOccured = new BehaviorSubject<boolean>(null);
    this.exportHasOccured = new BehaviorSubject<number>(null);
    this.chatHasOccured = new BehaviorSubject<boolean>(null);
    this.chatHasBeenRead = new BehaviorSubject<any>(null);
  }

  public initiateSignalrConnection(): Promise<any>{
        ///
        // I don't think this works as there is no active user at this point. Testing still passed 
        // so commenting out should be okay. If coach notificaiton dots are still working after the
        // next release this can just be deleted.
        ///
        // this.updateMessagesStatuses();

        return new Promise((resolve, reject) => {

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(this.settingsService.settings.signalrUrl + 'nudgeupdate', {
                    accessTokenFactory: () => localStorage.getItem("refreshToken")
                }) // the SignalR server url
                .build();

            this.connection.onclose(() => {
                setTimeout(() => {
                    this.initiateSignalrConnection();
                }, 5000)
            });

            this.connection
                .start()
                .then(() => {
                console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `);
                resolve(0);
                })
                .catch((error) => {
                console.log(`SignalR connection error: ${error}`);
                reject();
                });
        });
    }

    public setSignalrClientMethods(): void {
        this.connection.on('UpdateClients', (update: boolean) => {
            this.updateHasOccured.next(update);
        });
    }

    public monitorExports(): void {
        this.connection.on('ExportReady', (type: number) => {
            this.hasNewExports = true;
            this.exportHasOccured.next(type);
        });
    }

    public monitorChat(): void {
        this.connection.on('ChatUpdated', (update: ICoachChat)  => {
            this.updateMessagesStatuses();
            this.toasterService.show("You have received a new coach chat message");
            this.chatHasOccured.next(true);
        });
    }

    public updateOtherClients() {
        this.connection.invoke("UpdateClients", true);
    }

    public chatUpdated(user_id: number, isCoach: boolean) {
        this.connection.invoke("ChatUpdated", user_id, isCoach);
    }

    public exportsChecked() {
        this.hasNewExports = false;
    }

    public messagesChecked(userId: number = null) {    
        this.authenticationService.markChatWithUserAsRead(userId, this.authenticationService.currentUserValue.User_ID)
                .subscribe(() => {
            this.chatHasBeenRead.next(true);   
            this.updateMessagesStatuses();
        }); 
    }

    public updateMessagesStatuses() {
        this.authenticationService.getUsersUnreadChats().subscribe(result => {
            this.unreadCoacheeChatCoacheeIds = new Array<number>();
            result.forEach((chat) => this.unreadCoacheeChatCoacheeIds.push(chat.from_user_id));

            this.hasUnreadChatsFromCoachees = this.unreadCoacheeChatCoacheeIds
                .filter(user_id => user_id != this.authenticationService.currentSprintValue.coach_id).length > 0

            this.hasUnreadChatsFromCoach = this.unreadCoacheeChatCoacheeIds
                .filter(user_id => user_id == this.authenticationService.currentSprintValue.coach_id).length > 0;
        });
    }
}

export interface ICoachChat {
    item1: any;
    item2: any;
    item3: any;
}