import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() size: string;
  @Input() thickness: number;
  @Input() show: boolean = false;
  public get containerSize(): number { 
    return parseInt(this.size) + 15;
  }

  constructor() { }

  ngOnInit(): void {}
  
}
