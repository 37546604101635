import { Component, OnInit } from '@angular/core';
import { CoachDetails, CoachService } from '../../shared/services/coach.service';

@Component({
  selector: 'app-tab-coach',
  templateUrl: './my-coach.component.html',
  styleUrls: ['./my-coach.component.scss']
})
export class MyCoachComponent implements OnInit {

  constructor(private coachService: CoachService) {

  }

  public coach: CoachDetails;

  ngOnInit(): void {
    this.coachService.getCoachDetails().subscribe(result => {
      this.coach = result;
    });
  }
}