import { OnInit } from "@angular/core";
import { AuthenticationService } from "../shared/services/authentication.service";
import { SignalrService } from "../shared/services/signalr.service";

export class BaseComponent {
    constructor(
        private signalrService_: SignalrService,
        private authenticationService_: AuthenticationService) {

    }

    setupSignalR(): Promise<any> {
        return this.signalrService_.initiateSignalrConnection().then(() => {
            this.signalrService_.setSignalrClientMethods();
        });
    }

    userId(): number {
        return this.authenticationService_.currentUserValue.User_ID;
    }
}