import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../shared/components/components.module';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { GridModule } from '@progress/kendo-angular-grid';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipsModule } from "@progress/kendo-angular-tooltip";

import { DashboardComponent } from './dashboard/dashboard.component';
import { MyCoachComponent } from './my-coach/my-coach.component';
import { PlanComponent } from './plan/plan.component';
import { ProgressComponent } from './progress/progress.component';
import { ReflectComponent } from './reflect/reflect.component';
import { ChartComponent } from './reporting/chart/chart.component';
import { MyDasboardComponent } from './reporting/dashboards/my/mydashboard.component';
import { NuggetsComponent } from './nuggets/nuggets.component';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ToursComponent } from './tours/tours.component';
import { SafePipe } from '../shared/utils/safePipe.util';


@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    ChartsModule,
    GridModule,
    GaugesModule,
    DialogModule,
    PopoverModule.forRoot(),
    LayoutModule,
    TooltipsModule,
    FormsModule,
    DateInputsModule,
  ],
  declarations: [
        ChartComponent,
        DashboardComponent,
        PlanComponent,
        ProgressComponent,
        ReflectComponent,
        MyCoachComponent,
        MyDasboardComponent,
        NuggetsComponent,
        ToursComponent,
        SafePipe
    ],
    exports: [
        ChartComponent,
        DashboardComponent,
        PlanComponent,
        ProgressComponent,
        ReflectComponent,
        MyCoachComponent,
        MyDasboardComponent,
        NuggetsComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [SafePipe]
})
export class ViewsModule { }
