import { Component,Input,OnInit} from '@angular/core';

@Component({
  selector: 'html-widget',
  templateUrl: './html-widget.component.html',
  styleUrls: ['./html-widget.component.scss']
})
export class HtmlWidgetComponent implements OnInit {

    @Input() html: string;

    constructor() {

    }

    ngOnInit(): void {

    }
}