import { Component, OnInit } from '@angular/core';
import { QuestionAnswer } from '../../shared/models/responses/question-answer.response';
import { ReflectSummaryResponse } from '../../shared/models/responses/reflect-summary.response';
import { ReflectWeekResponse } from '../../shared/models/responses/reflect-week.response';
import { FeedbackService } from '../../shared/services/feedback.service';
import { HostListener } from "@angular/core";
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Sprint } from '../../shared/models/responses/sprint.response';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ProcessService } from '../../shared/services/process.service';
import { SprintGoalResponse } from '../../shared/models/responses/sprint-goal.response';
import { isThisQuarter } from 'date-fns';

@Component({
  selector: 'app-tab-reflect',
  templateUrl: './reflect.component.html',
  styleUrls: ['./reflect.component.css']
})
export class ReflectComponent implements OnInit {

  reflectSummary: ReflectSummaryResponse;
  reflectWeeks: ReflectWeekResponse[] = [];
  reflectQuestionAnswer: QuestionAnswer[];
  processedQuestionAnswer: any[] = [];
  activeIndex: number = 0;
  maxPeriod: number = 0;
  radius: number = 80;
  starWidth: string = 'max';
  //imageSize: number = 80;
  fontSize: number = 18; 
  outerStrokeColor: string = 'RGB(202,3,72)'
  currentSprint: Sprint;
  scores: any[] = [];
  isFeedbackVisible: boolean = false;
  title: Array<string> = ["", "", "", "", ""]; 
  showLandingArea: boolean = true;


  constructor(private feedbackService: FeedbackService, private router: Router, private authService: AuthenticationService, private processService: ProcessService) {
    this.currentSprint = this.authService.currentSprintValue;
    //this.onResize();
  }

  ngOnInit(): void {
    this.getQuestionAnswerList();
    this.getReflectSummary();
    this.getReflectWeekData();
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event?) {
  //   if (window.innerWidth > 1225) {
  //     this.radius = 70;
  //     this.starWidth = 'max';
  //     this.imageSize = 70;
  //     this.fontSize = 18;
  //   } else if (window.innerWidth > 1100 && window.innerWidth < 1225) {
  //     this.radius = 50;
  //     this.starWidth = 'max';
  //     this.imageSize = 60;
  //     this.fontSize = 14;
  //   }
  //   else if (window.innerWidth > 500 && window.innerWidth < 767) {
  //     this.radius = 80;
  //     this.starWidth = 'max';
  //     this.imageSize = 70;
  //     this.fontSize = 18;
  //   } else {
  //     this.radius = 50;
  //     this.starWidth = 'min';
  //     this.imageSize = 50;
  //     this.fontSize = 12;
  //   }
  // }

  getReflectSummary() {
    this.feedbackService.getReflectSummary().subscribe(res => {
      this.reflectSummary = res;
    });
  }
  getReflectWeekData() {
    this.feedbackService.getReflectSprintWeek().subscribe(res => {
      this.reflectWeeks = res;
    });
  }

  getQuestionAnswerList() {
    this.feedbackService.getQuestionAnswerList().subscribe(res => {
      this.reflectQuestionAnswer = res;
      for (let single of this.reflectQuestionAnswer) {
        let obj = {
          answers: [],
          question: '',
          nps_score: 0,
          period: 0,
          question_id: 0,
          feedback_score: 0
        };
        this.maxPeriod = single.period > this.maxPeriod ? single.period : this.maxPeriod;
        let ind = this.processedQuestionAnswer?.findIndex(x => x.period == single.period && x.question_id == single.question_id);
        if (ind >= 0) {
          if (single.text_answer.trim()) {
            this.processedQuestionAnswer[ind].answers.push(single.text_answer);
          }
        } else {
          if (single.text_answer.trim()) {
            obj.answers.push(single.text_answer);
          }
          obj.question = single.question_summary;
          obj.nps_score = single.npS_Score;
          obj.period = single.period;
          obj.question_id = single.question_id;
          obj.feedback_score = single.feedback_score;

          this.scores[single.period] = { npsScore: single.npS_Score, feedbackScore: single.feedback_score };
          this.processedQuestionAnswer.push(obj);
        }
        //console.log(this.processedQuestionAnswer);
      }

    });
  }
  onDateSelect(row: ReflectWeekResponse) {
    this.showLandingArea = false;
    if (row.pulse_score > 0) {
      this.activeIndex = row.sprint_week - 1;
      this.showFeedback();
    }
  }

  onNavigate(action) {
    if (action == 'NEXT') {
      if (this.activeIndex + 1 < this.maxPeriod) {
        this.activeIndex += 1;
      }
    } else {
      if (this.activeIndex > 0) {
        this.activeIndex -= 1;
      }
    }
  }
  goToProgress() {
    this.router.navigate(['/progress']);
  }

  getImage(score) {
    if (score >= 90) {
      this.outerStrokeColor = 'RGB(104,159,118)';
      return  './assets/img/brain/150x150_brain-green-02.svg';
    } else if (score >= 75 && score < 90) {
      this.outerStrokeColor = 'RGB(239,121,85)';
      return './assets/img/brain/150x150_brain-amber-02.svg';
    } else {
      this.outerStrokeColor = 'RGB(202,3,72)';
      return './assets/img/brain/150x150_brain-red-02.svg';
    }
  }
  showFeedback() {
    this.isFeedbackVisible = true;
  }
  hideFeedback() {
    this.isFeedbackVisible = false;
    this.showLandingArea = true;
  }
}
