import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "./settings.service";

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(
        private http: HttpClient, 
        private settingsService: SettingsService) {

        }

    private apiController: string = `${this.settingsService.settings.apiUrl}/user/`;

    public getImportSessionId(): Observable<string> {
        return this.http.get<string>(this.apiController + "GetImportSessionId");
    }

    public uploadUserFile(sessionId: string, clientId: number, file: any): Observable<Array<IImportUser>> {
        return this.http.post<Array<IImportUser>>(this.apiController + "ImportUserList", {
            file: file,
            client_id: clientId,
            session_id: sessionId
        });
    }

    public importUserFile(importFile: any, fieldsMapping: Array<FieldMap>, clientId: number): Observable<any> {
        return this.http.post<Array<IImportUser>>(this.apiController + "ImportUserFile", {
            file: importFile,
            mapping: fieldsMapping,
            client_id: clientId
        });
    }

    public importMappedUserFile(importFile: any, fieldsMapping: Array<FieldMap>, clientId: number, session_id: string): Observable<any> {
        let jsonObject = {
            file: importFile,
            mapping: fieldsMapping
        }

        return this.http.post<Array<IImportUser>>(this.apiController + "ImportMappedUserFile", {
            JSONstr: JSON.stringify(jsonObject),
            session_id: session_id,
            client_id: clientId
        });
    }

    public bulkCreateUsers(import_session_id: string): Observable<IImportComplete> {
        return this.http.post<IImportComplete>(this.apiController + "BulkCreateUsers", {
            import_session_id: import_session_id
        });
    }

    public deleteImportSession(session_id: string): Observable<any> {
        return this.http.post(this.apiController + "DeleteImportSession", {
            session_id: session_id
        });
    }

    public saveAndUpdateUser(user: SaveUser): Observable<any> {
        return this.http.post(this.apiController + "SaveAndUpdateUser", user);
    }

    public getClientUserList(client_id: number): Observable<Array<UserListProfile>> {
        var params = new HttpParams();
        params = params.append("client_id", client_id.toString());

        return this.http.get<Array<UserListProfile>>(this.apiController + "GetClientUserList", {
            params: params
        });
    }

    public getUserWithOrg(user_id: number): Observable<UserWithOrg> {
        var params = new HttpParams();
        params = params.append("this_user_id", user_id.toString());

        return this.http.get<UserWithOrg>(this.apiController + "GetUserWithOrg", {
            params: params
        });
    }

    public getUserSprintActivity(user_id: number): Observable<UserSprintActivity> {
        var params = new HttpParams();
        params = params.append("user_id", user_id.toString());

        return this.http.get<UserSprintActivity>(this.apiController + "GetUserSprintActivity", {
            params: params
        });
    }

    public userLoginActivity(user_id: number): Observable<UserLoginActivity> {
        var params = new HttpParams();
        params = params.append("user_id", user_id.toString());

        return this.http.get<UserLoginActivity>(this.apiController + "UserLoginActivity", {
            params: params
        });
    }

    public getUserCommunicationActivity(user_id: number): Observable<UserCommunicationActivity> {
        var params = new HttpParams();
        params = params.append("user_id", user_id.toString());

        return this.http.get<UserCommunicationActivity>(this.apiController + "GetUserCommunicationActivity", {
            params: params
        });
    }

    public getUserSupportActivity(user_id: number): Observable<UserSupportActivity> {
        var params = new HttpParams();
        params = params.append("user_id", user_id.toString());

        return this.http.get<UserSupportActivity>(this.apiController + "GetUserSupportActivity", {
            params: params
        });
    }

    public getImportMappingFields(): Observable<Array<AvailableField>> {
        return this.http.get<Array<AvailableField>>(this.apiController + "GetImportMappingFields");
    }

    public markUserAsLeft(user_id_left: number, date_left: Date, is_active: boolean): Observable<any> {
        let markAsLeft = {
            user_id_left: user_id_left,
            date_left: date_left,
            is_active: is_active
        }
        
        return this.http.post(this.apiController + "MarkUserAsLeft", markAsLeft);
    }

    public getUserProfilePic(user_id: number): Observable<Blob> {
        var params = new HttpParams();
        params = params.append("user_id", user_id.toString());

        return this.http.get<Blob>(this.apiController + "getUserProfilePic", {
            params: params
        });
    }

    public addSupportTicket(subject: string, description: string): Observable<any> {
        let supportTicket = {
            user_id: 0,
            subject: subject,
            description: description
        }
        
        return this.http.post(this.apiController + "AddSupportTicket", supportTicket);
    }
}

export interface IImportUser {
    first_name: string;
    full_name: string;
    job_title: string;
    contact_no: string;
    email_address: string;
    client_id: number;
    managers_email: string;
    coaches_email: string;
    org_level_1: string;
    org_level_2: string;
    org_level_3: string;
    org_level_4: string;
    org_level_5: string;
    has_error: boolean;
    error_code: number;
    has_warning: boolean;
}

export interface IImportComplete {
    processed_count: number;
    add_count: number;
    update_count: number;
    invalid_count: number;
}

export class SaveUser {
    public client_id: number;  
    public user_id: number;  
    public first_name: string;  
    public full_name: string;  
    public job_title: string;  
    public contact_no: string;  
    public email_address: string;  
    public manager_user_id: number;  
    public coach_user_id: number;  
    public role_id: number;  
    public user_org_id: number;  
    public org_unit_1_id: number;  
    public org_unit_2_id: number;  
    public org_unit_3_id: number;  
    public org_unit_4_id: number;  
    public org_unit_5_id: number;  
    public is_active: boolean;  
    public date_from: Date;  
}

export class UserListProfile {
    public user_id: number;  
    public first_name: string;  
    public full_name: string;  
    public job_title: string;  
    public contact_no: string;  
    public profile_pic: string;  
    public email_address: string;  
    public date_left: Date;  
    public is_active: boolean;  
    public role_name: string;  
    public licence_name: string;  
    public last_login_date: Date;  
}

export class UserWithOrg {
    public user_id: number;  
    public first_name: string;  
    public full_name: string;  
    public job_title: string;  
    public contact_no: string;  
    public profile_pic: string;  
    public email_address: string;  
    public date_left: Date;  
    public is_active: boolean;  
    public role_id: number;  
    public role_name: string;  
    public manager_user_id: number;  
    public manager_full_name: string;  
    public coach_user_id: number;  
    public coach_full_name: string;  
    public org_unit_id: number;  
    public org_level_1_id: number;  
    public org_level_2_id: number;  
    public org_level_3_id: number;  
    public org_level_4_id: number;  
    public org_level_5_id: number;  
    public org_level_1: string;  
    public org_level_2: string;  
    public org_level_3: string;  
    public org_level_4: string;  
    public org_level_5: string;  
    public licence_used: string;  
}

export class UserSprintActivity {
    public thisSprint: CurrentSprint = new CurrentSprint();
    public sprintHistory: Array<SprintActivity> = new Array();
}

export class CurrentSprint {
    public sprint_id: number;  
    public sprint_name: string;  
    public sprint_manager: string;  
    public sprint_start_date: Date;  
    public sprint_end_date: Date;  
    public is_snoozed: boolean;  
    public is_active: boolean; 
    public user_program_id: number; 
}

export class SprintActivity {
    public sprint_id: number;  
    public sprint_name: string;  
    public sprint_start_date: Date;  
    public sprint_end_date: Date;  
    public nudge_count: number;  
    public sprint_status: string;  
}

export class UserLoginActivity {
    public loginSummary: LoginSummary = new LoginSummary();
    public loginHistory: Array<LoginActivity> = new Array();
}

export class LoginSummary {
    public last_login_date: Date;  
    public created_date: Date;  
    public modified_date: Date;  
    public date_left: Date;  
    public account_status: string;  
}

export class LoginActivity {
    public login_date: Date;  
    public outcome: string;  
    public operating_system: string;  
    public ip_address: string;  
}

export class UserCommunicationActivity {
    public commsSummary: CommsSummary = new CommsSummary();
    public commsHistory: Array<CommunicationActivity> = new Array();
}

export class CommsSummary {
    public email_enabled: boolean;  
    public mobile_enabled: boolean;  
    public advance_warning_on_time_nudges: string;  
    public reminders_on_timed_nudges: string;  
    public messages_sent: number;  
    public last_subject: string;
    public last_sent: string;
}

export class CommunicationActivity {
    public comms_date: Date;  
    public comms_type: string;  
    public subject: string;  
    public is_received: boolean;  
}

export class UserSupportActivity {
    public supportSummary: SupportSummary = new SupportSummary();  
    public supportHistory: Array<SupportActivity> = new Array();  
}

export class SupportSummary {
    public tickets_logged: number;  
    public tickets_open: number;  
}

export class SupportActivity {
    public date_logged: Date;  
    public subject: string; 
    public ticket_id: number;  
    public ticket_status: string;  
}

export class AvailableField {
    public import_field_id: number;
    public friendly_name: string;
    public is_mandatory: boolean;
}

export class FieldMap {
    constructor(header, firstValue, fieldId) {
        this.header = header;
        this.firstValue = firstValue;
        this.fieldToMapToId = fieldId;
    }

    public header: string;
    public firstValue: string;
    public fieldToMapToId: number;
}