import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { SettingsService } from "./settings.service";
import { SignalrService } from "./signalr.service";

@Injectable({ providedIn: 'root' })
export class ReportingService {

  constructor(
    private http: HttpClient, 
    private settingsService: SettingsService,
    private authService: AuthenticationService,
    private signalrService: SignalrService
    ) { }

  getDashboard(program_id: number, dashboardId: number, reportId: number, client_id: number, coached_user_id: number): Observable<Array<any>> {
    if (!client_id)
      client_id = 0;

    if (!coached_user_id)
      coached_user_id = 0;
    
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());
    params = params.append("dashboard_id", dashboardId.toString());
    params = params.append("report_id", reportId.toString());
    params = params.append("client_id", client_id.toString());
    params = params.append("coached_user_id", coached_user_id.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/GetDashboard`, {
      params: params
    });
  }

  getScreenshotReport(program_id: number , report_id: number, session_id: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());
    params = params.append("report_id", report_id.toString());
    params = params.append("session_id", session_id.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/GetScreenshotReport`, {
      params: params
    });
  }

  // getReport(reportId: number): Observable<any> {
  //   var params = new HttpParams();
  //   params = params.append("user_program_id", this.authService.currentSprintValue.user_program_id.toString());
  //   params = params.append("report_id", reportId.toString());

  //   return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/GetReport`, {
  //     params: params
  //   });
  // }

  exportFile(type: number, report_id: number, program_id: number, client_id: number, report_name: string): Observable<any> {
    var params = new HttpParams();
    params = params.append("report_id", report_id.toString());
    params = params.append("program_id", program_id.toString());
    params = params.append("client_id", client_id.toString());
    params = params.append("report_name", report_name);
    params = params.append("type", type.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/RequestExport`, {
      params: params
    });
  }

  exportHistory(): Observable<Array<any>> {
    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/ExportHistory`);
  }

  downloadFile(dataRequestId: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("dataRequestId", dataRequestId.toString());

    return this.http.get(`${this.settingsService.settings.apiUrl}/Reporting/DownloadExport`, {
      responseType: 'arraybuffer',
      params: params
    });
  }

  getAvailableDashboards(groupId: number): Observable<Array<IDashboard>> {
    var params = new HttpParams();
    params = params.append("group_Id", groupId.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/GetAvailableDashboards`, {
      params: params
    });
  }

  getAvailableDashboardGroups(client_id: number): Observable<Array<IGroup>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/GetAvailableDashboardGroups`, {
      params: params
    });
  }

  getAccessibleClientList(): Observable<any> {
    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Reporting/GetAccessibleClientList`);
  }
}

export interface IDashboard {
  dashboard_id: number;
  dashboard_name: string;
  user_id: number;
}

export interface IGroup {
  dashboard_group_id: number;
  dashboard_group_name: string;
  is_multi_client: boolean;
}

