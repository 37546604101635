import { Router } from '@angular/router';
import { debug } from 'console';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SettingsService } from '../../shared/services/settings.service';
import { SignalrService } from '../../shared/services/signalr.service';

export function appInitializer(
    authenticationService: AuthenticationService,
    settingsService: SettingsService) {
    return () => new Promise(resolve => {
        settingsService.setupSettings().then(() => {
            // attempt to refresh token on app start up to auto authenticate  
            if (localStorage.getItem("refreshToken") != null &&
                localStorage.getItem('_UdSM') != null) {
                authenticationService.refreshExpiredAccessToken()
                    .subscribe()
                    .add(resolve);
            } else {
                if (//!window.location.href.includes("auth/access?access-token") &&
                    !window.location.href.includes("screenshot") &&
                    !window.location.href.includes("auth/ota")) {
                        authenticationService.logout();
                }
                resolve(() => {});
            }
        });
    });
}