import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AnyAaaaRecord } from "dns";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { isBuffer } from "util";
import { environment } from "../../../environments/environment";
import { app_session_registrationProcess_IU_name, app_session_registrationProcess_name } from "../constants/app.constant";
import { BusinessAmbitionRequest } from "../models/requests/business-ambition.request";
import { MeasureRequest } from "../models/requests/measure.request";
import { NudgeRequest, WeeklyPlanRequest } from "../models/requests/nudge.request";
import { Profile } from "../models/requests/profile.request";
import { SprintGoalReqest } from "../models/requests/sprint-goal.request";
import { BusinessSprintResponse } from "../models/responses/business-sprint.response";
import { BusinessAmbition } from "../models/responses/businessAmbition.response";
import { ComboResponse } from "../models/responses/combos.response";
import { GoalComboResponse } from "../models/responses/goal-combo.response";
import { LoginResponse } from "../models/responses/login.response";
import { MainMenu } from "../models/responses/main-menu-response";
import { MeasuresResponse } from "../models/responses/measures.response";
import { NotificationPreferenceResponse } from "../models/responses/notification-preference.response";
import { NudgeDetail } from "../models/responses/nudge-detail.response";
import { ScheduleTimeResponse } from "../models/responses/schedule-time.response";
import { SprintDetailWithParticipants } from "../models/responses/sprint-detail.response";
import { SprintGoalNudgeResponse } from "../models/responses/sprint-goal-nudge.response";
import { SprintGoalResponse } from "../models/responses/sprint-goal.response";
import { SprintNudgeResponse } from "../models/responses/sprint-nudge.response";
import { SprintUserResponse } from "../models/responses/sprint-user.response";
import { ISubMenu } from "../models/responses/sub-menu.response";
import { TeamSprintGoalResponse } from "../models/responses/team-sprint-goal.response";

import { SessionModel } from "../models/session.model";
import { DecryptString, EncryptString } from "../utils/encrypation.util";
import { AuthenticationService } from "./authentication.service";
import { SettingsService } from "./settings.service";
import { Time } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class SetupService {
  private currentComboSubject: BehaviorSubject<ComboResponse[]>;
  private currentUser: SessionModel;
  public currentActiveForm: BehaviorSubject<{ BA: BusinessAmbition, SP: BusinessSprintResponse, SPG: SprintGoalResponse[] }>;
  public registraionProcess$: BehaviorSubject<{ BA: number, SP: number, SPG: number[] }>;
  public currentActiveFormIU: BehaviorSubject<{ SN: SprintNudgeResponse[], ML: MeasuresResponse[] }>;
  public registraionProcessIU$: BehaviorSubject<{ SN: number[], ML: number[] }>;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private settingsService: SettingsService) {

    this.currentComboSubject = new BehaviorSubject<ComboResponse[]>([]);
    this.currentUser = this.authService.currentUserValue;
    this.currentActiveForm = new BehaviorSubject<{ BA: BusinessAmbition, SP: BusinessSprintResponse, SPG: SprintGoalResponse[] }>({ BA: null, SP: null, SPG: [] })
    this.registraionProcess$ = new BehaviorSubject<{ BA: number, SP: number, SPG: number[] }>(DecryptString(localStorage.getItem(app_session_registrationProcess_name)));
    this.currentActiveFormIU = new BehaviorSubject<{ SN: SprintNudgeResponse[], ML: MeasuresResponse[] }>({ SN: [], ML: [] })
    this.registraionProcessIU$ = new BehaviorSubject<{ SN: number[], ML: number[] }>(DecryptString(localStorage.getItem(app_session_registrationProcess_IU_name)));
  }

  private getComboList() {
    if (this.currentComboSubject.value.length > 0)
      return of(this.currentComboSubject.value);
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/nudge/getSetupComboList`)
      .pipe(map(results => {
        this.currentComboSubject.next(results);
        return results;
      }));
  }

  setRegistrationProcess(data: { BA: number; SP: number; SPG: number[] }) {
    localStorage.setItem(app_session_registrationProcess_name, EncryptString(JSON.stringify(data)));
    this.registraionProcess$.next(data);
  }

  setRegistrationProcessIU(data: { SN: number[], ML: number[] }) {
    localStorage.setItem(app_session_registrationProcess_IU_name, EncryptString(JSON.stringify(data)));
    this.registraionProcessIU$.next(data);
  }

  getAreaComboList() {
    return this.getComboList().pipe(map(results => results.filter(x => x.type === "AREA")));
  }
  getCategoryComboList() {
    // return this.getComboList().pipe(map(results => results.filter(x => x.type === "CATEGORY")));
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Nudge/GetNudgeCategoryComboList`);
  }
  getQuestionComboList() {
    return this.getComboList().pipe(map(results => results.filter(x => x.type === "QUESTION")));
  }
  getRoleComboList() {
    return this.getComboList().pipe(map(results => results.filter(x => x.type === "ROLE")));
  }
  getScheduleComboList() {
    return this.getComboList().pipe(map(results => results.filter(x => x.type === "SCHEDULE")));
  }
  getCoachComboList() {
    return this.getCoachAndManagerList().pipe(map(results => results.filter(x => x.type === "COACHES")));
  }
  getManagerComboList() {
    return this.getCoachAndManagerList().pipe(map(results => results.filter(x => x.type === "MANAGER")));
  }

  getGoalComboList() {
    return this.http.get<GoalComboResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintGoalForNudge?user_program_id=${this.currentUser.User_Program_ID}&program_id=${this.currentUser.Program_ID}`)
  }

  //Business ambition's API
  getBusinessAmbitions() {
    return this.http.get<BusinessAmbition[]>(`${this.settingsService.settings.apiUrl}/Setup/GetBusinessAmbitionsListByUserId?user_id=${this.currentUser.User_ID}`)
  }
  saveAndUpdateBusinessAmbition(data: BusinessAmbitionRequest) {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateBusinessAmbition`, data);
  }
  getBusinessAmbitionsCoachList() {
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetBusinessAmbitionsCoachList?client_id=${this.currentUser.Client_ID}`)
  }
  GetParticipants() {
    return this.http.get<SprintUserResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetParticipantsByUserId?userid=${this.currentUser.User_ID}`)
  }

  getSprintById(sprint_id) {
    return this.http.get<SprintDetailWithParticipants>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintDetailById?program_id=${sprint_id}`)
  }

  //Business Sprint
  getBusinessSprint(projectId) {
    return this.http.get<BusinessSprintResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintByProjectId?project_id=${projectId}`);
  }
  saveAndUpdateSprint(data: SaveSprint) {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateSprint`, data);
  }

  getSprintsByProjectId(projectId: number) {
    return this.http.get<BusinessAmbition[]>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintByProjectId?project_id=${projectId}`)
  }

  //Sprint goal
  getSprintGoals(projectId, programId, userProgramId) {
    return this.http.get<SprintGoalResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintGoalsBySprintId?project_id=${projectId}&program_id=${programId}&user_program_id=${userProgramId}`)
  }

  getMeasuresByProgramId(programId = 0) {
    var prgID = programId === 0 ? this.currentUser.Program_ID : programId;
    return this.http.get<MeasuresResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetMeasuresListByProgramId?program_id=${prgID}`)
  }
  getKpiLibrarryList(kpi_area_id) {
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetKPILibrarybyAreaId?KPI_Area_id=${kpi_area_id}`)
  }

  // saveAndUpdateSprintGoal(data: SprintGoalReqest) {
  //   return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateSprintGoal`, data);
  // }

  saveAndUpdateMeasures(data: MeasureRequest) {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateMeasures`, data);
  }

  saveAndUpdateMeasuresList(data: MeasureRequest[]) {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateMeasuresList`, data);
  }

  getNudges() {
    return this.http.get<SprintNudgeResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetNudgeList?userprogramid=${this.currentUser.User_Program_ID}`)
  }

  nudgeListSaveUpdate(data: NudgeRequest): any {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateNudge`, data);
  }

  nudgeDelete(id: number) {
    return this.http.delete<number>(`${this.settingsService.settings.apiUrl}/Setup/DeleteNudge?useractionid=${id}`);
  }

  getWeeklyNudgesData(firstday, lastday) {
    return this.http.get<any[]>(`${this.settingsService.settings.apiUrl}/Setup/GetWeeklyNudgeData?user_program_id=${this.currentUser.User_Program_ID}&user_id=${this.currentUser.User_ID}&fromdate=${firstday}&todate=${lastday}`)
  }

  getUserProfile(id: number = 0) {
    let userid = id === 0 ? this.currentUser.User_ID : id;
    return this.http.post<Profile>(`${this.settingsService.settings.apiUrl}/setup/getUserDetailbyId?user_id=${userid}`, {});
  }

  updateProfile(params: Profile) {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/setup/saveAndUpdateUserProfile`, params.user_profile);
  }

  updateTaskStatus() {
    return this.http.get<LoginResponse>(`${this.settingsService.settings.apiUrl}/setup/updateTaskStatus?user_id=${this.currentUser.User_ID}&due_date=${this.authService.currentTaskValue.due_date}&task_type_id=${this.authService.currentTaskValue.task_type_id}&status_id=${this.authService.currentTaskValue.status_id}`)
      .pipe(map(results => {
        if (results.nextTask && results.sprint) {
          this.authService.updateSprintAndTask(results.sprint, results.nextTask);
          this.setRegistrationProcessIU(null);
          this.setRegistrationProcess(null);
        }
        return results;
      }));
  }

  getYourTeamSprintDetails() {
    return this.http.get<TeamSprintGoalResponse>(`${this.settingsService.settings.apiUrl}/Setup/GetYourTeamSprintDetails?program_id=${this.currentUser.Program_ID}`);
  }

  getScheduleTime() {
    return this.http.get<Array<ScheduleTimeResponse>>(`${this.settingsService.settings.apiUrl}/Nudge/GetScheduleList`);
  }

  getNotificationTimeList() {
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Load/GetNotificationTimeList`);
  }
  getNotificationPreferencebyUserId() {
    return this.http.get<NotificationPreferenceResponse>(`${this.settingsService.settings.apiUrl}/Load/GetNotificationPreferencebyUserId?user_id=${this.currentUser.User_ID}`);
  }
  updateNotificationTime(before_id: number, after_id: number, sendEmailNotifications: boolean, sendMobileNotifications: boolean) {
    return this.http.get<Profile>(`${this.settingsService.settings.apiUrl}/load/UpdateNotificationTime?user_id=${this.currentUser.User_ID}&before_id=${before_id}&after_id=${after_id}&sendEmails=${sendEmailNotifications}&sendMobile=${sendMobileNotifications}`);
  }

  getCoachAndManagerList() {
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetManagerAndCoachList?client_id=${this.currentUser.Client_ID}`);
  }

  getCoachAndManagerListForClient(client_id: number) {
    return this.http.get<ComboResponse[]>(`${this.settingsService.settings.apiUrl}/Setup/GetManagerAndCoachList?client_id=${client_id}`);
  }

  getFallenBehindStatus(): Observable<any> {
    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Setup/GetIsUserUptodate`);
  }

  updateFallenBehind(userId: number, userProgramId: number, wasOOO: boolean): Observable<any> {
    var params = new HttpParams();
    params = params.append("user_id", userId.toString());
    params = params.append("user_program_id", userProgramId.toString());
    params = params.append("wasOOO", wasOOO.toString());

    return this.http.get<any>(`${this.settingsService.settings.apiUrl}/Setup/UptoDateUser`, {
      params: params
    });
  }

  updateFallenBehindDate(userId: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("user_id", userId.toString());

    return this.http.post<any>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateUserLastCheck`, {}, {
      params: params
    });
  }

  GetNudgeById(userActionId: number): Observable<NudgeDetail> {
    return this.http.get<NudgeDetail>(`${this.settingsService.settings.apiUrl}/Setup/GetNudgeById?user_action_id=` + userActionId.toString());
  }

  GetMainMenus(): Observable<Array<MainMenu>> {
    return this.http.get<Array<MainMenu>>(`${this.settingsService.settings.apiUrl}/Setup/GetAvailableMenuTabs`);
  }

  GetSubMenus(menu_id: number, program_id: number): Observable<Array<ISubMenu>> {
    var params = new HttpParams();
    params = params.append("menu_id", menu_id.toString());
    params = params.append("program_id", program_id.toString());

    return this.http.get<Array<ISubMenu>>(`${this.settingsService.settings.apiUrl}/Setup/GetAvailableSubMenuTabs`, {
      params: params
    });
  }

  //Company Setup
  public getCompanyList(): Observable<Array<Company>> {
    return this.http.get<Array<Company>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientList`);
  }

  public getAllClientsList(): Observable<Array<BasicCompany>> {
    return this.http.get<Array<BasicCompany>>(`${this.settingsService.settings.apiUrl}/Setup/GetAllClientsList`);
  }

  public getBIGUserList(): Observable<Array<SystemUser>> {
    return this.http.get<Array<SystemUser>>(`${this.settingsService.settings.apiUrl}/Setup/GetBIGUserList`);
  }

  public getClient(clientId: number): Observable<Company> {
    var params = new HttpParams();
    params = params.append("client_id", clientId.toString());

    return this.http.get<Company>(`${this.settingsService.settings.apiUrl}/Setup/GetClient`, {
      params: params
    });
  }

  public getClientPrograms(clientId: number): Observable<Array<ClientProgram>> {
    var params = new HttpParams();
    params = params.append("client_id", clientId.toString());

    return this.http.get<Array<ClientProgram>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientPrograms`, {
      params: params
    });
  }

  public saveAndUpdateClient(company: Company): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClient`, company);
  }

  public getClientVision(clientId: number): Observable<CompanyVision> {
    var params = new HttpParams();
    params = params.append("client_id", clientId.toString());

    return this.http.get<CompanyVision>(`${this.settingsService.settings.apiUrl}/Setup/GetClientVision`, {
      params: params
    });
  }

  public saveAndUpdateClientVision(vision: CompanyVision): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClientVision`, vision);
  }

  public getSuggestedNudgeList(value_category_id: number): Observable<Array<SuggestedNudgeforValueCategory>> {
    var params = new HttpParams();
    params = params.append("value_category_id", value_category_id.toString());

    return this.http.get<Array<SuggestedNudgeforValueCategory>>(`${this.settingsService.settings.apiUrl}/Setup/GetSuggestedNudgeList`, {
      params: params
    });
  }

  public getSuggestedNudgeListforClientValue(client_value_id: number): Observable<Array<SuggestedNudgeforClientValue>> {
    var params = new HttpParams();
    params = params.append("client_value_id", client_value_id.toString());

    return this.http.get<Array<SuggestedNudgeforClientValue>>(`${this.settingsService.settings.apiUrl}/Setup/GetSuggestedNudgeListforClientValue`, {
      params: params
    });
  }

  public getSprintTemplateSuggestedNudges(sprintTemplateId: number): Observable<Array<SuggestedNudge>> {
    var params = new HttpParams();
    params = params.append("sprint_template_id", sprintTemplateId.toString());

    return this.http.get<Array<SuggestedNudge>>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintTemplateSuggestedNudges`, {
      params: params
    });
  }

  public getClientLicenceList(client_id: number): Observable<ClientLicencesWithCount> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<ClientLicencesWithCount>(`${this.settingsService.settings.apiUrl}/Setup/GetClientLicenceList`, {
      params: params
    });
  }

  public getCountryCodeList(): Observable<Array<CountryCode>> {
    return this.http.get<Array<CountryCode>>(`${this.settingsService.settings.apiUrl}/Load/GetCountryCodeList`);
  }

  public getLicenceVolumeList(): Observable<Array<LicenceVolume>> {
    return this.http.get<Array<LicenceVolume>>(`${this.settingsService.settings.apiUrl}/Load/GetLicenceVolumeList`);
  }

  public getLicenceTypeList(): Observable<Array<LicenceType>> {
    return this.http.get<Array<LicenceType>>(`${this.settingsService.settings.apiUrl}/Load/GetLicenceTypeList`);
  }

  public getContractLengthList(): Observable<Array<ContractLength>> {
    return this.http.get<Array<ContractLength>>(`${this.settingsService.settings.apiUrl}/Load/GetContractLengthList`);
  }

  public saveAndUpdateClientLicenceList(licences: Array<SaveClientLicence>): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClientLicenceList`, licences);
  }

  public saveAndUpdateClientContact(clientContact: ClientContact): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClientContact`, clientContact);
  }

  public getBIGImplementationRoles(): Observable<Array<ImplementationRole>> {
    return this.http.get<Array<ImplementationRole>>(`${this.settingsService.settings.apiUrl}/Setup/GetBIGImplementationRoles`);
  }

  public getBIGImplementationUsersForClient(clientId: number): Observable<Array<ClientContactList>> {
    var params = new HttpParams();
    params = params.append("client_id", clientId.toString());

    return this.http.get<Array<ClientContactList>>(`${this.settingsService.settings.apiUrl}/Setup/GetBIGImplementationUsersForClient`, {
      params: params
    });
  }

  public addUserToClientContacts(contact: AddToClientContacts): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/AddUserToClientContacts`, contact);
  }

  public deleteClientContact(contactId: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("client_contact_id", contactId.toString());

    return this.http.delete(`${this.settingsService.settings.apiUrl}/Setup/DeleteClientContact`, {
      params: params
    });
  }

  public getClientImplementationRoles(): Observable<Array<ImplementationRole>> {
    return this.http.get<Array<ImplementationRole>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientImplementationRoles`);
  }

  public getRoleTypes(): Observable<Array<RoleType>> {
    return this.http.get<Array<RoleType>>(`${this.settingsService.settings.apiUrl}/Setup/GetRoleTypes`);
  }

  public getClientImplementationUsersForClient(client_id: number): Observable<Array<ClientContactList>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<Array<ClientContactList>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientImplementationUsersForClient`, {
      params: params
    });
  }

  public getClientGoalList(client_id: number): Observable<Array<ClientGoalList>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<Array<ClientGoalList>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientGoalList`, {
      params: params
    });
  }

  public saveAndUpdateClientGoals(goal: SaveClientGoal): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClientGoals`, goal);
  }

  public getClientGoal(client_goal_id: number): Observable<ClientGoal> {
    var params = new HttpParams();
    params = params.append("client_goal_id", client_goal_id.toString());

    return this.http.get<ClientGoal>(`${this.settingsService.settings.apiUrl}/Setup/GetClientGoal`, {
      params: params
    });
  }

  public getClientGoalOutcomeList(client_goal_id: number): Observable<Array<ClientGoalOutcomeList>> {
    var params = new HttpParams();
    params = params.append("client_goal_id", client_goal_id.toString());

    return this.http.get<Array<ClientGoalOutcomeList>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientGoalOutcomeList`, {
      params: params
    });
  }

  public SaveAndUpdateClientGoalOutcomes(goalOutcomes: Array<SaveClientGoalOutcome>): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClientGoalOutcomes`, goalOutcomes);
  }

  public getClientGoalOutcome(client_goal_outcome_id: number): Observable<ClientGoalOutcome> {
    var params = new HttpParams();
    params = params.append("client_goal_outcome_id", client_goal_outcome_id.toString());

    return this.http.get<ClientGoalOutcome>(`${this.settingsService.settings.apiUrl}/Setup/GetClientGoalOutcome`, {
      params: params
    });
  }

  public getMeasuresLibraryList(KPI_area_id: number, SearchString: string): Observable<Array<MeasureLibrary>> {
    var params = new HttpParams();
    params = params.append("KPI_area_id", KPI_area_id.toString());
    if (SearchString)
      params = params.append("SearchString", SearchString.toString());

    return this.http.get<Array<MeasureLibrary>>(`${this.settingsService.settings.apiUrl}/Setup/GetMeasuresLibraryList`, {
      params: params
    });
  }

  public getMeasuresAreasList(): Observable<Array<MeasuresArea>> {
    return this.http.get<Array<MeasuresArea>>(`${this.settingsService.settings.apiUrl}/Setup/GetMeasuresAreasList`);
  }

  public getClientValueList(client_id: number): Observable<Array<ClientValue>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<Array<ClientValue>>(`${this.settingsService.settings.apiUrl}/Setup/GetClientValueList`,{
      params: params
    });
  }

  public saveAndUpdateClientValueList(clientValue: Array<SaveClientValue>): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateClientValueList`, clientValue);
  }

  public getValueCategoryList(): Observable<Array<ValueCategory>> {
    return this.http.get<Array<ValueCategory>>(`${this.settingsService.settings.apiUrl}/Setup/GetValueCategoryList`);
  }

  public getIconList(client_id: number, icon_category_id: number): Observable<Array<Icon>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());
    params = params.append("icon_category_id", icon_category_id.toString());

    return this.http.get<Array<Icon>>(`${this.settingsService.settings.apiUrl}/Load/GetIconList`,{
      params: params
    });
  }

  public getIcon(client_id: number, icon_id: number): Observable<Icon> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());
    params = params.append("icon_id", icon_id.toString());

    return this.http.get<Icon>(`${this.settingsService.settings.apiUrl}/Load/GetIcon`,{
      params: params
    });
  }

  public saveAndUpdateIcon(icon: SaveIcon): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Load/SaveAndUpdateIcon`, icon);
  }

  public deleteClientValue(client_value_id: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("client_value_id", client_value_id.toString());

    return this.http.delete<any>(`${this.settingsService.settings.apiUrl}/Setup/DeleteClientValue`,{
      params: params
    });
  }

  public saveAndUpdateNudgeforClientValue(suggestedNudges: SaveNudgeforClientValue[]): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateNudgeforClientValue`, suggestedNudges);
  }

  public deleteNudgeforClientValue(client_value_nudge_id: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("client_value_nudge_id", client_value_nudge_id.toString());

    return this.http.delete<any>(`${this.settingsService.settings.apiUrl}/Setup/DeleteNudgeforClientValue`,{
      params: params
    });
  }

  public getOrgUnitList(client_id: number, parent_org_unit_id: number): Observable<Array<OrgUnit>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());
    params = params.append("parent_org_unit_id", parent_org_unit_id.toString());

    return this.http.get<Array<OrgUnit>>(`${this.settingsService.settings.apiUrl}/Load/GetOrgUnitList`,{
      params: params
    });
  }

  public saveAndUpdateOrgUnit(orgUnit: UpdateOrgUnit): Observable<number> {
    return this.http.post<number>(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateOrgUnit`, orgUnit);
  }

  public sprintList(client_id: number, sprint_manager_id: number, is_active: boolean, filter_strategic_goal_id: number): Observable<Array<SprintListItem>> {
    var params = new HttpParams();
    params = params.append("sprint_manager_id", sprint_manager_id.toString());
    params = params.append("client_id", client_id.toString());
    params = params.append("is_active", is_active.toString());
    params = params.append("filter_strategic_goal_id", filter_strategic_goal_id.toString());

    return this.http.get<Array<SprintListItem>>(`${this.settingsService.settings.apiUrl}/Setup/SprintList`,{
      params: params
    });
  }

  public strategicGoalChoices(client_id: number): Observable<Array<ListItem>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<Array<ListItem>>(`${this.settingsService.settings.apiUrl}/Setup/StrategicGoalChoices`, {
      params: params
    });
  }

  public sprintOwnerChoices(client_id: number): Observable<Array<ListItem>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<Array<ListItem>>(`${this.settingsService.settings.apiUrl}/Setup/SprintOwnerChoices`, {
      params: params
    });
  }

  public getUserListWithOrg(client_id: number, program_id: number, list_type: string): Observable<Array<UserWithOrgUnitItem>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());
    params = params.append("program_id", program_id.toString());
    params = params.append("list_type", list_type);

    return this.http.get<Array<UserWithOrgUnitItem>>(`${this.settingsService.settings.apiUrl}/Setup/GetUserListWithOrg`,{
      params: params
    });
  }

  public saveAndUpdateUserDiary(userDiaryEvents: UpdateUserDiary[]): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateUserDiary`, userDiaryEvents);
  }

  public deleteSprint(programid: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("programid", programid.toString());

    return this.http.delete(`${this.settingsService.settings.apiUrl}/Setup/DeleteSprint`, {
      params: params
    });
  }

  public getSprintSetup(program_id: number): Observable<SprintDetail> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());

    return this.http.get<SprintDetail>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintSetup`,{
      params: params
    });
  }

  public getSprintParticipantAvailabilityList(client_id: number, filter_business_unit_id: number, filter_manager_user_id: number, program_id: number): Observable<Array<SprintParticipantAvailability>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());
    params = params.append("filter_business_unit_id", filter_business_unit_id.toString());
    params = params.append("filter_manager_user_id", filter_manager_user_id.toString());
    params = params.append("program_id", program_id.toString());

    return this.http.get<Array<SprintParticipantAvailability>>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintParticipantAvailabilityList`,{
      params: params
    });
  }

  public saveAndUpdateSprintParticipants(program_id: number, sprintParticipants: SaveSprintParticipant[]): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateSprintParticipants`, {
      program_id : program_id,
      sprintparticipantsmodel: sprintParticipants
    });
  }

  public getSprintGoalList(program_id: number): Observable<SetupSprintGoal> {
    var params = new HttpParams();
    params = params.append("programId", program_id.toString());

    return this.http.get<SetupSprintGoal>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintGoalList`,{
      params: params
    });
  }

  public saveAndUpdateSprintGoal(sprintGoal: SaveSprintGoal): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateSprintGoal`, sprintGoal);
  }

  public getParticipantCoachingStatus(program_id: number): Observable<CoachingStatusForSprint> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());

    return this.http.get<CoachingStatusForSprint>(`${this.settingsService.settings.apiUrl}/Setup/GetParticipantCoachingStatus`,{
      params: params
    });
  }

  public GetManagerAndCoachList(client_id: number): Observable<Array<ListItem>> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<Array<ListItem>>(`${this.settingsService.settings.apiUrl}/Setup/GetManagerAndCoachList`,{
      params: params
    });
  }

  public GetManagerAndCoachesList(client_id: number): Observable<ManagerAndCoachLists> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<ManagerAndCoachLists>(`${this.settingsService.settings.apiUrl}/Setup/GetManagerAndCoachesList`,{
      params: params
    });
  }

  public saveAndUpdateParticipantsCoachingStatus(coachSettings: SaveCoachingStatusForSprint): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateParticipantsCoachingStatus`, coachSettings);
  }

  public getSprintSetupStatus(program_id: number): Observable<SprintSetupStatus> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());

    return this.http.get<SprintSetupStatus>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintSetupStatus`,{
      params: params
    });
  }

  public getIndustryList(): Observable<Array<ListItem>> {
    return this.http.get<Array<ListItem>>(`${this.settingsService.settings.apiUrl}/Load/GetIndustryList`);
  }

  public removeUserFromSprint(user_program_id: number): Observable<boolean> {
    var params = new HttpParams();
    params = params.append("userprogramid", user_program_id.toString());

    return this.http.put<boolean>(`${this.settingsService.settings.apiUrl}/Setup/RemoveUserFromSprint`,null, {
      params: params
    });
  }

  public deleteClientGoal(client_goal_id: number) {
    var params = new HttpParams();
    params = params.append("client_goal_id", client_goal_id.toString());

    return this.http.delete<number>(`${this.settingsService.settings.apiUrl}/Setup/DeleteClientGoal`,{
      params: params
    });
  }

  public deleteClientGoalOutcome(client_goal_outcome_id: number) {
    var params = new HttpParams();
    params = params.append("client_goal_outcome_id", client_goal_outcome_id.toString());

    return this.http.delete<number>(`${this.settingsService.settings.apiUrl}/Setup/ClientGoalOutcome`,{
      params: params
    });
  }


  public GetEmailTemplates(): Observable<Array<EmailTemplate>> {
    return this.http.get<Array<EmailTemplate>>(`${this.settingsService.settings.apiUrl}/Setup/GetEmailTemplates`);
  }

  public getSprintCommunicationPlan(program_id: number): Observable<Array<SprintCommunicationPlan>> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());

    return this.http.get<Array<SprintCommunicationPlan>>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintCommunicationPlan`,{
      params: params
    });
  }

  public getSprintCommunicationPlanRecipients(program_id: number, program_email_schedule_id: number): Observable<Array<Recipient>> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());
    params = params.append("program_email_schedule_id", program_email_schedule_id.toString());

    return this.http.get<Array<Recipient>>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintCommunicationPlanRecipients`,{
      params: params
    });
  }

  public saveAndUpdateCommunicationPlanWithRecipients(sprintCommsPlan: SaveSprintCommunicationPlanWithRecipientList): Observable<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/Setup/SaveAndUpdateCommunicationPlanWithRecipients`, sprintCommsPlan);
  }

  public getSprintReadonly(program_id: number, client_id: number): Observable<boolean> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());
    params = params.append("client_id", client_id.toString());

    return this.http.get<boolean>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintReadonly`,{
      params: params
    });
  }

  public deleteCommunicationPlan(program_email_schedule_id: number): Observable<any> {
    var params = new HttpParams();
    params = params.append("program_email_schedule_id", program_email_schedule_id.toString());

    return this.http.delete<number>(`${this.settingsService.settings.apiUrl}/Setup/DeleteCommunicationPlan`,{
      params: params
    });
  }

  public getShowExitPopups(program_id: number): Observable<boolean> {
    var params = new HttpParams();
    params = params.append("program_id", program_id.toString());

    return this.http.get<boolean>(`${this.settingsService.settings.apiUrl}/Setup/GetShowExitPopups`,{
      params: params
    });
  }

  public doesClientExist(client_id: number): Observable<boolean> {
    var params = new HttpParams();
    params = params.append("client_id", client_id.toString());

    return this.http.get<boolean>(`${this.settingsService.settings.apiUrl}/Setup/DoesClientExist`,{
      params: params
    });
  }

  public getSprintTemplates(is_team_sprint: boolean, isTraining: boolean): Observable<Array<SprintTemplate>> {
    return this.http.get<Array<SprintTemplate>>(`${this.settingsService.settings.apiUrl}/Setup/GetSprintTemplates?is_team_sprint=${is_team_sprint}&isTraining=${isTraining}`);
  }
}

export class SubMenu implements ISubMenu {
  constructor(data: ISubMenu, private domSanitizer: DomSanitizer) {
    Object.assign(this, data);
    this.dashboardOptions = new DashboardOptions();
    this.dashboardOptions.coached_user_id = this.user_id;
  }

  public sub_menu_id: number;
  public sub_menu_name: string;
  public icon: string;
  public user_id: number;
  public dashboardOptions: DashboardOptions;

  public getIconUrl(): any {
    return this.domSanitizer.bypassSecurityTrustUrl("data:image/svg+xml;base64," + this.icon);
  }
}

export class DashboardOptions {
  public isCurrent: boolean = false;
  public selectedClient: number = 0;
  public coached_user_id: number = 0;
  public program_id: number= 0;
}

export class BasicCompany {
  public client_id: number;
  public client_name: string;
}

export class Company {
  public client_id: number;
  public client_name: string;
  public is_active: boolean = true;
  public contract_start_date: Date;
  public contract_length_id: number = 0;
  public contract_length: number;
  public contract_end_date: Date;
  public logo: string;
  public billing_address_line_1: string;
  public billing_address_line_2: string;
  public billing_address_line_3: string;
  public billing_city: string;
  public billing_county: string;
  public billing_post_code: string;
  public company_address_line_1: string;
  public company_address_line_2: string;
  public company_address_line_3: string;
  public company_city: string;
  public company_county: string;
  public company_post_code: string;
  public reg_number: string;
  public vat_number: string;
  public finance_account_no: string;
  public crM_account_no: string;
  public has_same_billing_address: boolean = true;
  public update_by_user_id: number;
  public approved_user_licences: number;
  public employee_count: number;
  public domain: string;
  public phone: string;
  public about: string;
  public industry_id: number;
}

export class SystemUser {
  public user_id: number;
  public full_name: string;
}

export class CompanyVision {
  public client_id: number;
  public company_vision: string;
  public company_mission: string;
  public company_vision_icon: string;
  public company_vision_icon_id: number;
  public company_mission_icon: string;
  public company_mission_icon_id: number;
}

export class SuggestedNudgeforValueCategory {
  public value_category_id: number;
  public nudge_name: string;
  public nudge_description: string;
  public icon: string;
  public category_id: number;
  public category_name: number;
}

export class SuggestedNudgeforClientValue {
  public client_value_nudge_id: number;
  public client_value_id: number;
  public nudge_name: string;
  public order_no: number;
}

export class ClientLicencesWithCount {
constructor() {
  this.clientLicenceList = new Array();
  this.clientLicenceSummary = new ClientLicenceSummary();
}

  public clientLicenceList: Array<ClientLicence>;
  public clientLicenceSummary: ClientLicenceSummary;
}

export class ClientLicence {
  public client_licence_id: number;
  public licence_type_id: number;
  public licence_name: string;
  public licence_count: number;
  public current_count: number = 0;
  public date_added: Date;
  public status: string;
}

export class ClientLicenceSummary {
  public active_licences: number;
  public inactive_licences: number;
  public requested_licences: number;
}

export class CountryCode {
  public value: number;
  public text: string;
}

export class LicenceVolume {
  public licence_volume_id: number;
  public licence_volume_name: string;
  public volume_count: number;
}

export class LicenceType {
  public licence_type_id: number;
  public licence_name: string;
}

export class ContractLength {
  public contract_length_id: number;
  public contract_length_name: string;
  public contract_length: number;
}

export class SaveClientLicence {
  public client_licence_id: number;
  public client_id: number;
  public licence_type_id: number;
  public licence_count: number;
  public licence_volume_id: number;
  public is_requested: boolean;
  public is_active: boolean;
}

export class ClientContact {
  public user_id: number;
  public client_contact_id: number;
  public client_id: number;
  public first_name: string;
  public full_name: string;
  public contact_no: string;
  public email_address: string;
  public implementation_resource_type_id: number;
  public previous_implementation_resource_type_id: number;
  public role_id: number;
  public is_primary: boolean;
}

export class ImplementationRole {
  public implementation_resource_type_id: number;
  public resource_name: string;
}

export class ClientContactList {
  public client_contact_id: number;
  public client_id: number;
  public implementation_resource_type_id: number;
  public user_id: number;
  public full_name: string;
  public job_title: string;
  public contact_no: string;
  public email_address: string;
  public resource_name: string;
  public first_name: string;
  public role_id: number;
  public is_primary: boolean;
}

export class AddToClientContacts {
  public client_contact_id: number;
  public client_id: number;
  public implementation_resource_type_id: number;
  public previous_implementation_resource_type_id: number;
  public user_id: number;
  public is_active: boolean;
  public is_primary: boolean;
}

export class RoleType {
  public role_id: number;
  public role_name: string;
  public licence_name: string;
}

export class ClientGoalList {
  public client_goal_id: number;
  public goal_name: string;
  public goal_short_description: string;
  public icon: string;
  public order_no: number;
  public is_active: boolean;
}

export class SaveClientGoal {
  public client_goal_id: number;
  public client_id: number;
  public goal_name: string;
  public goal_description: string;
  public goal_short_description: string;
  public icon_id: number;
  public icon: string;
  public order_no: number;
  public is_active: boolean;
}

export class ClientGoal {
  public client_goal_id: number;
  public client_id: number;
  public goal_name: string;
  public goal_short_description: string;
  public goal_description: string;
  public icon_id: number;
  public icon: string;
  public is_active: boolean;
  public order_no: number;
}

export class ClientGoalOutcomeList
{
  public client_goal_outcome_id: number;
  public client_goal_id: number;
  public outcome_name: string;
  public from_value: number;
  public to_value: number;
  public prefix: string;
  public suffix: string;
  public end_date: Date;
  public actual_value: number;
  public progress_pc: number;
  public kpI_library_id: number;
  public kpI_name: string;
  public is_active: boolean;
  public order_no: number;
  public start_date: Date;
  public is_confidential: boolean;
}

export class SaveClientGoalOutcome {
  public client_goal_outcome_id: number = 0;
  public client_goal_id: number;
  public kpI_library_id: number;
  public kpI_library_name: string;
  public outcome_name: string;
  public from_value: number;
  public to_value: number;
  public start_date: Date;
  public end_date: Date;
  public order_no: number = 0;
  public is_active: boolean;
  public is_confidential: boolean;
  public prefix: string;
  public suffix: string;
}

export class ClientGoalOutcome {
  public client_goal_outcome_id: number;
  public client_goal_id: number;
  public outcome_name: string;
  public from_value: number;
  public to_value: number;
  public start_date: Date;
  public end_date: Date;
  public kpI_library_id: number;
  public kpI_name: string;
  public prefix: string;
  public suffix: string;
  public is_active: boolean;
  public order_no: number;
  public is_confidential: boolean;
}

export class MeasureLibrary
{
  public kpI_library_id: number;
  public kpI_name: string;
  public kpI_description: string;
  public kpI_area_id: number;
  public kpI_area_name: string;
  public prefix: string;
  public suffix: string;
}

export class MeasuresArea
{
  public kpI_area_id: number;
  public kpI_area_name: string;
}

export class ClientValue {
  public client_value_id: number;
  public value_title: string;
  public value_sub_title: string;
  public value_description: string;
  public value_category_id: number;
  public value_category_name: string;
  public icon: string;
  public icon_id: number;
}

export class SaveClientValue {
  public client_value_id: number;
  public client_id: number;
  public value_title: string;
  public value_description: string;
  public icon_id: number;
  public is_active: boolean;
}

export class ValueCategory {
  public value_category_id: number;
  public value_category_name: string;
}

export class Icon {
  public icon_id: number;
  public icon: string;
}

export class SaveIcon {
  public client_id: number;
  public icon_id: number;
  public icon: string;
  public icon_category_id: number;
  public is_active: boolean;
}

export class SaveNudgeforClientValue {
  public client_value_nudge_id: number;
  public client_value_id: number;
  public nudge_name: string;
  public order_no: number;
  public is_active: boolean;
}

export class OrgUnit {
  public org_unit_id: number;
  public org_unit_name: string;
}

export class UpdateOrgUnit {
  public org_unit_id: number;
  public org_unit_name: string;
  public client_id: number;
  public parent_org_unit_id: number;
  public org_level: number;
  public is_active: boolean;
}

export class SprintListItem {
  public program_id: number;
  public program_name: string;
  public sprint_focus: string;
  public org_unit: string;
  public sprint_start_date: Date;
  public sprint_end_date: Date;
  public participant_count: number;
  public sprint_status: string;
  public weeks_left: string;
  public sprint_lead_full_name: string;
  public sprint_lead_profile_pic: string;
  public sprint_logo: string;
}

export class ListItem {
  public value: number;
  public text: string;
  public type: string;
}

export class UserWithOrgUnitItem {
  public isSelected: boolean;
  public user_id: number;
  public profile_pic: string;
  public full_name: string;
  public job_title: string;
  public org_unit: string;
}

export class SaveSprint {
  public program_id: number;
  public client_id: number;
  public sprint_focus: string;
  public sprint_name: string;
  public inspiration: string = "";
  public icon_id: number = 0;
  public start_date: Date;
  public end_date: Date;
  public duration: number;
  public lead_user_id: number;
  public is_active: boolean;
  public is_training_sprint: boolean;
  public is_tryout_sprint: boolean;
  public training_date: Date;
  public training_time: Date;
  public sprint_template_id: number;

  public participants: Array<SprintUsers> = new Array();
}

export class SprintUsers {
  public user_id: number;
  public manager_user_id: number;
  public relationship_type_id: number;
}

export class UpdateUserDiary {
  public user_diary_id: number;
  public user_action_id: number;
  public diary_date: Date;
  public start_time: string;
  public end_time: string;
  public global_appointment_ID: string;
}

export class SprintDetail {
  public program_id: number;
  public client_id: number;
  public sprint_focus: string;
  public sprint_name: string;
  public inspiration: string ;
  public icon_id: number;
  public icon: string;
  public start_date: Date;
  public end_date: Date;
  public duration: number;
  public lead_user_id: number;
  public lead_user_full_name: string;
  public is_active: boolean;
  public is_training_sprint: boolean;
  public is_tryout_sprint: boolean;
  public training_date: Date;
  public training_time: Date;
  public sprint_template_id: number;
}

export class SprintParticipantAvailability {
  public user_id: number;
  public full_name: string;
  public job_title: string;
  public profile_pic: string;
  public available_status: string;
  public date_available_from: Date;
  public selected_status: string;
  public org_name: string;
  public is_selectable: boolean;
  public business_unit_id: number;
  public manager_user_id: number;
}

export class SaveSprintParticipant {
  public user_id: number;
  public is_active: boolean;
}

export class SetupSprintGoal {
  public sprint_goal_list: Array<SprintGoal>;

  /* STATIC DATA */
  public company_goal_list: Array<ListItem>;
  public company_value_list: Array<ListItem>;
  public company_key_result_list: Array<CompanyKeyResult>;
  public update_freqency_list: Array<ListItem>;
  public scheduled_time_list: Array<ListItem>;
  public recommended_nudge_list: Array<ClientSuggestedNudge>;
}

export class SprintGoal {
  public sprint_goal_detail: SprintGoalDetails;
  public sprint_nudge_list: Array<SprintNudge>
  public sprint_key_result_list: Array<SprintKeyResult>
}

export class CompanyKeyResult
{
  public client_goal_id: number;
  public client_goal_outcome_id: number;
  public outcome_name: string;
}

export class ClientSuggestedNudge
{
  public client_value_nudge_id: number;
  public client_value_id: number;
  public nudge_name: string;
}

export class SprintGoalDetails
{
  public sprint_focus: number;
  public client_goal_id: number;
  public client_value_id: number;
  public client_goal_outcome_id: number;
  public sprint_goal_id: number;
  public sprint_goal_name: string;
  public sprint_goal_description: string;
  public client_goal_name: string;
  public client_goal_description: string;
  public colour_code: string;
  public icon: string;
}

export class SprintNudge
{
  public program_nudge_id: number;
  public action_name: string;
  public client_value_nudge_id: number;
  public action_description: string;
  public scheduled_time_id: number;
  public order_no: number;
  public key_result_id: number;
}

export class SprintKeyResult
{
  public program_key_result_id: number;
  public KPI_library_id: number;
  public key_result_name: string;
  public key_result_description: string;
  public starting_value: number;
  public target_value: number;
  public other_measure: string;
  public update_freqency_id: number;
}

export class SaveSprintGoal
{
  public program_id: number;
  public sprint_goal_detail: SaveSprintGoalDetails;
  public sprint_nudge_list: Array<SaveSprintNudge>;
  public sprint_key_result_list: Array<SaveSprintKeyResult>;
}

export class SaveSprintGoalDetails {
  public sprint_focus: number;
  public client_goal_id: number;
  public client_value_id: number;
  public client_goal_outcome_id: number;
  public sprint_goal_id: number;
  public sprint_goal_name: string;
  public sprint_goal_description: string;
  public is_active: boolean;
  public program_id: number;
}

export class SaveSprintNudge {
  public program_nudge_id: number;
  public program_goal_id: number;
  public action_name: string;
  public client_value_nudge_id: number;
  public action_description: string;
  public scheduled_time_id: number;
  public order_no: number;
  public is_active: boolean;
}

export class SaveSprintKeyResult {
  public program_key_result_id: number;
  public program_goal_id: number;
  public KPI_library_id: number;
  public key_result_description: string;
  public starting_value: number;
  public target_value: number;
  public other_measure: string;
  public update_freqency_id: number;
  public is_active: boolean;
}

export class ParticipantsCoachingStatus {
  public selected: boolean;
  public user_id: number;
  public user_program_id: number;
  public profile_pic: string;
  public full_name: string;
  public manager_user_id: number;
  public manager_full_name: string;
  public coach_user_id: number;
  public coach_full_name: string;
  public biG_coach_user_id: number;
  public biG_coach_full_name: string;
  public program_peer_group_id: number;
  public peer_group_name: string;
  public team: string;
}

export class ManagerAndCoachLists {
  public managerList: Array<ListItem>;
  public coachList: Array<ListItem>;
  public bigCoachList: Array<ListItem>;
}

export class SaveParticipantsCoachingStatus {
  public user_id: number;
  public user_program_id: number;
  public coach_user_id: number;
  public biG_coach_user_id: number;
  public peer_group_name: string;
}

export class CoachingStatusForSprint {
  public peer_groups: PeerGroupConfig;
  public participants_coaching_status_list: Array<ParticipantsCoachingStatus>;
}

export class PeerGroupConfig {
  public has_peer_groups: boolean;
  public peer_group_count: number;
}

export class SaveCoachingStatusForSprint {
  public peer_groups: SavePeerGroupConfig;
  public participants_coaching_status_list: Array<SaveParticipantsCoachingStatus>;
}

export class SavePeerGroupConfig {
  public program_id: number;
  public has_peer_groups: boolean;
  public peer_group_count: number;
}

export class SprintSetupStatus {
  public has_summary: boolean;
  public has_team: boolean;
  public has_goals: boolean;
  public has_coach: boolean;
  public sprint_start_date: Date;

  public is_active: boolean;
}

export class EmailTemplate {
  public email_template_id: number;
  public template_name: string;
  public template_subject: string;
  public template_body: string;
  public icon: string;
}

export class SprintCommunicationPlan {
  public program_email_schedule_id: number;
  public email_template_id: number;
  public email_template_name: string;
  public icon: string;
  public email_template_description: string;
  public subject: string;
  public body: string;
  public is_everyone: boolean;
  public date_to_send: Date;
  public date_to_sent: Date;
  public is_active: boolean;
  public status: string;
  public recipient_count: number;
}

export class SaveSprintCommunicationPlanWithRecipientList {
  public sprint_communication_plan: SaveSprintCommunicationPlan;
  public receipient_list: Array<SaveRecipient>;
}

export class SaveSprintCommunicationPlan {
  public program_email_schedule_id: number;
  public email_template_id: number;
  public subject: string;
  public body: string;
  public program_id: number;
  public is_everyone: boolean;
  public date_to_send: Date;
  public is_active: boolean;
}

export class SaveRecipient {
  public program_email_schedule_recipient_id: number;
  public program_email_schedule_id: number;
  public user_id: number;
  public is_selected: boolean;
}

export class Recipient {
  public program_email_schedule_recipient_id: number;
  public user_id: number;
  public full_name: string;
  public is_selected: boolean;
}

export class SprintTemplate {
  public sprint_template_id: number;
  public template_name: string;
  public icon: Array<any>;
  public is_training: boolean;
}

export class ClientProgram {
  public program_id: number;
  public client_id: number;
  public program_name: string;
}

export class SuggestedNudge {
  public sprint_template_suggested_nudge_id: number;
  public suggested_nudge_name: string;
  public suggested_nudge_description: string;
  public sprint_template_goal_id: number;
}
