import { Component,OnInit} from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { ReportingService } from '../../../services/reporting.service';

@Component({
  selector: 'stats-my-progress',
  templateUrl: './stats-my-progress.component.html',
  styleUrls: ['./stats-my-progress.component.scss']
})
export class StatsMyProgressComponent implements OnInit {

    public weeklyChange: string;
    public daysInaRow: number;
    public highestStreak: number;
    public achievedVsPlan: string;
    public nudgeAchieved: string;

    constructor(
        private reportingService: ReportingService,
        private authService: AuthenticationService) {

    }

    ngOnInit(): void {
        this.load();
    }

    public load(): void {
        this.reportingService.getDashboard(0, 0, 44, this.authService.currentUserValue.Program_ID, 0).subscribe(result => {
            let data = result[0].data[0];
            this.weeklyChange = data.nudge_pc_vs_previous.value;
            this.daysInaRow = data.current_streak.value;
            this.highestStreak = data.maximum_streak.value;
            this.achievedVsPlan = data.nudge_counts.value;
            this.nudgeAchieved = data.overall_nudge_pc.value;
        });
    }
}