import { AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerInlineDirective, BsDaterangepickerDirective, BsLocaleService, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateRangeResponse } from '../../models/responses/daterange.response';
import { TodaysNudgeResponse } from '../../models/responses/todays-nudge.response';
import { AuthenticationService } from '../../services/authentication.service';
import { DashboardService } from '../../services/dashboard.service';
import { FeedbackService } from '../../services/feedback.service';
import { BsDatePickerUtils } from '../../utils/bsDatepicker.util';
import { FeedbackComponent } from '../feedback/feedback.component';
import { defineLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import { BaseComponent } from '../../../views/base.view';
import { SignalrService } from '../../services/signalr.service';
import { SetupService } from '../../services/setup.service';
import { TeamService } from '../../services/team.service';


@Component({
  selector: 'app-todays-nudges',
  templateUrl: './todays-nudges.component.html',
  styleUrls: ['./todays-nudges.component.css']
})

export class TodaysNudgesComponent extends BaseComponent implements OnInit {

  @ViewChild(BsDatepickerInlineDirective) datePicker: BsDatepickerInlineDirective;
  @ViewChild('dp', { static: false }) dp: BsDaterangepickerDirective;
  @ViewChild('forceFinishDay') forceFinishTemplate: TemplateRef<any>;
  @Output()
  public topTipsClick = new EventEmitter<MouseEvent>();
  nudges: TodaysNudgeResponse[] = [];
  isIamOnHoliDayDisable: boolean = false;
  dayTitle: string = "";
  percentage: number;
  modalRef: BsModalRef;
  dateCustomClasses: DatepickerDateCustomClasses[] = [];
  bsConfig = {
    showWeekNumbers: false,
  }
  calenderStartDate: Date;
  calenderEndDate: Date;
  bsDatePickerUtils: BsDatePickerUtils;
  lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  bsModalRef: BsModalRef;
  isFriday: boolean;
  calendarSelectedDate: any;
  nextUpdateDue: Date;
  bsValue: Date;
  counter: boolean;
  noSprint: boolean = false;
  public isUpToDate: boolean = true;
  fallenBehindUpdate: boolean = false;
  fallenBehindSkip: boolean = false;
  fallenBehindLeave: boolean = false;

  constructor(private feedbackService: FeedbackService, private authenticationService: AuthenticationService, private modalService: BsModalService,
    private dashboardService: DashboardService, private bsLocaleService: BsLocaleService, private signalrService: SignalrService, 
    private teamService: TeamService, private setupService: SetupService) {
      super(signalrService, authenticationService);
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 1;
    defineLocale("en-gb", enGbLocale);
  }

  ngOnInit(): void {
    super.setupSignalR().then(() => {
      this.signalrService.updateHasOccured.subscribe((update: boolean) => {
        if (update != null) {
          location.reload();
          //this.getTodaysNudges(this.authenticationService.currentSprintValue.next_update_due);
        }
      });

      this.setupService.getFallenBehindStatus().subscribe(result => {
        this.isUpToDate = result;
      });

      this.bsLocaleService.use('en-gb');
      this.teamService.getSprint(this.authenticationService.currentUserValue.User_ID).subscribe(result => {

        if (moment(result.sprint.user_sprint_end_date) < moment(this.authenticationService.currentSprintValue.next_update_due))
          this.noSprint = true;
        
        this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
        console.log(result.sprint.next_update_due);
        this.calendarSelectedDate = new Date(result.sprint.next_update_due);
        this.bsValue = new Date(result.sprint.next_update_due);
        this.getTodaysNudges(result.sprint.next_update_due);
      });
    });
  }
  
  ngAfterContentInit() {
      this.bsLocaleService.use('en-gb');
      this.calenderStartDate = moment(this.authenticationService.currentSprintValue.next_update_due).startOf('month').toDate();
      this.calenderEndDate = moment(this.authenticationService.currentSprintValue.next_update_due).endOf('month').toDate();
      this.callCalenderDataService();

      if (this.datePicker) {
        this.bsDatePickerUtils = new BsDatePickerUtils(this.datePicker);
        this.bsDatePickerUtils.viewChanged.subscribe(date => {
        let existingMonth = new Date(this.calenderStartDate).getMonth();
        let newMonth = new Date(date).getMonth();
        if (existingMonth != newMonth) {
          this.calenderStartDate = moment(date).startOf('month').toDate();
          this.calenderEndDate = moment(date).endOf('month').toDate();
          this.bsValue = new Date(this.calenderStartDate);
          this.callCalenderDataService();
        }

        });
      }

  }

  get isButtons() {
    return moment(moment().format('YYYY-MM-DD')).isSameOrAfter(moment(this.authenticationService.currentSprintValue.next_update_due).format('YYYY-MM-DD'));
  }

  get isCheckboxEnabled() {
    if (this.authenticationService.currentSprintValue) {
      var value = moment(moment(this.calendarSelectedDate).format("YYYY-MM-DD")).isSameOrAfter(moment(this.authenticationService.currentSprintValue.next_update_due).format("YYYY-MM-DD")) && moment(moment(new Date()).format("YYYY-MM-DD")).isSameOrAfter(moment(moment(this.calendarSelectedDate).format("YYYY-MM-DD")));
      return value;
    }
    return false;
  }

  get isFinishValid() {
    if (this.authenticationService.currentSprintValue) {
      return moment(moment(this.calendarSelectedDate).format("YYYY-MM-DD")).isSame(moment(this.authenticationService.currentSprintValue.next_update_due).format("YYYY-MM-DD"))
    }
    return false;
  }

  get isSkipDayValid() {
    if (this.authenticationService.currentSprintValue) {
      return moment(moment(this.calendarSelectedDate).format("YYYY-MM-DD")).isSame(moment(this.authenticationService.currentSprintValue.next_update_due).format("YYYY-MM-DD"));
    }
    return false;
  }

  get isShowReflectBtn() {
    if (this.authenticationService.currentSprintValue) {
      var updateDue = moment(this.authenticationService.currentSprintValue.next_update_due).format("YYYY-MM-DD");
      var surveyDate = moment(this.authenticationService.currentSprintValue.next_survey_due).format("YYYY-MM-DD");
      return moment(surveyDate).isSame(moment(updateDue)) && moment(this.calendarSelectedDate).format('dddd') === 'Friday';
    }
    return false;
  }

  get getIncomplateNudgesCount() {
    return this.nudges.filter(x => x.status_id != 2).length || 0;
  }

  get getTitle() {
    return this.getIncomplateNudgesCount > 1 ? "nudges to" : 'nudge to';
  }

  getTodaysNudges(diaryDate) {
    this.nudges = null;
    if (moment(diaryDate) <= moment(this.authenticationService.currentSprintValue.user_sprint_end_date)) {
      let nextUpdatDayName = new Date(diaryDate).toLocaleString('en-us', { weekday: 'long' });
      this.dayTitle = this.getTitleOfTheDay(diaryDate);
      if (nextUpdatDayName.toLowerCase() == 'friday') {
        this.isFriday = true;
      } else {
        this.isFriday = false;
      }
      this.feedbackService.getTodaysNudges(diaryDate).subscribe(results => {
        results.map(x => {
          if (x.start_time !== '00:00:00') {
            var date = moment().format("YYYY-MM-DD");
            x.start_time = moment(date + ' ' + x.start_time).format('HH:mm');
          } else {
            x.start_time = "00:00";
          }
        });
        this.isIamOnHoliDayDisable = results.some(x => x.status_id === 2);
        this.nudges = results;
        this.getPercentage()
      });
    }
  }

  getTitleOfTheDay(nudgesDate: Date) {
      return `${moment(nudgesDate).format('dddd, Do MMMM')}`
  }

  handleCheckboxClick(ev: any, item: TodaysNudgeResponse) {
    if (moment(item.diary_date) > moment(this.authenticationService.currentSprintValue.next_update_due)) {
      this.nextUpdateDue = this.authenticationService.currentSprintValue.next_update_due;
      this.modalRef = this.modalService.show(this.forceFinishTemplate);
      return;
    }
    let params = {
      user_diary_id: item.user_diary_id,
      isStatus: ev.target.checked,
      diary_date: item.diary_date,
      user_action_id: item.user_action_id,
      user_id: this.authenticationService.currentUserValue.User_ID,
    }
    this.feedbackService.updateNudgeStatus(params).subscribe(results => {
      this.getTodaysNudges(this.authenticationService.currentSprintValue.next_update_due);
      this.signalrService.updateOtherClients();
    });
  }

  onForceFinishClick() {
    this.modalRef.hide();
    this.bsValue = new Date(this.nextUpdateDue);
  }

  onIamOnHolidayClick() {
    this.feedbackService.updateIamOnHoliday(this.calendarSelectedDate).subscribe(result => {
      this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
      this.callCalenderDataService();
      this.bsValue = new Date(result.sprint.next_update_due);
      this.getTodaysNudges(this.authenticationService.currentSprintValue.next_update_due);
      if (result.nextTask.task_type_id == 2) {
        this.bsModalRef = this.modalService.show(FeedbackComponent, { ignoreBackdropClick: true, keyboard: false });
      }
      this.signalrService.updateOtherClients();
      location.reload();
    });
  }

  onFinishmyDayClick(template?: TemplateRef<any>) {
    var checkedNudges = this.nudges.filter(x => x.status_id === 2);
    if (checkedNudges.length !== this.nudges.length && template) {
      this.modalRef = this.modalService.show(template);
    } else {
      var list = this.nudges.map(x => {
        return {
          user_diary_id: x.user_diary_id,
          isStatus: x.status_id === 2,
          diary_date: x.diary_date,
          user_action_id: x.user_action_id,
          user_id: this.authenticationService.currentUserValue.User_ID
        }
      });
      this.feedbackService.finishMyDay(list).subscribe(result => {
        this.authenticationService.updateSprintAndTask(result.sprint, result.nextTask);
        this.modalRef && this.modalRef.hide();
        this.callCalenderDataService();
        this.signalrService.updateOtherClients();
        location.reload();
      });
    }
  }

  cancelConfirmPopup() {
    this.modalRef.hide();
  }

  getPercentage() {
    let count = 0;
    for (let single of this.nudges) {
      if (single.status_id == 2) {
        count += 1;
      }
    }
    if (count > 0) {
      this.isIamOnHoliDayDisable = true;
    }
    this.percentage = count / this.nudges.length * 100;
  }

  callCalenderDataService(): void {
    const strStartDate = moment(this.calenderStartDate).format("YYYY-MM-DD");
    const strEndDate = moment(this.calenderEndDate).format("YYYY-MM-DD");
    this.dashboardService.getCalendarData(strStartDate, strEndDate).subscribe((result) => {
      if (result.isNew && result.results.length > 0) {
        this.mapToDatePicker(result.results)
      }
    });
  }

  mapToDatePicker(results: DateRangeResponse[]) {
    results.map(x => {
      this.dateCustomClasses.push({
        date: new Date(x.diaryDate),
        classes: this.getDateBackgroundClass(x.overallCompletionRate)
      })
    });

    this.dp.dateCustomClasses = this.dateCustomClasses;
    this.dp.setConfig();
    const el = document.body.querySelector('.bs-datepicker-body table tbody');
    if (null !== el) {
      for (let i = 0; i < el.querySelectorAll('tr').length; i++) {
        let len = el.querySelectorAll('tr').item(i).querySelectorAll('td').length;
        for (let j = 0; j < len; j++) {
          let dt = new Date(this.authenticationService.currentSprintValue.next_update_due).getDate()
          if (el.querySelectorAll('tr').item(i).querySelectorAll('td').item(j).querySelector('span').innerText.toString() == dt.toLocaleString()) {
            el.querySelectorAll('tr').item(i).querySelectorAll('td').item(j).querySelector('span').click();
          }
        }
      }
    }
  }
  getDateBackgroundClass(percentage: number) {
    if (percentage >= 90) {
      return ['badge-green', 'text-light'];
    } else if (percentage >= 75 && percentage < 90) {
      return ['badge-amber', 'text-light'];
    } else if (percentage == -1) {
      return ['badge-blue', 'text-light'];

    } else {
      return ['badge-red', 'text-light'];
    }
  }

  onChangeDate(ev) {
    if (this.datePicker) {
      this.calendarSelectedDate = ev;
      this.getTodaysNudges(moment(ev).format('YYYY-MM-DDTHH:mm'));
      this.counter = false;
    }
    
    if (this.datePicker) {
      this.bsDatePickerUtils = new BsDatePickerUtils(this.datePicker);
      this.bsDatePickerUtils.viewChanged.subscribe(date => {
        
        let existingMonth = new Date(this.calenderStartDate).getMonth();
        let newMonth = new Date(date).getMonth();
        if (existingMonth != newMonth) {
          this.calenderStartDate = moment(date).startOf('month').toDate();
          this.calenderEndDate = moment(date).endOf('month').toDate();
          this.callCalenderDataService();
        }
      });
    }
  }

  reflectOnWeekClick() {
    this.bsModalRef = this.modalService.show(FeedbackComponent);
  }

  showTopTips() {
    this.topTipsClick.emit();
  }

  updateChanged(event): void {
    if (event.target.checked)
    {
      this.fallenBehindUpdate = true;
      this.fallenBehindSkip = false;
      this.fallenBehindLeave = false;
    }
  }

  skipChanged(event): void {
    if (event.target.checked)
    {
      this.fallenBehindUpdate = false;
      this.fallenBehindSkip = true;
      this.fallenBehindLeave = false;
    }
  }

  leaveChanged(event): void {
    if (event.target.checked)
    {
      this.fallenBehindUpdate = false;
      this.fallenBehindSkip = false;
      this.fallenBehindLeave = true;
    }
  }

  fallenBehindDecision(): void {
    let userId = this.authenticationService.currentUserValue.User_ID;

    if (this.fallenBehindUpdate) {
      this.isUpToDate = true;
      this.setupService.updateFallenBehindDate(userId).subscribe(result => {
        //this.router.navigate(['/widget'], { relativeTo: this.activeRoute });
      });
    } 
    else if (this.fallenBehindSkip) {
      this.setupService.updateFallenBehind(userId, this.authenticationService.currentSprintValue.user_program_id, false)
        .subscribe(result => {
          this.setupService.updateFallenBehindDate(userId).subscribe(result => {
            location.reload();
          });
        });
    } else if (this.fallenBehindLeave) {
      this.setupService.updateFallenBehind(userId, this.authenticationService.currentSprintValue.user_program_id, true)
        .subscribe(result => {
          this.setupService.updateFallenBehindDate(userId).subscribe(result => {
            location.reload();
          });
        });
    }
  }
}
