import { Component,OnInit} from '@angular/core';
import { MotivationalNugget, ProcessService, Resource } from '../../../services/process.service';

@Component({
  selector: 'motivational-nuggets',
  templateUrl: './motivational-nuggets.component.html',
  styleUrls: ['./motivational-nuggets.component.scss']
})
export class MotvationalNuggetsComponent implements OnInit {
  public nuggets: Array<MotivationalNugget> = new Array();
  public showVideoPopup: boolean = false;
  public selectedVideoUrl: string;
  public selectedCardTitle: string;
  private selectedNuggetId: number = 0;

  constructor(private processService: ProcessService) {

  }

  ngOnInit(): void {
    this.processService.getMotivationalNuggets().subscribe(result => {
      this.nuggets = result;
    });
  }

  public openVideo(video: MotivationalNugget): void {
    this.showVideoPopup = true;
    this.selectedVideoUrl = video.blob_URL;
    this.selectedCardTitle = video.nugget_name;
    this.selectedNuggetId = video.nugget_id;
  }

  public hidePopup(): void {
      this.showVideoPopup = false;
  }
}