import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Engagement } from "../models/responses/engagement.response";
import { NudgeCompletion } from "../models/responses/nudge-completion.response";
import { NudgeStatus } from "../models/responses/nudge-status.response";
import { ProgressWeek } from "../models/responses/progress-week.response";
import { QuestionAnswer } from "../models/responses/question-answer.response";
import { TimeSpent } from "../models/responses/time-spent.response";
import { SessionModel } from "../models/session.model";
import { getcalculatedSprint } from "../utils/sprint.util";
import { AuthenticationService } from "./authentication.service";
import { SettingsService } from "./settings.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class ProcessService {

    private currentProgressSubject: BehaviorSubject<[Engagement[], TimeSpent[], NudgeCompletion[]]>;
    private calculatedSprintSubject: BehaviorSubject<{ startDate: string; endDate: string; currentWeek: number; totalWeeks: number; }>;
    currentSession: SessionModel;

    constructor(
        private http: HttpClient, 
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService) {

        this.authenticationService.currentUser.subscribe(x => this.currentSession = x);
        this.currentProgressSubject = new BehaviorSubject<[Engagement[], TimeSpent[], NudgeCompletion[]]>([[], [], []]);
        this.calculatedSprintSubject = new BehaviorSubject<{ startDate: string; endDate: string; currentWeek: number; totalWeeks: number; }>(null);
        let sprint = getcalculatedSprint(this.authenticationService.currentSprintValue);
        this.calculatedSprintSubject.next(sprint);
    }

    get getCalculatedSprint(): { startDate: string; endDate: string; currentWeek: number; totalWeeks: number; } {
        return this.calculatedSprintSubject?.value || null;
    }

    getQuestionAnswerListData() {
        return this.http.get<QuestionAnswer[]>(`${this.settingsService.settings.apiUrl}/process/getQuestionAnswerList?userid=${this.currentSession.User_ID}&programid=${this.currentSession.Program_ID}`);
    }

    private calculateData(results: NudgeCompletion[]) {
        let totalWeeks = this.getCalculatedSprint?.totalWeeks || 0;
        let week = 1;
        var listNudgeCompletion: NudgeCompletion[] = [];
        while (week <= totalWeeks) {
            let currentWeek = results.find(x => x.weekNo === week);
            if (currentWeek) {
                listNudgeCompletion.push(currentWeek);
            } else if (week < this.getCalculatedSprint?.currentWeek) {
                let emptyWeek = new NudgeCompletion();
                emptyWeek.weekNo = week;
                emptyWeek.isDisabled = false;
                emptyWeek.isCurrentWeek = false;
                emptyWeek.weeklyCompletionRate = 0;
                listNudgeCompletion.push(emptyWeek);
            } else {
                let emptyWeek = new NudgeCompletion();
                emptyWeek.weekNo = week;
                emptyWeek.isDisabled = true;
                emptyWeek.isCurrentWeek = week === this.getCalculatedSprint.currentWeek;
                listNudgeCompletion.push(emptyWeek);
            }
            week++;
        }
        return listNudgeCompletion;
    }

    getNudgeStatus(userActionId: number) {
        return this.http.get<NudgeStatus>(`${this.settingsService.settings.apiUrl}/Nudge/GetNudgeStatus?user_id=${this.currentSession.User_ID}&program_id=${this.currentSession.Program_ID}&is_summary=true&user_Action_id=${userActionId}`);
    }

    getSprintWeeks(userActionId: number) {
        return this.http.get<ProgressWeek[]>(`${this.settingsService.settings.apiUrl}/Process/GetProgressSprintWeeks?user_Action_id=${userActionId}`);
    }

    getSprintWeeksAll(userProgramId: number) {
        return this.http.get<ProgressWeek[]>(`${this.settingsService.settings.apiUrl}/Process/GetProgressSprintWeeksAll?user_program_id=${userProgramId}`);
    }

    getEveryDayActions(user_program_id: number) {
        var params = new HttpParams();
        params = params.append("user_program_id", user_program_id.toString());

        return this.http.get<Array<EverydayActions>>(`${this.settingsService.settings.apiUrl}/Nudge/GetEveryDayActions`, {
            params: params
          });
    }

    getMotivationalNuggets() {
        return this.http.get<MotivationalNugget[]>(`${this.settingsService.settings.apiUrl}/Nudge/GetMotivationalNuggets`);
    }
}

export class EverydayActions {
    public icon: string;
    public goal_type: string;
    public goal_name: string;
    public nudge: string;
    public frequency: string;
}

export class MotivationalNugget {
    public nugget_id: number;
    public nugget_name: string;
    public nugget_description: string;
    public icon: string;
    public blob_URL: string;
    public date_last_viewed: Date;
    public view_time: string;
    public view_count: number;
    public hover_over_icon: string;
    public transcript: string;
    public rc_group: string;
    public safeIcon: SafeUrl;
    public safeBlob: SafeResourceUrl;
}

export class Resource {    
    public nugget_id: number;
    public nugget_name: string;
    public nugget_description: string;

    public resource_id: number;
    public resource_name: string;
    public resource_description: string;

    public icon: any;
    public icon_png: any;
    public resource_group_id: number;
    public rc_group: string;
    public blob_URL: string;
    public transcript: string;
    public play_time: Date;
    public order_no: number;
    public client_id: number;
    public program_id: number;
    public is_active: boolean;
    public date_last_viewed: string;
}

export class ResourceGroup {
    public resource_group_id: number;
    public resource_group_name: string;
    public order_no: number;
    public is_active: boolean;
}