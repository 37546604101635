import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarNativeDateFormatter, CalendarView, DateFormatterParams } from 'angular-calendar';
import { addDays, addHours, endOfMonth, startOfDay, subDays } from 'date-fns';
import { SetupService } from '../../services/setup.service';
import * as moment from 'moment';
import { SprintNudgeResponse } from '../../models/responses/sprint-nudge.response';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { WeeklyPlanRequest } from '../../models/requests/nudge.request';
import { WeeklyPlabNudgeResponse } from '../../models/responses/weekly-plan-nudge.response';
import { SessionModel } from '../../models/session.model';
import { AuthenticationService } from '../../services/authentication.service';
import { Sprint } from '../../models/responses/sprint.response';
import { ProcessService } from '../../services/process.service';
import { PlanNudge, PlanService } from '../../services/plan.service';


@Component({
  selector: 'app-weekly-calendar',
  templateUrl: './weekly-calendar.component.html',
  styleUrls: ['./weekly-calendar.component.css'],

})
export class WeeklyCalendarComponent implements OnInit, OnChanges {
  @Input() isFromSetup: boolean = false;
  public currentSprint: Sprint;
  calculatedSprint: { startDate: string; endDate: string; currentWeek: number; totalWeeks: number; };
  viewDate: Date = new Date();
  startDate: Date;
  view: CalendarView = CalendarView.Week;
  allDayEventColor = {
    primary: '#ffffff',
    secondary: '#4978bd',
  };
  eventColor = {
    primary: '#ffffff',
    secondary: 'colors.yellow',
  };
  refresh: Subject<any> = new Subject();

  CalendarView = CalendarView;
  firstday = '';
  lastday = '';
  first = null;
  last = null;
  nudgesData: WeeklyPlanRequest[] = [];
  currentUser: SessionModel;
  events: CalendarEvent[] = [];

  constructor(
    private setupService: SetupService, 
    private authService: AuthenticationService, 
    private planService: PlanService,
    public router: Router, 
    public processService: ProcessService) {

    this.currentUser = this.authService.currentUserValue;
    this.calculatedSprint = this.processService.getCalculatedSprint || null;
    this.currentSprint = this.authService.currentSprintValue;
    this.getWeekDates();
    this.getWeeklyNudges();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isFromSetup && changes.isFromSetup.currentValue) {
      this.isFromSetup = changes.isFromSetup.currentValue;
    }
  }

  handleEvent(action, ev) {
    let ind = this.events.findIndex(e => e.id == ev.id);

    if (action == 'Dropped or resized') {
      if (moment(this.events[ind].start).format('HH:mm') == '00:00') {

      }
    }
    if (ind >= 0 && this.events[ind].allDay) {
      this.events[ind].color = this.eventColor;
    }

  }

  getWeekDates() {
    var curr = moment(this.authService.currentSprintValue?.next_targets_due);
    this.first = curr.get('date') - curr.get('day') + 1;
    this.last = this.first + 1;
    this.firstday = curr.startOf('isoWeeks').format('YYYY-MM-DD');
    this.lastday = curr.endOf('isoWeeks').format('YYYY-MM-DD');
    this.startDate = new Date(this.firstday);
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
    allDay
  }: CalendarEventTimesChangedEvent): void {
    if (typeof allDay !== 'undefined') {
      event.allDay = allDay;
      if (allDay == true) {
        event.color = this.allDayEventColor
      } else {
        event.color = this.eventColor
      }
    }
    this.events = this.events.map((iEvent) => {
      if (iEvent.id === event.id) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
  }

  getWeeklyNudges() {
    this.setupService.getWeeklyNudgesData(this.firstday, this.lastday).subscribe(results => {
      this.nudgesData = results;
      if (this.nudgesData) {
        let items = []
        let start: string = '';
        let end: string | Date = '';
        let allday: boolean = false;

        for (let single of results) {
          if (single.start_time === '00:00:00') {
            start = moment(single.diary_date).format("MM/DD/YYYY") + ' 00:00:00';
            end = moment(single.diary_date).format("MM/DD/YYYY") + ' 00:00:00';
            end = new Date(end);
            allday = true;
          } else {
            start = moment(single.diary_date).format("MM/DD/YYYY") + ' ' + single.start_time;
            end = moment(start).add(30, "minutes").toDate()
            // start = moment(single.diary_date).format("MM/DD/YYYY") + ' ' + single.start_time;
            // if (single.end_time == '00:00:00') {
            //   end = moment(single.diary_date).format("MM/DD/YYYY") + ' ' + single.start_time;
            //   end = new Date(new Date(end).setMinutes(new Date(end).getMinutes() + 30));
            // } else {
            //   end = moment(single.diary_date).format("MM/DD/YYYY") + ' ' + single.end_time;
            //   end = new Date(end)
            // }

            allday = false;
          }
          let obj = {
            id: single.user_diary_id,
            title: single.action_name,
            // title: allday ? single.action_name : `${moment(start).format('hh:mm')}-${moment(end).format('hh:mm')} ${single.action_name}`,
            start: new Date(start),
            end: end,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            color: allday ? this.allDayEventColor : this.eventColor,
            allDay: allday,
            draggable: true
          }
          items.push(obj)
        }
        this.events = items;
      }
    });
  }
  cancelClick() {
    this.router.navigate(['/personal'], {
      queryParams: {
        tabIndex: 1
      }});
  }
  onSaveClick() {
    var planNudges = new Array();
    
    for (let single of this.events) {
      let selected = this.nudgesData.filter(data => data.user_diary_id == single.id)[0];
      let existingPlanNudge = planNudges.filter(x => x.user_action_id == selected.user_action_id);
      let arrayHasNudge = existingPlanNudge.length > 0;

      let newPlanNudge;

      if (arrayHasNudge) {
        newPlanNudge = existingPlanNudge[0];
      } else {
        newPlanNudge = new PlanNudge({
          user_action_id: selected.user_action_id,
          d1_is_active: false,
          d1_start_time: null,
          D1_end_time: null,

          d2_is_active: false,
          d2_start_time: null,
          D2_end_time: null,

          d3_is_active: false,
          d3_start_time: null,
          D3_end_time: null,

          d4_is_active: false,
          d4_start_time: null,
          D4_end_time: null,

          d5_is_active: false,
          d5_start_time: null,
          D5_end_time: null,

          d6_is_active: false,
          d6_start_time: null,
          D6_end_time: null,

          d7_is_active: false,
          d7_start_time: null,
          D7_end_time: null
        });
      }

      var dayOfWeek = moment(single.start).day();
      switch (dayOfWeek) {
        case 1:
          newPlanNudge.d1_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d1_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D1_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d1_start_time = null;
            newPlanNudge.D1_end_time = null;
          }
        break;
        case 2:
          newPlanNudge.d2_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d2_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D2_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d2_start_time = null;
            newPlanNudge.D2_end_time = null;
          }
        break;
        case 3:
          newPlanNudge.d3_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d3_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D3_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d3_start_time = null;
            newPlanNudge.D3_end_time = null;
          }
        break;
        case 4:
          newPlanNudge.d4_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d4_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D4_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d4_start_time = null;
            newPlanNudge.D4_end_time = null;
          }
        break;
        case 5:
          newPlanNudge.d5_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d5_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D5_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d5_start_time = null;
            newPlanNudge.D5_end_time = null;
          }
        break;
        case 6:
          newPlanNudge.d6_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d6_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D6_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d6_start_time = null;
            newPlanNudge.D6_end_time = null;
          }
        break;
        case 0: //Sunday
          newPlanNudge.d7_is_active = selected.is_active;
          if (single.start)
            newPlanNudge.d7_start_time = moment(single.start).format('HH:mm');
          if (single.end)
            newPlanNudge.D7_end_time = moment(single.end).format('HH:mm');
          if (single.allDay) {
            newPlanNudge.d7_start_time = null;
            newPlanNudge.D7_end_time = null;
          }
        break;
      }

      if (arrayHasNudge) {
        var itemIndex = planNudges.findIndex(x => x.user_action_id == selected.user_action_id);
        planNudges[itemIndex] = newPlanNudge;
      } else {
        planNudges.push(newPlanNudge);
      }
    }

    this.planService.savePlanNudges(planNudges).subscribe(() => {
      this.getWeeklyNudges();
      this.router.navigate(['/personal'], {
        queryParams: {
          tabIndex: 1
        }});
    });
  }
}