import { HttpClient } from '@angular/common/http';
import { Component,Inject,Input,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { DateChangeEvent, SchedulerEvent } from '@progress/kendo-angular-scheduler';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { SetupService, UpdateUserDiary } from '../../services/setup.service';

type ProfileType = {
    givenName?: string,
    surname?: string,
    userPrincipalName?: string,
    id?: string
};

@Component({
  selector: 'graph-calendar',
  templateUrl: './graph-calendar.component.html',
  styleUrls: ['./graph-calendar.component.scss']
})
export class GraphCalendarComponent implements OnInit {
    @Input() isInSetup: boolean = false;

    public isIframe = false;
    public loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    public profile!: ProfileType;
    public calendarItems: any;
    public events: SchedulerEvent[] = new Array();
    public selectedDate: Date = new Date();

    public showProfile: boolean = false;
    public showCalendar: boolean = false;

    private startDate: Date = moment().startOf('month').toDate();
    private endDate: Date = moment(this.startDate).endOf('month').toDate();
    private monthsProcessed: Array<number> = new Array();
    private requestBatchs = new Array();
    private weeklyNudgesRaw: Array<any> = new Array();
    private diaryEntriesToSave: Array<UpdateUserDiary> = new Array();

    public selectedViewIndex = 1;

    constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
        private msalService: MsalService, 
        private msalBroadcastService: MsalBroadcastService,
        private http: HttpClient,
        private router: Router,
        private setupService: SetupService,
        private authService: AuthenticationService,
        private toastrService: ToastrService) {
    }

    ngOnInit(): void {
        this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
          this.setLoginDisplay();
        });

        this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
        .subscribe(() => {
            this.setLoginDisplay();
        })
    }

    public login(): void {
        if (this.msalGuardConfig.authRequest){
            this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
        } else {
            this.msalService.loginRedirect();
        }
    }

    public setLoginDisplay() {
        this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;

        // if (this.loginDisplay) {
        //     this.getNudgeCalendar();
        // }
    }

    public getOutlookCalendar(): void {

        let startDateUTC = moment(this.startDate).utc().format();
        let endDateUTC = moment(this.endDate).utc().format();

        this.http.get("https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=" + startDateUTC + "&enddatetime=" + endDateUTC + "&top=500").subscribe(calendarItems => {
            this.calendarItems = calendarItems;
            let calendarEvents = new Array();

            this.calendarItems.value.forEach((item: { id: any; start: { dateTime: string; }; end: { dateTime: string; }; isAllDay: any; subject: any; }) => {
                let utcStartDate = moment.utc(item.start.dateTime).toDate();
                let utcEndDate = moment.utc(item.end.dateTime).toDate();
                
                let newCalendarEvent = {
                    id: item.id,
                    start: moment(utcStartDate).local().toDate(),
                    end: moment(utcEndDate).local().toDate(),
                    isAllDay: item.isAllDay,
                    title: item.subject,
                    description: "false"
                }
                
                if (this.hasSyncedEvent(newCalendarEvent))
                    calendarEvents.push(newCalendarEvent);
                this.showCalendar = true;
            });

            this.events = this.events.concat(calendarEvents);
        });
    }

    public getNudgeCalendar(): void {
        this.setupService.getWeeklyNudgesData(moment(this.startDate).format("YYYY-MM-DD"), moment(this.endDate).format("YYYY-MM-DD")).subscribe(result => {
            let nudgeEvents = new Array();
            
            result.forEach(item => {
                let splitDate = item.diary_date.split('T');
                let startDateTime = moment(splitDate[0] + "T" + item.start_time);
                let endDateTime = moment(startDateTime);
                endDateTime.add(30, 'minute');

                let newCalendarEvent = {
                    id: item.global_appointment_ID,
                    start: startDateTime.toDate(),
                    end: endDateTime.toDate(),
                    isAllDay: false,
                    title: item.action_name,
                    description: "true"
                  }
                  
                  nudgeEvents.push(newCalendarEvent);
            });
            
            this.events = this.events.concat(nudgeEvents);
            this.getOutlookCalendar();
        });
    }

    public dateChanged(event: DateChangeEvent): void {
        let dateStart = moment(event.dateRange.start);
        let dateEnd = moment(event.dateRange.end);

        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            if (!this.monthsProcessed.includes(dateStart.month())) {
                this.startDate = moment(dateStart).startOf('month').toDate();
                this.endDate = moment(dateStart).endOf('month').toDate();

                this.getNudgeCalendar();
                this.monthsProcessed.push(dateStart.month())
            }

            dateStart.add(1,'month');
         }
    }

    public logoutOfGraph(): void {
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: 'http://localhost:4200/personal/weekly-calendar'
        });
    }

    public backToPlan(): void {
        this.router.navigate(['/personal'], {
            queryParams: {
              tabIndex: 1
            }});
    }

    public syncNudges(): void {
        //Get all nudges for the sprint
        let sprintStartDate = moment(this.authService.currentSprintValue.program_start_date);
        let sprintEndDate = moment(this.authService.currentSprintValue.program_end_date);

        let incrementedId = 1;
        let requests = new Array();

        this.setupService.getWeeklyNudgesData(sprintStartDate.format("YYYY-MM-DD"), sprintEndDate.format("YYYY-MM-DD")).subscribe(result => {
            this.weeklyNudgesRaw = result;

            result.forEach(item => {
                let splitDate = item.diary_date.split('T');

                let startDateTime = moment(splitDate[0] + "T" + item.start_time);
                let endDateTime = moment(startDateTime);
                endDateTime.add(30, 'minute');

                let objectToSave = {
                    subject: item.action_name,
                    start: {
                        dateTime: startDateTime.utc(),
                        timeZone: "UTC"
                    },
                    end: {
                        dateTime: endDateTime.utc(),
                        timeZone: "UTC"
                    }
                }

                if (item.global_appointment_ID) {
                    requests.push(this.http.patch("https://graph.microsoft.com/v1.0/me/events/" + item.global_appointment_ID, objectToSave));
                } else {
                    requests.push(this.http.post("https://graph.microsoft.com/v1.0/me/events", objectToSave));
                }

                if (incrementedId === 4) {
                    this.requestBatchs.push(requests);
                    requests = new Array();
                    incrementedId = 0
                }

                incrementedId = incrementedId + 1;
            });

            this.requestBatchs.push(requests);

            this.matchBatchRequest(0);
        });
    }

    private matchBatchRequest(depth: number): void {
        forkJoin(this.requestBatchs[depth]).subscribe(result => {
            result.forEach((graphResponse: any, index) => {
                let originalNudge = this.weeklyNudgesRaw[index * (depth + 1)];

                let saveNudgeObject: UpdateUserDiary = {
                    user_diary_id: originalNudge.user_diary_id,
                    user_action_id: originalNudge.user_action_id,
                    diary_date: originalNudge.diary_date,
                    start_time: originalNudge.start_time,
                    end_time: originalNudge.end_time,
                    global_appointment_ID: graphResponse.id
                };

                this.diaryEntriesToSave.push(saveNudgeObject);
            });

            let deeper = depth + 1;
            if (this.requestBatchs.length > (deeper + 1))
                this.matchBatchRequest(deeper);
            else
                this.saveBackToNudge();
        });
    }

    private saveBackToNudge(): void {
        this.setupService.saveAndUpdateUserDiary(this.diaryEntriesToSave).subscribe(result => {
            this.toastrService.success("Outlook calendar sync successful", '', {
                positionClass: 'toast-bottom-right'
            });
        });
    }

    public hasSyncedEvent(event: any): boolean {
        if(event.description == "false" && this.events.filter(x => x.description == "true" && x.id == event.id).length > 0)
            return false;
        return true;
    }

    public backAuthSetup() {
        this.router.navigateByUrl('/auth/setup-wiz', { state: { activeIndex: 3 } });
    }

    public finishSetup(): void {
        this.router.navigate(['/auth/complete-setup']);
    }

    public getSchedulerStyle(): string {
        if (this.isInSetup)
            return "height: 80vh;";
        else
            return "height: 81vh;";
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
      }
}