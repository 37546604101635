import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TopTipResponse } from '../models/responses/topltip.response';
import { EngagementRating } from '../models/responses/assesment.response';
import { map } from 'rxjs/operators';
import { NudgeResponse } from '../models/responses/nudge.response';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { SessionModel } from '../models/session.model';
import { AuthenticationService } from './authentication.service';
import { DateRangeResponse } from '../models/responses/daterange.response';
import * as moment from 'moment';
import { SettingsService } from './settings.service';
import { TrackerDay } from '../models/responses/tracker-day.response';

interface ICalenderSubject {
  date: string;
  results: DateRangeResponse[];
}

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private currentNudgeSubject: BehaviorSubject<NudgeResponse[]>;
  private currentCalenderSubject: BehaviorSubject<ICalenderSubject[]>;
  public currentNudge: Observable<NudgeResponse[]>;
  public currentCalender: Observable<ICalenderSubject[]>;
  currentSession: SessionModel;

  constructor(
    private http: HttpClient, 
    private authenticationService: AuthenticationService,
    private settingsService: SettingsService) {

    this.currentNudgeSubject = new BehaviorSubject<NudgeResponse[]>([]);
    this.currentCalenderSubject = new BehaviorSubject<ICalenderSubject[]>([]);
    this.currentNudge = this.currentNudgeSubject.asObservable();
    this.currentCalender = this.currentCalenderSubject.asObservable();
    this.authenticationService.currentUser.subscribe(x => this.currentSession = x);
  }

  public get currentNudgeValue(): NudgeResponse[] {
    return this.currentNudgeSubject.value;
  }

  public get getCalenderValue(): ICalenderSubject[] {
    return this.currentCalenderSubject.value;
  }

  isCalenderDataAvailable = (date: string) => this.getCalenderValue.some(x => x.date === date);

  getAssessments() {
    return this.http.get<EngagementRating>(`${this.settingsService.settings.apiUrl}/dashboard/getEngagementRating?programid=${this.currentSession.Program_ID}`);
  }

  getCalendarData(startDate: string, endDate: string) {
    // if (this.isCalenderDataAvailable(startDate))
    //   return of({ results: this.getCalenderValue.find(x => x.date === startDate).results, isNew: false });
    return this.http.get<DateRangeResponse[]>(`${this.settingsService.settings.apiUrl}/dashboard/getCalendarData?userprogramid=${this.currentSession.Program_ID}&fromdate=${startDate}&todate=${endDate}`)
      .pipe(map(response => {
        // this.currentCalenderSubject.next([...this.getCalenderValue, { date: startDate, results: response }]);
        return { results: response, isNew: true };
      }));
  }

  getNudgeChartData(userProgramId: number) {
    // if (this.currentNudgeValue.length > 0)
    //   return of(this.currentNudgeValue);
    return this.http.get<NudgeResponse[]>(`${this.settingsService.settings.apiUrl}/dashboard/getNudgeChartData?userprogramid=${userProgramId}`)
      .pipe(map(response => {
        this.currentNudgeSubject.next(response);
        return response;
      }));
  }

  getTrackerDays(): Observable<Array<TrackerDay>> {
    return this.http.get<Array<TrackerDay>>(`${this.settingsService.settings.apiUrl}/dashboard/GetTrackerDays?userprogramid=${this.currentSession.User_Program_ID}`);
  }

  getTrackerNudges(diaryDate: string, sortBy: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.settingsService.settings.apiUrl}/dashboard/getTrackerNudges?userprogramid=${this.currentSession.User_Program_ID}&diaryDate=${diaryDate}&sortBy=${sortBy}`);
  }

  getGreetingText(): string {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      return 'Good Morning';
    } else if (curHr < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }
}