import { Component, OnInit } from '@angular/core';
import { BusinessAmbition } from '../../../shared/models/responses/businessAmbition.response';
import { SetupService } from '../../../shared/services/setup.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {

  businessAmbitions: BusinessAmbition[] = [];

  constructor(private setupService: SetupService) { }

  ngOnInit(): void {
    this.getBusinessAmbitions();
  }

  getBusinessAmbitions() {
    this.setupService.getBusinessAmbitions().subscribe(results => {
      this.businessAmbitions = results;
    })
  }
}
