import { Injectable } from "@angular/core";
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../shared/services/authentication.service";

@Injectable({ providedIn: 'root' })
export class CanActivateAuthChild implements CanActivateChild {
    constructor(private authService: AuthenticationService) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {        
        const currentUser = this.authService.currentUserValue;
        if (currentUser && !state.url.startsWith('/auth')) {
            return true;
        }
        return false;
    }
}