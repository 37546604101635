import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperOptions } from 'swiper';
import { Engagement } from '../../shared/models/responses/engagement.response';
import { NudgeCompletion } from '../../shared/models/responses/nudge-completion.response';
import { NudgeStatus } from '../../shared/models/responses/nudge-status.response';
import { NudgeResponse } from '../../shared/models/responses/nudge.response';
import { ProgressWeek } from '../../shared/models/responses/progress-week.response';
import { Sprint } from '../../shared/models/responses/sprint.response';
import { TimeSpent } from '../../shared/models/responses/time-spent.response';
import { WeeklyPlabNudgeResponse } from '../../shared/models/responses/weekly-plan-nudge.response';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { ProcessService } from '../../shared/services/process.service';
import { nudgePyramidSort } from '../../shared/utils/nudge.util';
import { getSprintLabel } from '../../shared/utils/sprint.util';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';
import { TeamService } from '../../shared/services/team.service';
import { NudgeSprintWeek } from '../../shared/models/responses/nudge-sprint-week';
import { FeedbackService } from '../../shared/services/feedback.service';

@Component({
  selector: 'app-tab-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  config: SwiperOptions = {
    breakpoints: {
      1900: {
        slidesPerView: 10
      },
      1800: {
        slidesPerView: 9
      },
      1700: {
        slidesPerView: 8
      },
      1500: {
        slidesPerView: 7
      },
      1300: {
        slidesPerView: 6
      },
      768: {
        slidesPerView: 4
      },
      576: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.arrow-button-right',
      prevEl: '.arrow-button-left'
    },
    lazy: true,
    spaceBetween: 5
  };
  currentSprint: Sprint;
  sprintLabel: string = "";
  points: NudgeResponse[] = [];
  nudgeStatus: NudgeStatus;
  selectedNudge: NudgeResponse;
  progressWeek: ProgressWeek[] = [];

  routePathObservable: any;
  selectedNudgeId: number = 0;
  title: string;
  subTitle: string;
  popOverData: any[];
  public get user_program_id() {
      return this.authenticationService.currentSprintValue.user_program_id;
  }

  constructor(
    private router: Router,
    private processService: ProcessService,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute, 
    private feedbackService: FeedbackService) {

  }

  ngOnInit(): void {
    this.routePathObservable = this.route.params.subscribe(params => {
      this.selectedNudgeId = +params['nudge'] || 0;
    });

    this.currentSprint = this.authenticationService.currentSprintValue;
    this.sprintLabel = getSprintLabel(this.currentSprint?.weekNo, this.currentSprint?.duration);
    this.getNudgeChartData();

  }

  getNudgeStatus(userActionId: number) {
    this.processService.getNudgeStatus(userActionId).subscribe(res => {
      this.nudgeStatus = res;
    });
  }
  geWeekData(userActionId: number) {
    this.processService.getSprintWeeks(userActionId).subscribe(res => {
      this.progressWeek = res.sort((a, b) => { return a.sprint_week - b.sprint_week});
    });
  }

  getWeekDataAll() {
    this.title = "All nudges";
    this.subTitle = "";
    this.processService.getSprintWeeksAll(this.authenticationService.currentSprintValue.user_program_id).subscribe(res => {
      this.progressWeek = res.sort((a, b) => { return a.sprint_week - b.sprint_week});
    });
  }

  getNudgeChartData() {
    this.dashboardService.getNudgeChartData(this.currentSprint.user_program_id).subscribe(res => {
      this.points = nudgePyramidSort(res);
      if (this.selectedNudgeId === 0) {
        this.getNudgeStatus(0);
        this.getWeekDataAll();
      } else {
        this.handleNudgeClick(this.points.find(x => x.user_action_id === this.selectedNudgeId) as NudgeResponse);
      }

    });
  }

  handleChartClick(e: any) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        this.router.navigate(['/progress/weekly-review', label]);
      }
    }
  }
  handleNudgeClick(nudge: NudgeResponse) {
    this.selectedNudge = nudge;
    this.title = this.selectedNudge.action_name;
    this.subTitle = this.selectedNudge.sprint_goal_name + ": " + this.selectedNudge.scheduled_time_name;
    this.getNudgeStatus(this.selectedNudge.user_action_id);
    this.geWeekData(this.selectedNudge.user_action_id);
  }

  handleBrainClick() {
    this.selectedNudge = null;
    this.selectedNudgeId = 0
    this.getNudgeStatus(0);
    this.getWeekDataAll();
  }

  setSelectedNudgeData(nudge: NudgeResponse) {
    this.selectedNudge = nudge;
  }

  goToReflect() {
    this.router.navigate(['/reflect']);
  }

  planClick(): void {
    if (this.selectedNudge == null) {
      this.router.navigate(['/plan']);
    } else {
      this.router.navigate(['/plan', { id: this.selectedNudge.user_action_id }]);
    }
  }

  onShown(diaryDate: Date, daysToAdd) {
    let date = moment(diaryDate);
    date.add(daysToAdd, "day");

    this.feedbackService.getTodaysNudges(date.format("YYYY-MM-DD")).subscribe(result => {
      this.popOverData = result.filter(x => x.user_diary_id != 0)
    });
  }
}
