import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { DateRangeResponse } from "../models/responses/daterange.response";
import { Engagement } from "../models/responses/engagement.response";
import { LoginResponse, TaskAndSprintDetails } from "../models/responses/login.response";
import { NudgeCompletion } from "../models/responses/nudge-completion.response";
import { NudgeSprintWeek } from "../models/responses/nudge-sprint-week";
import { NudgeStatus } from "../models/responses/nudge-status.response";
import { NudgeResponse } from "../models/responses/nudge.response";
import { ProgressWeek } from "../models/responses/progress-week.response";
import { QuestionAnswer } from "../models/responses/question-answer.response";
import { ReflectSummaryResponse } from "../models/responses/reflect-summary.response";
import { ReflectWeekResponse } from "../models/responses/reflect-week.response";
import { TeamChartData } from "../models/responses/team-chart.response";
import { TeamSprintWeek } from "../models/responses/team-sprint-week.response";
import { TeamSummary } from "../models/responses/team-summary.response";
import { SingleTeamMember, TeamMember } from "../models/responses/team-user.response";
import { TimeSpent } from "../models/responses/time-spent.response";
import { WeeklySentiment } from "../models/responses/weeklysentiment";
import { SessionModel } from "../models/session.model";
import { getcalculatedSprint } from "../utils/sprint.util";
import { AuthenticationService } from "./authentication.service";
import { SettingsService } from "./settings.service";

interface ICalenderSubject {
    date: string;
    results: DateRangeResponse[];
}

@Injectable({ providedIn: 'root' })
export class TeamService {

    private currentTeamSubject: BehaviorSubject<TeamMember[]>;
    private currentCalenderSubject: BehaviorSubject<ICalenderSubject[]>;
    private calculatedSprintSubject: BehaviorSubject<{ startDate: string; endDate: string; currentWeek: number; totalWeeks: number; }>;
    currentSession: SessionModel;

    constructor(
        private http: HttpClient, 
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService) {

        this.currentTeamSubject = new BehaviorSubject<TeamMember[]>([]);
        this.currentCalenderSubject = new BehaviorSubject<ICalenderSubject[]>([]);
        this.calculatedSprintSubject = new BehaviorSubject<{ startDate: string; endDate: string; currentWeek: number; totalWeeks: number; }>(null);
        this.authenticationService.currentUser.subscribe(x => this.currentSession = x);
        let sprint = getcalculatedSprint(this.authenticationService.currentSprintValue);
        this.calculatedSprintSubject.next(sprint);
    }

    get teamMembers(): TeamMember[] {
        return this.currentTeamSubject.value;
    }

    get getCalculatedSprint(): { startDate: string; endDate: string; currentWeek: number; totalWeeks: number; } {
        return this.calculatedSprintSubject.value;
    }

    public get getCalenderValue(): ICalenderSubject[] {
        return this.currentCalenderSubject.value;
    }

    isCalenderDataAvailable = (date: string) => this.getCalenderValue.some(x => x.date === date);

    getTeamMembers() {
        if (this.teamMembers.length > 0)
            return of(this.teamMembers);
        return this.http.get<TeamMember[]>(`${this.settingsService.settings.apiUrl}/team/getTeamList?userid=${this.currentSession.User_ID}`)
            .pipe(map(results => {
                this.currentTeamSubject.next(results);
                return results;
            }));
    }

    getTeamMember(userId: number): Observable<SingleTeamMember> {
        return this.http.get<SingleTeamMember>(`${this.settingsService.settings.apiUrl}/team/GetTeamMember?userid=${userId}`);
    }

    getQuestionAnswerListData(user: TeamMember) {
        if (this.teamMembers.find(x => x.user_id === user.user_id)) {
            if (this.teamMembers.find(x => x.user_id === user.user_id).questionAnswer?.length > 0) {
                return of(this.teamMembers.find(x => x.user_id === user.user_id).questionAnswer);
            }
        }
        return this.http.get<QuestionAnswer[]>(`${this.settingsService.settings.apiUrl}/process/getQuestionAnswerList?userid=${user.user_id}&programid=${user.program_id}`)
            .pipe(map(results => {
                this.teamMembers.map(x => {
                    if (x.user_id === user.user_id) {
                        x.questionAnswer = results;
                    }
                });
                this.currentTeamSubject.next(this.teamMembers);
                return results;
            }));
    }

    getCalendarData(user: TeamMember, startDate: string, endDate: string) {
        if (this.isCalenderDataAvailable(startDate))
            return of({ results: this.getCalenderValue.find(x => x.date === startDate).results, isNew: false });
        return this.http.get<DateRangeResponse[]>(`${this.settingsService.settings.apiUrl}/dashboard/getCalendarData?userid=${user.user_id}&userprogramid=${user.user_program_id}&fromdate=${startDate}&todate=${endDate}`)
            .pipe(map(response => {
                // this.currentCalenderSubject.next([...this.getCalenderValue, { date: startDate, results: response }]);
                return { results: response, isNew: true };
            }));
    }

    getNudgeChartData(program_id: number, user_id: number) {
        return this.http.get<NudgeResponse[]>(`${this.settingsService.settings.apiUrl}/dashboard/getNudgeChartData?userprogramid=${program_id}&userid=${user_id}`)
            .pipe(map(response => {
                // this.currentNudgeSubject.next(response);
                return response;
            }));
    }

    private calculateData(results: NudgeCompletion[]) {
        let totalWeeks = this.getCalculatedSprint?.totalWeeks || 0;
        let week = 1;
        var listNudgeCompletion: NudgeCompletion[] = [];
        while (week <= totalWeeks) {
            let currentWeek = results.find(x => x.weekNo === week);
            if (currentWeek) {
                listNudgeCompletion.push(currentWeek);
            } else if (week < this.getCalculatedSprint?.currentWeek) {
                let emptyWeek = new NudgeCompletion();
                emptyWeek.weekNo = week;
                emptyWeek.isDisabled = false;
                emptyWeek.isCurrentWeek = false;
                emptyWeek.weeklyCompletionRate = 0;
                listNudgeCompletion.push(emptyWeek);
            } else {
                let emptyWeek = new NudgeCompletion();
                emptyWeek.weekNo = week;
                emptyWeek.isDisabled = true;
                emptyWeek.isCurrentWeek = week === this.getCalculatedSprint.currentWeek;
                listNudgeCompletion.push(emptyWeek);
            }
            week++;
        }
        return listNudgeCompletion;
    }

    getNudgeStatus(userActionId: number, user: SingleTeamMember) {
        return this.http.get<NudgeStatus>(`${this.settingsService.settings.apiUrl}/Nudge/GetNudgeStatus?user_id=${user.user_id}&program_id=${user.program_id}&is_summary=true&user_Action_id=${userActionId}`);
    }

    getSprintWeeks(userActionId: number) {
        return this.http.get<ProgressWeek[]>(`${this.settingsService.settings.apiUrl}/Process/GetProgressSprintWeeks?user_Action_id=${userActionId}`);
    }

    getSprintWeeksAll(userProgramId: number) {
        return this.http.get<ProgressWeek[]>(`${this.settingsService.settings.apiUrl}/Process/GetProgressSprintWeeksAll?user_program_id=${userProgramId}`);
    }

    getTeamSummary(program_id: number) {
        return this.http.get<TeamSummary>(`${this.settingsService.settings.apiUrl}/Team/GetTeamSummary?program_id=${program_id}`);
    }

    getTeamChartData(program_id: number) {
        return this.http.get<TeamChartData[]>(`${this.settingsService.settings.apiUrl}/Team/GetTeamChartData?program_id=${program_id}`);
    }
    getTeamSprintWeek(program_id: number) {
        return this.http.get<TeamSprintWeek[]>(`${this.settingsService.settings.apiUrl}/Team/GetTeamSprintWeek?program_id=${program_id}`);
    }


    getReflectSummary(user_Program_ID) {
        return this.http.get<ReflectSummaryResponse>(`${this.settingsService.settings.apiUrl}/feedback/GetReflectSummary?user_program_id=${user_Program_ID}`);
    }
    getReflectSprintWeek(user_Program_ID) {
        return this.http.get<ReflectWeekResponse[]>(`${this.settingsService.settings.apiUrl}/feedback/GetReflectSprintWeek?user_program_id=${user_Program_ID}`);
    }
    getQuestionAnswerList(member) {
        
        return this.http.get<QuestionAnswer[]>(`${this.settingsService.settings.apiUrl}/Process/GetQuestionAnswerList?programid=${member.program_id}&userid=${member.user_id}`);
    }

    getSprint(user_id: number): Observable<TaskAndSprintDetails> {
        return this.http.get<TaskAndSprintDetails>(`${this.settingsService.settings.apiUrl}/setup/GetTaskAndSprintDetails?user_id=${user_id}`);
    }

    getNudgeSprintWeeks(user_program_id, sprint_week) {
        return this.http.get<NudgeSprintWeek[]>(`${this.settingsService.settings.apiUrl}/team/GetNudgeSprintWeek?user_program_id=${user_program_id}&sprint_week=${sprint_week}`);
    }

    getWeeklySentiment(team_user_id) {
        return this.http.get<WeeklySentiment[]>(`${this.settingsService.settings.apiUrl}/team/GetWeeklySentiment?team_user_id=${team_user_id}`);
    }
}