import { Component, OnInit} from '@angular/core';
import { ClientImplementation, ImplementationService, ImplementationStep } from '../../services/implementation.service';

@Component({
  selector: 'implementation',
  templateUrl: './implementation.component.html',
  styleUrls: ['./implementation.component.scss']
})
export class ImplementationComponent implements OnInit {
    constructor(private implementationService: ImplementationService) { }

    public clientImplementations: Array<ClientImplementation>;

    ngOnInit(): void {
        this.load();
    }

    public load(): void {
      this.implementationService.getImplementationSteps().subscribe(result => this.clientImplementations = result);
    }

    public completeStep(step: ImplementationStep): void {
      if (!step.is_blocked)
        this.implementationService.updateImplementationStep(step.id).subscribe(result => this.load());
    }
}
